import React, { createContext, useState, useContext } from "react";

// Crear el contexto
const ProjectContext = createContext<any>(null);

const ProjectProvider = ({ children }: { children: any }) => {
  const [email, setEmail] = useState(false);
  return (
    <ProjectContext.Provider value={{ email, setEmail }}>
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectProvider, ProjectContext };

import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
import { Box, Typography } from "@mui/material";
import { IVideoData, LatestVideo } from '../../../../../types/dashboard';

// Registrar el plugin
ChartJS.register(ChartDataLabels);

interface IHorizontalBartChartProps {
  dataChart: LatestVideo
}

const HorizontalBarChart: React.FC<IHorizontalBartChartProps> = ({ dataChart }) => {
  const data: number[] = [parseInt(dataChart?.totalTimeSpentOnVideo ?? '0')];
  const chartData: ChartData<'bar'> = {
    labels: [''],
    datasets: [
      {
        label: 'Horizontal Line',
        data,
        backgroundColor: '#00ADFF',
        borderWidth: 2,
        borderRadius: 10,
        barThickness: 40,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        suggestedMin: 0,
        suggestedMax: parseInt(dataChart?.totalTimeSpentOnVideo ?? '0') + 10,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 10,
          callback: function (value) {
            return `${value}s`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'start',
        color: '#ffffff',
        formatter: (value: number) => {
          const percentage = ((value / (dataChart?.totalDuration ?? value)) * 100).toFixed(0);
          return `${value}s | ${percentage}%`;
        },
      },
    },
  };

  const chartRef = useRef<ChartJS<'bar'>>(null);

  return (
    <Box mt={2} display="flex" alignItems="center">
      <Typography component="div" sx={{ whiteSpace: 'nowrap', fontSize: 12, marginTop: '-20px', color: '#787878'}}>
        Time Watched
      </Typography>
      <Box
        position="relative"
        borderRadius="15px"
        height="70px"
        width="80%"
        ml={2}
        flex="1"
      >
        <Bar ref={chartRef} data={chartData} options={options} />
      </Box>
    </Box>
  );
}

export default HorizontalBarChart;
import { Grid } from "@mui/material";
import { Paragraph } from "../../../../../styled/Paragraph";
import { IoIosLink } from "react-icons/io";
import { ButtonPrimary } from "../../../../../styled/Button";
import { DebtInvestor } from "../../../../../types/fundraising";

interface DebtCardProps {
  investor: DebtInvestor;
}

const DebtCard = ({
  investor,
}: DebtCardProps) => {
  const openInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <>
    <Grid
      container
      sx={{
        borderRadius: "8px",
        padding: "6px 24px 24px 24px",
        backgroundColor: "white",
      }}
      >
      <Grid item lg={12}>
        <Paragraph
          fontSize={24}
          fontWeight="400"
          color="#1E1E1E"
          style={{ lineHeight: "1.1" }}
          >
          {investor.name}
        </Paragraph>
        <Paragraph
          fontSize={16}
          fontWeight="300"
          color="#1E1E1E"
          style={{ lineHeight: "1" }}
          >
          {investor.subtitle}
        </Paragraph>

        <div style={{ display: "flex", gap: 8, marginTop: 6 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              cursor: "pointer",
            }}
            onClick={() => openInNewTab(investor.website)}
            >
            <IoIosLink />
            <Paragraph fontSize={14} fontWeight="400" color="#1E1E1E">
              Website
            </Paragraph>
          </div>
        </div>
          <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
            marginTop: 10,
          }}
          >
            <Paragraph fontSize={16} fontWeight="400" color="#1E1E1E">
              Repayment terms:
            </Paragraph>
            <Paragraph fontSize={16} fontWeight="400" color="#1E1E1E">
              {investor.terms}
            </Paragraph>
          </div>

          <Paragraph fontSize={16} fontWeight="400" color="#1E1E1E">
            Interest Rate: {investor.interest_rate}
          </Paragraph>

        <Paragraph
          fontSize={14}
          fontWeight="300"
          color="#1E1E1E"
          style={{ marginTop: 4 }}
          >
          {investor.description}
        </Paragraph>

        <div style={{ marginTop: 12, display: 'flex', gap: 24 }}>
          <ButtonPrimary $height={52} onClick={() => openInNewTab(investor.website)}>
            Apply now
          </ButtonPrimary>
        </div>
      </Grid>
    </Grid>
    </>
  );
};

export default DebtCard;

import React, { FC, useContext, useEffect, useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { VscFeedback } from "react-icons/vsc";
import VisitorsListFull from "../components/Table/VisitorListFull";
import OverallFeedback from "../components/OverallFeedback/OverallFeedback";
import { AppContext } from "../../../../context";
import { getDashboardData } from "../../../../api/dashboard";

const VisitorsDetailActivity = () => {
  const { setInitialDashboard, initialDashboard } = useContext(AppContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const mixpanelData = await getDashboardData();
        setInitialDashboard(mixpanelData);
        setIsLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setIsLoading(false);
      }
    };
    if (!initialDashboard) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        className="profile__box"
        direction="column"
        alignItems="stretch"
        sx={{maxWidth: '904px'}}
      >
        <Grid item md={12}>
          <p>Dive into your startup's analytics</p>
          <Box display="flex" flexDirection="row" alignItems="center">
            <p
              style={{
                fontWeight: "400",
                fontSize: "40px",
                marginRight: "76px",
              }}
            >
              Navigate your startup's success
            </p>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" sx={{maxWidth: '904px', display: 'flex', justifyContent: 'space-between', marginLeft: 0, marginBottom: 4, gap: isSmallScreen ? 2 : 0}}>
          <button
            type="button"
            onClick={() => navigate("/Dashboard")}
            className="button__navigation color__inactive"
            style={{ width: isSmallScreen ? "100%" : "" }}
          >
            <IoIosArrowRoundBack size={26} /> Back
          </button>
          <input
            style={{
              width: isSmallScreen ? "100%" : "50%",
              height: "4rem",
              padding: "16px",
              borderRadius: "30px",
              border: "1px solid #E0E0E0",
            }}
            type="text"
            aria-label="Search"
            placeholder="Search for a visitor"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            type="button"
            style={{ width: isSmallScreen ? "100%" : "11rem" }}
            onClick={openModal}
            className="button__secondary color__active"
          >
            <VscFeedback size={18} /> Overall Feedback
          </button>
      </Grid>
        <VisitorsListFull
          data={initialDashboard?.reviewsDashBoard || []}
          loading={isLoading}
          error={error}
          searchTerm={searchTerm}
        />
      <OverallFeedback
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        reviews={
          initialDashboard?.reviewsDashBoard.map((visitor) => visitor.review) ||
          []
        }
      />
    </>
  );
};

export default VisitorsDetailActivity;

import { useCallback, useEffect } from 'react';

const debounce = (func: any, wait = 200) => {
  let timeout: NodeJS.Timeout;

  function executedFunction (...args: any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  executedFunction.cancel = () => {
    clearTimeout(timeout);
  };

  return executedFunction;
};

function useDebounce (callback: any, delay = 1000) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(debounce(callback, delay), [delay]);

  const cancelDebounce = useCallback(() => {
    debouncedCallback.cancel();
  }, [debouncedCallback]);

  useEffect(() => () => {
    cancelDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);

  return { debouncedCallback, cancelDebounce };
}

export default useDebounce;
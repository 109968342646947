import React, { useState, FC } from "react";
import { Grid, Box } from "@mui/material";
import Modal from "react-modal";
import { LiaTimesSolid } from "react-icons/lia";
import Dropzone, { ISubmitButtonProps } from "react-dropzone-uploader";
import { LuFolder } from "react-icons/lu";
import "react-dropzone-uploader/dist/styles.css";
import Swal from "sweetalert2";
import { createPitchDeck } from "../../../../../../helpers/Api";
import LinearProgress from "@mui/material/LinearProgress";

type IdProp = {
  modalIsOpen: boolean;
  userId: string;
  closeModal: () => void;
};

const PitchDeckInnerModal: FC<IdProp> = ({
  modalIsOpen,
  closeModal,
  userId,
}) => {
  const stylesForDragAndDrop = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <p>
          <LuFolder color="#04BF7B" />
        </p>
        <p style={{ color: "#04BF7B", fontWeight: "300" }}>Browse your file</p>
        <p style={{ color: "#ACB8BC", fontWeight: "300", fontSize: "16px" }}>
          Max 15mb | only pdf
        </p>
      </div>
    );
  };

  const startupId = userId.toString();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleChangeStatus = ({ meta }: { meta: any }, status: any) => {
    setFile(meta);
  };

  const Preview = ({ meta }: { meta: any }) => {
    const { name, percent, status } = meta;
    return (
      <span
        style={{
          alignSelf: "center",
          textAlign: "center",
          marginTop: "10vh",
          fontFamily: "Helvetica",
        }}
      >
        {name},<br />
        Upload status: {status}
      </span>
    );
  };

  const handleSubmit = async (files: any[]) => {
    const formData = new FormData();
    formData.append("pitchDeck", files[0].file);
    setLoading(true);
    try {
      const response = await createPitchDeck(formData, startupId);
      if (response === 200) {
        Swal.fire({
          title: "Success",
          text: "Amazing you've uploaded your pitch deck",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        files.forEach((f) => f.remove());
        setFile(null);
        closeModal();
      }
    } catch (error) {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
            `,
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">
            Upload your pitch deck
          </p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        {loading ? (
          <Box p={10} sx={{ width: "100%" }}>
            <LinearProgress />
            <p style={{ textAlign: "center", marginTop: "1rem" }}>Uploading</p>
          </Box>
        ) : (
          <Dropzone
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            maxFiles={1}
            PreviewComponent={Preview}
            inputContent={stylesForDragAndDrop}
            styles={{
              dropzone: {
                borderRadius: "15px",
                backgroundColor: "#ffff",
                height: "20rem",
                width: "100%",
              },
            }}
            accept=".pdf, PDF"
          />
        )}
      </Grid>
    </Modal>
  );
};

export default PitchDeckInnerModal;

import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Checkbox, FormControlLabel } from "@mui/material";
import Cookies from "js-cookie";
import { getPublicStartup } from "../../../../../helpers/Api";
import { Link } from "react-router-dom";
import { ProjectContext } from "../../../../../context/ProjectProvider";
import { PublicStartupResponse } from "../../../../../types/startup";
interface TermsProps {
  startUpId: any;
  setNewVisitor: React.Dispatch<React.SetStateAction<boolean | null>>;
  setStartUpData: React.Dispatch<React.SetStateAction<PublicStartupResponse | null | undefined>>;
}

const TermsPublicForm: React.FC<TermsProps> = ({
  startUpId,
  setNewVisitor,
  setStartUpData,
}) => {
  const [error, setError] = useState<string | null>(null);
  const { setEmail } = useContext(ProjectContext);

  const onSubmitGetData = async (values: any) => {
    const email = values.email;
    const isTermsAcepted = values.checkbox;
    setEmail(email);

    const response = await getPublicStartup(startUpId, email, isTermsAcepted);
    if (response.status === 200) {
      setStartUpData(response.data);
      setNewVisitor(false);
      Cookies.set("email", email, { expires: 7 });
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        checkbox: false,
      }}
      validationSchema={Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
        checkbox: Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("Required"),
      })}
      onSubmit={(values: any) => {
        onSubmitGetData(values);
      }}
    >
      {({ setFieldValue, values, touched, errors, isValid, dirty }) => (
        <Form noValidate role="form">
          <div className="signup__input">
            <p>Your account details</p>
            <Field
              type="email"
              placeholder="Email Address"
              name="email"
              className={`form-control customField mb-4 ${
                touched.email ? (errors.email ? "is-invalid" : "is-valid") : ""
              }`}
              autoComplete="new-email"
            />
            <ErrorMessage
              name="email"
              component="p"
              className="requiredField_signUp"
            />
            <FormControlLabel
              className={`mb-3`}
              control={
                <Field
                  type="checkbox"
                  name="checkbox"
                  as={Checkbox}
                  onChange={() => setFieldValue("checkbox", !values.checkbox)}
                />
              }
              label={
                <span>
                  I agree to PomJuice's{" "}
                  <Link
                    to="/terms-of-service"
                    style={{
                      textDecoration: "none",
                      color: "#04BF7B",
                      fontWeight: "inherit",
                    }}
                  >
                    Terms & Conditions{" "}
                  </Link>
                  and
                  <Link
                    to="/privacy-policy"
                    style={{
                      textDecoration: "none",
                      color: "#04BF7B",
                      fontWeight: "inherit",
                    }}
                  >
                    {" "}
                    Privacy Policy.
                  </Link>
                </span>
              }
            />
            <ErrorMessage
              name="checkbox"
              component="p"
              className="requiredField"
            />

            {error && <p className="text-danger">{error}</p>}
            <div className="d-flex justify-content-end mt-3">
              <button
                type="submit"
                className="button__secondary color__active"
                style={{ backgroundColor: "#04BF7B", color: "#fff" }}
                disabled={!isValid || !dirty}
              >
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TermsPublicForm;

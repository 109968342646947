import React, { useContext, useState, FC } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { loginUser } from "../../../helpers/Api";
import { forgotPassword } from "../../../helpers/Api";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { AppContext } from "../../../context";
import { Container, Grid, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

//Image assets
import PumpJuiceLogo from "../../../assets/pompjuice_logo.svg";
import pomegranate from "../../../assets/pomegranate.svg";

const Login: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { dispatch, setIsOnboarding, getStartupProfile } =
    useContext(AppContext);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleLogin = async (e: { email: string; password: string }) => {
    const { email, password } = e;
    if (email !== "" && password !== "") {
      try {
        const response = await loginUser(email, password);
        if (response.success) {
          dispatch({ type: "OPEN_AUTH" });
          if (response.data.isOnboarding === false) {
            setIsOnboarding(false);
            localStorage.setItem("isOnboarding", "false");
            await getStartupProfile();
            navigate("/dashboard");
          } else {
            navigate("/startup-profile");
          }
        } else {
          await Swal.fire({
            title: "Oops!",
            text: response.message,
            icon: "error",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "swal2-confirm btn btn-primary",
            },
          });
        }
      } catch (err) {
        await Swal.fire({
          title: "Oops!",
          text: "An unexpected error occurred. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
      }
    } else {
      await Swal.fire({
        title: "Oops!",
        text: "The fields must be filled",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  const handleForgotPassword = async () => {
    try {
      const { value: email } = await Swal.fire({
        title: "Forgot Password",
        html: `<div style="text-align: left; font-size: 1rem;">Enter the email address associated with your account and we'll send you a temporary password</div>`,
        input: "email",
        showCancelButton: true,
        confirmButtonText: "Send",
        cancelButtonText: "Cancel",
        inputValidator: (value: string) => {
          if (!value) {
            return "Email is required";
          }
        },
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
          cancelButton: "swal2-cancel btn btn-default",
        },
      });

      if (email) {
        try {
          const response = await forgotPassword(email);
          if (response.status === 200) {
            Swal.fire({
              title: "Temporary Password Sent",
              text: `A temporary password has been sent to ${email} if an account is associated with this email address.`,
              icon: "success",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "swal2-confirm btn btn-primary",
              },
            });
          } else {
            Swal.fire({
              title: "Oops",
              text: `Looks like there is something wrong <br />
                  `,
              icon: "error",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "swal2-confirm btn btn-primary",
              },
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Oops",
            text: `Looks like there is something wrong <br />
            `,
            icon: "error",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "swal2-confirm btn btn-primary",
            },
          });
        }
      }
    } catch {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
            `,
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container minHeight={"100vh"} className="login__box">
        <Grid
          container
          item
          xs={12}
          md={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className="login__brand"
        >
          <div className="login__semiCircle">
            <p></p>
          </div>
          <img src={PumpJuiceLogo} className="login_img" alt="PumpJuiceLogo" />
          <div className="login__brand_text">
            <p>Welcome Back!</p>
            <p style={{ fontSize: 35, fontWeight: 700 }}>Log in</p>
            <p style={{ fontWeight: 400 }}>
              Don’t have an account?{" "}
              <b>
                <Link
                  to="/signup"
                  style={{
                    color: "inherit",
                    fontWeight: "inherit",
                  }}
                >
                  Sign up
                </Link>
              </b>
            </p>
          </div>
          <img
            src={pomegranate}
            className="login_pomegranate"
            alt="GitHub Logo"
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={8}
          display="flex"
          flexDirection="column"
          alignItems={{ md: "stretch", xs: "center" }}
          justifyContent="center"
          className="login__card"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required field"),
              password: Yup.string()
                .min(8, "Password must be at least 8 characters")
                .required("Required field"),
            })}
            onSubmit={(values) => {
              handleLogin(values);
            }}
          >
            {(props) => (
              <Form noValidate role="form" className="login__form">
                <div>
                  <div className="loginSmallDevices__brand_text">
                    <p style={{ fontSize: 35, fontWeight: 700 }}>Log in</p>
                  </div>
                  <p>Please enter your account details</p>
                  <Box mb={4}>
                    <Field
                      type="text"
                      placeholder="Email Address"
                      name="email"
                      className={`form-control customField_user mb-3 ${
                        props.touched.email
                          ? props.errors.email
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      autoComplete="new-email"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="requiredField"
                    />
                  </Box>
                  <div className="input-group">
                    <Field
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      className={`form-control customField mb-3 ${
                        props.touched.password
                          ? props.errors.password
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      autoComplete="new-password"
                    />
                    <div className="input-group-text mb-3">
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setPasswordShown(passwordShown ? false : true)
                        }
                      >
                        {passwordShown === false && <IoMdEyeOff />}
                        {passwordShown === true && <IoMdEye />}
                      </i>
                    </div>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="p"
                    className="requiredField"
                  />
                  <div style={{ marginTop: 12 }}>
                    <button
                      className="button__primary color__active"
                      type="submit"
                    >
                      Login
                      {/* {!props.isSubmitting ? (
                        "Login"
                      ) : (
                        <CircularProgress
                          sx={{ color: "white", marginTop: "5px" }}
                          size={26}
                        />
                      )} */}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <button
            className="button__link "
            onClick={handleForgotPassword}
            style={{
              textAlign: "start",
            }}
          >
            Forgot your password
          </button>
          {/* <div className="login__social">
            <p>Or connect with</p>
            <div className="login__button_social">
              <button className="button__social">
                <img src={GoogleButton} alt="GitHub Logo" /> Continue with
                Google
              </button>
            </div>
            <div className="login__button_social">
              <button className="button__social">
                <img src={LinkedinButton} alt="GitHub Logo" /> Continue with
                Google
              </button>
            </div>
          </div> */}
          <div className=" loginSmallDevices__brand_text">
            <p style={{ fontWeight: 400, marginTop: "25px" }}>
              Don’t have an account?{" "}
              <b>
                <Link
                  to="/signup"
                  style={{
                    color: "#04BF7B",
                    fontWeight: "inherit",
                  }}
                >
                  Sign up
                </Link>
              </b>
            </p>
          </div>
          <Box className="login__policies">
            <p></p>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;

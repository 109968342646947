import { Container, Grid, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
//Image assets
import PumpJuiceLogo from "../../../assets/pompjuice_logo.svg";

import pomegranate from "../../../assets/pomegranate.svg";
import RocketLogo from "../../../assets/rocket_logo.svg";
import { Paragraph } from "../../../styled/Paragraph";
const Welcome = () => {
  const [redirectToHome, setRedirectToHome] = useState(false);

  const handleContinue = () => {
    window.location.href = "/startup-profile";
  };

  if (redirectToHome) {
    return null; // Redirecting, so no need to render anything
  }

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container minHeight={"100vh"} className="login__box">
        <Grid
          item
          xs={12}
          md={4}
          container
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="login__brand"
          height="100%"
        >
          <div className="login__semiCircle">
            <p></p>
          </div>
          <img src={PumpJuiceLogo} className="login_img" alt="PumpJuiceLogo" />

          <div className="login__brand_text">
            <p>Welcome</p>

            <p>
              Please Log in to continue{" "}
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontWeight: "inherit",
                }}
              >
                <b>Log in</b>
              </Link>
            </p>
          </div>

          <img
            src={pomegranate}
            style={{
              width: "80%",
              margin: "2rem",
            }}
            alt="GitHub Logo"
            className="login_pomegranate"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          container
          display="flex"
          direction="column"
          alignItems="stretch"
          justifyContent="center"
          className="login__card"
        >
          <Box
            className="mb-5 "
            style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}
          >
            <img
              src={RocketLogo}
              style={{ width: "10rem", margin: "2rem" }}
              alt="Rocket Logo!"
            />
            <Typography
              variant="h4"
              style={{
                fontSize: 40,
                fontWeight: '500',
                textAlign: "start",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Welcome to PomJuice Pro!
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "30px", fontSize: 20, fontWeight: '400' }}>
              Enjoy a 14 day free trial with unlimited access to Pro features*
            </Typography>
            <ul>
              <li>Share your private profile page with any number of investors</li>
              <li>View your entire startup profile analytics history</li>
              <li>Search and apply for capital through the fundraising portal</li>
              <li>Upload any number of documents to your data room</li>
            </ul>

            <div style={{ textAlign: "left", marginTop: "20px" }}>
              <button
                className="button__primary color__active"
                type="submit"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
            <div style={{ marginTop: "20px"}}>
              <Paragraph fontSize={12} fontWeight="400">
                * Subscribe for $99/year to keep access to Pro features at the end of your free trial
              </Paragraph>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Welcome;

import { Grid } from '@mui/material'
import React from 'react'
import { Paragraph } from '../../../../../styled/Paragraph'
import { ButtonPrimary } from '../../../../../styled/Button'

const DebtEmptyCard = () => {
  return (
    <div
     style={{
       borderRadius: "8px",
       padding: "36px 72px",
       backgroundColor: "white",
       display: 'flex',
       flexDirection: 'column',
       gap: '24px',
       alignItems: 'center',
       boxShadow:
        "0px -2px 80px 0px rgba(0, 0, 0, 0.07), 0px -0.836px 33.422px 0px rgba(0, 0, 0, 0.05), 0px -0.447px 17.869px 0px rgba(0, 0, 0, 0.04), 0px -0.25px 10.017px 0px rgba(0, 0, 0, 0.04), 0px -0.133px 5.32px 0px rgba(0, 0, 0, 0.03), 0px -0.055px 2.214px 0px rgba(0, 0, 0, 0.02)",
     }}
    >
      <Paragraph fontSize={24} fontWeight='400' style={{ textAlign: 'center' }}>Based on your company profile you do not qualify for any debt financing offers at this time.</Paragraph>
      <Paragraph fontSize={14} fontWeight='300' style={{ textAlign: 'center' }}>Check back again at another time or click the button below to view your equity financing options instead!</Paragraph>
      <ButtonPrimary $height={52} width='auto' onClick={() => {}}>
        View Equity Financing Matches
      </ButtonPrimary>

    </div>
  )
}

export default DebtEmptyCard
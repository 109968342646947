import React, { FC, useContext, useState } from "react";
import { Grid, Box, Container, Chip } from "@mui/material";
import { BiSolidPencil } from "react-icons/bi";
import { NumericFormat } from "react-number-format";
import EditBusinessTractionInnerModal from "../ModalChoice/Modals/EditBusinessTractionModal";
import { AppContext } from "../../../../../context";
import MainCard from "../../../../../components/components/MainCard";
import { Paragraph } from "../../../../../styled/Paragraph";

interface CardChoosingProps {
  title: string | null | undefined;
  dataTraction: {
    businessModel: string[];
    businessRevenueSources: string | null | undefined;
    mrr: number | null | undefined;
    topCustomers: Customer[];
  };
  userId: string;
  isReadOnly?: boolean;
}

type Customer = {
  name: string;
};

const CardBusinessTraction: FC<CardChoosingProps> = ({
  title = "Business Traction",
  dataTraction,
  userId,
  isReadOnly = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { getStartupProfile } = useContext(AppContext);

  const openModal = () => {
    if (!isReadOnly) {
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    getStartupProfile();
  };

  const renderBusinessModel = (model: string | null | undefined) => {
    switch (model) {
      case "product_sales":
        return "Product Sales";
      case "platform":
        return "Platform";
      case "service_based":
        return "Service Based";
      case "marketplace":
        return "Marketplace";
      case "subcription":
        return "Subcription";
      case "saas":
        return "SaaS";
      case "freemium":
        return "Freemium";
      case "other":
        return "Other";
      default:
        return null;
    }
  };

  const renderRevenueSource = (source: string | null | undefined) => {
    switch (source) {
      case "b2c":
        return "B2C";
      case "b2b":
        return "B2B";
      case "b2b2c":
        return "B2B2C";
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item md={12}>
        <MainCard title="Business Traction" subtext="Required *">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "top",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              p={2}
            >
              <Grid item md={6}>
                <Box pb={2}>
                  <Paragraph fontSize={18} fontWeight="400">
                    Startup Business Model
                  </Paragraph>
                </Box>
                <Box pb={2}>
                  <Paragraph fontSize={18} fontWeight="400">
                    Revenue Main Resource
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph fontSize={18} fontWeight="400">
                    Monthly Recurring Revenue
                  </Paragraph>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box pb={2}>
                  <Paragraph
                    fontSize={18}
                    fontWeight="400"
                    className="buildProfile__companyDetails"
                    >
                    {dataTraction.businessModel.map((el) => renderBusinessModel(el)).join(', ')}
                  </Paragraph>
                </Box>
                <Box pb={2}>
                  <Paragraph
                    fontSize={18}
                    fontWeight="400"
                    className="buildProfile__companyDetails"
                  >
                    {renderRevenueSource(dataTraction.businessRevenueSources)}
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph
                    fontSize={18}
                    fontWeight="400"
                    className="buildProfile__companyDetails"
                  >
                    <NumericFormat
                      type="text"
                      prefix={"$"}
                      suffix={" USD"}
                      decimalSeparator={"."}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      placeholder="$0.0"
                      value={dataTraction.mrr}
                      // className="buildProfile__companyDetails"
                      style={{ border: "none", backgroundColor: "transparent" }}
                      disabled
                    />
                  </Paragraph>
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box mt={2}>
                  <Paragraph fontSize={18} fontWeight="400">
                    Top Customers:{" "}
                  </Paragraph>
                </Box>
                <Grid item md={12}>
                  <Container maxWidth="lg">
                    {dataTraction.topCustomers.map((customer, index) => (
                      <Chip
                        key={index}
                        sx={{ margin: "10px" }}
                        label={customer.name}
                        variant="outlined"
                      />
                    ))}
                  </Container>
                </Grid>
              </Grid>
            </Grid>
            {!isReadOnly && (
              <EditBusinessTractionInnerModal
                userId={userId}
                dataTraction={dataTraction}
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
              />
            )}
            {!isReadOnly && (
              <button
                onClick={openModal}
                className="button__edit color__active"
                style={{
                  backgroundColor: "#04BF7B",
                  borderRadius: "8px",
                  padding: "8px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
              >
                <BiSolidPencil size={22} />
              </button>
            )}
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default CardBusinessTraction;

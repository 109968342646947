import React from "react";
import {
  CustomPagination,
  HeaderContainer,
  MainCardContainer,
  MainCardContent,
  MainCardTitle,
} from "../../styled/MainCard";
import { Paragraph } from "../../styled/Paragraph";
import { ButtonPrimary } from "../../styled/Button";
import { Box, Stack } from "@mui/material";

interface MainCardProps {
  title: string;
  buttonTitle?: string;
  pagination?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  onPageChange?: (page: number) => void;
  totalPages?: number;
  subtext?: string;
}

const MainCard = ({ title, buttonTitle, pagination = false, children, onClick, onPageChange, totalPages, subtext  }: MainCardProps) => {
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  return (
    <MainCardContainer>
      <MainCardTitle withSubtext={!!subtext}>
        <HeaderContainer>
        <Paragraph fontSize={24} fontWeight="400" color="#04BF7B">
          {title}
        </Paragraph>
        {pagination && (
          <Stack spacing={2}>
            <CustomPagination
              count={totalPages}
              onChange={handlePageChange}
              />
          </Stack>
        )}
        {buttonTitle && (
          <ButtonPrimary $height={52} onClick={onClick}>
            {buttonTitle}
          </ButtonPrimary>
        )}
        </HeaderContainer>
      {subtext &&
        <Box>
          <Paragraph fontSize={14} fontWeight="400" color="#04BF7B">
            {subtext}
          </Paragraph>
        </Box>
      }
      </MainCardTitle>
      <MainCardContent>{children}</MainCardContent>
    </MainCardContainer>
  );
};

export default MainCard;

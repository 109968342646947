import { Grid } from '@mui/material'
import { DebtInvestor } from '../../../../../types/fundraising'
import DebtItemList from './DebtItem'

interface Props {
  investors: DebtInvestor[]
}

const DebtList = ({ investors }: Props) => {
  return (
    <>
    <Grid container direction="row" sx={{ padding: '0px 16px', alignItems: 'center', width: 'calc(100% - 24px)'}}>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>Name</Grid>
      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Status</Grid>
      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Financing Amount</Grid>
      <Grid item xs={2}></Grid>
    </Grid>
    {investors?.map((inv, i) => <DebtItemList investor={inv} key={inv.id} />)}
    </>
  )
}

export default DebtList
export const getTimeAgo = (secondsSinceLastVisit: number) => {
  const currentDate = new Date();
  const millisecondsSinceLastVisit = secondsSinceLastVisit * 1000;
  const lastVisitDate = new Date(currentDate.getTime() - millisecondsSinceLastVisit);
  const diff = currentDate.getTime() - lastVisitDate.getTime();
  const timeAgo = convertTime(diff);
  return timeAgo;
}

export const convertTime = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? '1 year ago' : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
    return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
  }
}

export const convertNanosecondsToTimeFormat = (totalNanoseconds: number) => {
  // Convertir nanosegundos a segundos
  const totalSeconds = totalNanoseconds / 1e9;

  // Calcular horas, minutos y segundos
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // Formatear los componentes del tiempo para asegurarse de que siempre tengan dos dígitos
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  // Retornar el formato adecuado: HH:MM:SS si hay horas, o MM:SS si no
  return hours > 0
    ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    : `${formattedMinutes}:${formattedSeconds}`;
};

export const convertNanosecondsToSeconds = (nanoseconds: number): number => {
  // Convertir nanosegundos a segundos
  return nanoseconds / 1e9;
};

export const formatDateToMonthYear = (dateString: string) => {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  return `${month}/${year}`;
}
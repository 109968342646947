import React, { FC, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import TermsComponent from "./components/Terms/TermsComponent";
import { checkPublicVisitor, checkPrivateVisitor } from "../../../helpers/Api";
import StartupProfile from "./components/StartupProfile/StartupProfile";
import Error from "../Error/index";
import { Grid, CircularProgress } from "@mui/material";
import { PublicStartupResponse } from "../../../types/startup";

type Params = {
  id: string;
  email: string;
  token: string;
};

const SharedStartUp: FC = (): JSX.Element => {
  const [newVisitor, setNewVisitor] = useState<boolean | null>(null);
  const [startUpData, setStartUpData] = useState<PublicStartupResponse | null | undefined>();
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { id, email, token } = useParams<Params>();

  const hasFetchedData = useRef(false);

  useEffect(() => {
    const getData = async () => {
      try {
        if (id && email && token) {
          setIsPrivate(true);
          const response = await checkPrivateVisitor(id, email, token);
          if (response.status === 200) {
            const data = response.data;
            if (data.new_visitor) {
              setNewVisitor(true);
            } else {
              setStartUpData(data);
            }
          } else {
            setError("Failed to fetch private visitor data");
          }
        } else if (id) {
          const response = await checkPublicVisitor(id);
          if (response.status === 200) {
            const data = response.data;
            if (data.new_visitor) {
              setNewVisitor(true);
            } else {
              setStartUpData(data);
            }
          } else {
            setError("Failed to fetch public visitor data");
          }
        } else {
          setError("ID not provided");
        }
      } catch (e) {
        setError("An error occurred while fetching data");
      }
    };

    if (!hasFetchedData.current) {
      getData();
      hasFetchedData.current = true;
    }
  }, [id, email, token]);

  if (error) {
    return <Error />;
  }
  if (!startUpData && !newVisitor) {
    return (
      <Grid container direction="column">
        <Grid
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          item
          md={12}
        >
          <CircularProgress size={70} color="inherit" />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {newVisitor ? (
        <TermsComponent
          startUpId={id}
          email={email}
          token={token}
          isPrivate={isPrivate}
          setNewVisitor={setNewVisitor}
          setStartUpData={setStartUpData}
        />
      ) : (
        <>
        {startUpData &&
          <StartupProfile jsonData={startUpData} isReadOnly={true} />
        }
        </>
      )}
    </>
  );
};

export default SharedStartUp;

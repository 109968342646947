import React, { FC } from "react";
import { Grid, Box } from "@mui/material";
import moment from "moment";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { SiCalendly } from "react-icons/si";
import { LiaLaptopSolid } from "react-icons/lia";

interface ProfileProps {
  profileData: InputProfileData | undefined;
}

type InputProfileData = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  tagLine: string | null | undefined;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  bio: string | undefined;
  experiences: Experience[];
  linkedinUrl: string | null | undefined;
  xUrl: string | null | undefined;
  websiteUrl: string | null | undefined;
  calendlyUrl: string | null | undefined;
  pictureUrl: string | null | undefined;
};

interface Experience {
  title: string | null | undefined;
  company: string | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}
const ReadModeProfile: FC<ProfileProps> = ({ profileData }) => {
  return (
    <Grid container>
      <Grid container direction="row">
        <Grid item className="profile__titleForm" xs={12} md={6} lg={4}>
          <p>First name</p>
        </Grid>
        <Grid item className="profile__descriptionForm" xs={12} md={4}>
          <Box mt={7}>
            <p>{profileData?.firstName || "No information available"}</p>
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item className="profile__titleForm" xs={12} md={6} lg={4}>
          <p>Last name</p>
        </Grid>
        <Grid item className="profile__descriptionForm" xs={12} md={4}>
          <Box mt={6}>
            <p>{profileData?.lastName || "Add first name"}</p>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item className="profile__titleForm" xs={12} md={6} lg={4}>
          <p>TagLine</p>
        </Grid>
        <Grid item className="profile__descriptionForm" xs={12} md={4}>
          <Box mt={6}>
            <p>{profileData?.tagLine || "Give us a stunning tagline"}</p>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item className="profile__titleForm" xs={12} md={6} lg={4}>
          <p>Contact</p>
        </Grid>
        <Grid item className="profile__descriptionForm" xs={12} md={4}>
          <Box mt={6}>
            <p>
              Email: {profileData?.email || "Where can we reach you?"}
              <br />
              <br />
              Phone: {profileData?.phoneNumber || "Add phone number"}
            </p>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item className="profile__titleForm" xs={12} md={6} lg={4}>
          <p>Bio</p>
        </Grid>
        <Grid item className="profile__descriptionForm" xs={12} md={6}>
          <Box mt={6}>
            {profileData?.bio ? (
              <p>{profileData?.bio}</p>
            ) : (
              <p>
                Edit your profile by adding a brief biography! Tell us a bit
                about yourself and your journey in the industry.
              </p>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item className="profile__titleForm" xs={12} md={6} lg={4}>
          <p>Relevant links</p> <br />
        </Grid>
        <Grid item className="profile__descriptionForm_links" xs={12} md={6}>
          <Box mt={6}>
            {profileData?.linkedinUrl ? (
              <>
                <LiaLaptopSolid color="#00ACEE" size={22} />
                <p>{profileData?.websiteUrl?.slice(8) || "Add website link"}</p>

                <FaTwitterSquare color="#00ACEE" size={22} />
                <p>
                  @{profileData?.xUrl?.slice(14) || "Add social media link"}
                </p>

                <FaLinkedin color="#00ACEE" size={22} />
                <p>
                  {profileData?.linkedinUrl?.slice(28) || "Add linkedin link"}
                </p>

                <SiCalendly color="#00ACEE" size={22} />
                <p>
                  {profileData?.calendlyUrl?.slice(8) || "Add calendly link"}
                </p>
              </>
            ) : (
              <p>
                Add relevant links to your profile! These can be links to your
                website, calendar platform, linkedin profile or X social media.
              </p>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item className="profile__titleForm" xs={12} md={6} lg={4}>
          <p>Experience</p>
        </Grid>
        <Grid item className="profile__descriptionForm" xs={12} md={6}>
          <Box mt={7}>
            {profileData?.experiences?.length ? (
              profileData?.experiences?.map((exp, index) => (
                <p key={index}>
                  {exp.title} at {exp.company} (
                  {exp.startDate
                    ? moment(exp.startDate).format("MMMM YYYY")
                    : ""}{" "}
                  -{" "}
                  {exp.endDate
                    ? moment(exp.endDate).format("MMMM YYYY")
                    : "Present"}
                  )
                </p>
              ))
            ) : (
              <p>
                Share your professional experience! Add details about your
                previous roles.
              </p>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReadModeProfile;

import { format, parseISO } from "date-fns";
import { Day, LastWeek } from '../types/dashboard';

const generateLabels = (data?: Day[]) => {
  return data?.map((d) => format(parseISO(d.date), 'EEEE'));
};

const getMonthByDate = (date: string) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [year, month] = date.split('-');
  const monthIndex = parseInt(month, 10) - 1; // Convert month to zero-based index

  if (monthIndex >= 0 && monthIndex < 12) {
      return monthNames[monthIndex];
  } else {
      return "Invalid date";
  }
}

export const getTotalVisits = (data: any, chartType: string) => {
  switch (chartType) {
    case 'pageVisit-lastWeek':
    case 'pitchDeckView-lastWeek':
    case 'totalShares-lastWeek':
    case 'videoView-lastWeek':{
      return data?.lastWeek?.total ?? 0
    }
    case 'pageVisit-lastMonth':
    case 'pitchDeckView-lastMonth':
    case 'totalShares-lastMonth':
    case 'videoView-lastMonth': {
      return data?.lastMonth?.total ?? 0
    }
    case 'pageVisit-lastSixMonth':
    case 'pitchDeckView-lastSixMonth':
    case 'totalShares-lastSixMonth':
    case 'videoView-lastSixMonth': {
      return data?.lastSixMonth?.total ?? 0
    }
    case 'pageVisit-lastYear':
    case 'pitchDeckView-lastYear':
    case 'totalShares-lastYear':
    case 'videoView-lastYear': {
      return data?.lastYear?.total ?? 0
    }
    default: return 0
  }
}

export const formatDataChart = (data: any, chartType: string, color?: string) => {
  switch (chartType) {
    case 'pageVisit-lastWeek':
    case 'pitchDeckView-lastWeek':
    case 'totalShares-lastWeek':
    case 'videoView-lastWeek':
    {
      const dataToUse = data?.lastWeek
      const days: Day[] = dataToUse?.days ?? [];
      const labels: string[] | undefined = generateLabels(days)
      return {
        labels,
        datasets: [
            {
                label: '',
                data: days?.map((el) => el.qty) ?? [0],
                backgroundColor: color,
                borderColor: color,
                borderWidth: 2,
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointRadius: 2,
                tension: 0.25,
                fill: false,
                datalabels: {
                    display: false,
                },
            },
        ],
      };

    }
    case 'pageVisit-lastMonth':
    case 'pitchDeckView-lastMonth':
    case 'totalShares-lastMonth':
    case 'videoView-lastMonth':
    {
      const dataToUse = data?.lastMonth
      const days: Day[] = dataToUse?.days ?? [];
      const labels: string[] | undefined = days?.map((d) => d.date)

      return {
        labels,
        datasets: [
            {
                label: '',
                data: days?.map((el) => el.qty) ?? [0],
                backgroundColor: color,
                borderColor: color,
                borderWidth: 2,
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointRadius: 2,
                tension: 0.25,
                fill: false,
                datalabels: {
                    display: false,
                },
            },
        ],
      };
    }

    case 'pageVisit-lastSixMonth':
    case 'pitchDeckView-lastSixMonth':
    case 'totalShares-lastSixMonth':
    case 'videoView-lastSixMonth':
    {
      const dataToUse = data?.lastSixMonth
      const months: Day[] = dataToUse?.months ?? [];
      const labels: string[] | undefined = months.map((d) => getMonthByDate(d.date)) ?? []
      return {
        labels,
        datasets: [
            {
                label: '',
                data: months?.map((el) => el.qty) ?? [0],
                backgroundColor: color,
                borderColor: color,
                borderWidth: 2,
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointRadius: 2,
                tension: 0.25,
                fill: false,
                datalabels: {
                    display: false,
                },
            },
        ],
      };
    }

    case 'pageVisit-lastYear':
    case 'pitchDeckView-lastYear':
    case 'totalShares-lastYear':
    case 'videoView-lastYear':
    {
      const dataToUse = data?.lastYear
      const days: Day[] = dataToUse?.months ?? [];
      const labels: string[] | undefined = days.map((d) => getMonthByDate(d.date)) ?? []
      return {
        labels,
        datasets: [
            {
                label: '',
                data: days?.map((el) => el.qty) ?? [0],
                backgroundColor: color,
                borderColor: color,
                borderWidth: 2,
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointRadius: 2,
                tension: 0.25,
                fill: false,
                datalabels: {
                    display: false,
                },
            },
        ],
      };
    }
    default:
      break;
  }

}
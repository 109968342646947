import React, { FC, useState, useEffect } from "react";
import Modal from "react-modal";
import { Box, Grid } from "@mui/material";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LiaTimesSolid } from "react-icons/lia";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormat } from "react-number-format";
//Custom components
import { InvestmentInstrument } from "../../../../../../helpers/InvestmentInstrument";
import { editInvestorFromRound } from "../../../../../../helpers/Api";

interface inputProps {
  setLoading: (value: boolean) => void;
  modalIsOpen: boolean;
  startupId: string;
  roundId: number;
  closeModal: () => void;
  dataToEditInvestor: editInvestorProps;
}

type StageSelector = {
  value: string;
  label: string;
};

type editInvestorProps = {
  id: number | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  firmName: string | null | undefined;
  email: string | null | undefined;
  amountInvested: number | null | undefined;
  investedType: string | null | undefined;
};

const EditInvestorFoundingInnerModal: FC<inputProps> = ({
  setLoading,
  modalIsOpen,
  startupId,
  closeModal,
  roundId,
  dataToEditInvestor,
}) => {
  const initialValues = {
    id: dataToEditInvestor?.id,
    firstName: dataToEditInvestor?.firstName || "",
    lastName: dataToEditInvestor?.lastName || "",
    firmName: dataToEditInvestor?.firmName || "",
    email: dataToEditInvestor?.email || "",
    amountInvested: dataToEditInvestor?.amountInvested || 0,
    investedType: dataToEditInvestor?.investedType || "",
  };

  useEffect(() => {
    setInvertmentInstruction({
      label: `${dataToEditInvestor?.investedType}`,
      value: `${dataToEditInvestor?.investedType}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen]);

  const [investmentInstruction, setInvertmentInstruction] =
    useState<StageSelector>({ label: "", value: "" });

  const handleInputInstrument = (option: StageSelector) => {
    return setInvertmentInstruction(option);
  };

  const handleEditInvestor = async (values: editInvestorProps) => {
    try {
      const response = await editInvestorFromRound(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          firmName: values.firmName,
          email: values.email,
          amountInvested: values.amountInvested,
          investedType: investmentInstruction.value,
        },
        startupId,
        roundId,
        dataToEditInvestor?.id as number
      );
      if (response) {
        Swal.fire({
          title: "Success",
          text: "Amazing you've added the investor information",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
          `,
        icon: "error",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Edit an investor</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          p={4}
          container
          display="flex"
          direction="column"
          alignItems="stretch"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(values: editInvestorProps) => {
              handleEditInvestor(values);
            }}
          >
            {(props) => (
              <Form>
                <Box mt={0} mb={4}>
                  <label htmlFor="firstName">
                    Add the round investor's information
                  </label>
                </Box>
                <Grid container display={"flex"} direction={"row"} spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      style={{ marginBottom: "12px" }}
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      className="form-control customField"
                    />

                    <ErrorMessage
                      name="firstName"
                      component="p"
                      className="requiredField"
                    />
                    <Field
                      type="text"
                      name="firmName"
                      placeholder="Firm name"
                      className="form-control customField"
                    />
                    <ErrorMessage
                      name="firmName"
                      component="p"
                      className="requiredField"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      style={{ marginBottom: "12px" }}
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      className="form-control customField"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className="requiredField"
                    />
                    <Field
                      type="email"
                      name="email"
                      placeholder="email address firm"
                      className="form-control customField"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="requiredField"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={1}>
                      <label htmlFor="amount">Amount invested?</label>
                    </Box>
                    <NumericFormat
                      type="text"
                      prefix={"$"}
                      suffix={" USD"}
                      decimalSeparator={"."}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      placeholder="$0.0"
                      value={dataToEditInvestor?.amountInvested || 0}
                      onValueChange={(value) =>
                        props.setFieldValue("amountInvested", value.floatValue)
                      }
                      className="form-control"
                      style={{
                        textAlign: "left",
                        height: "51px",
                        borderRadius: "10px",
                        border: "1px solid #d1d1d1",
                        marginTop: "10px",
                        marginBottom: "16px",
                        alignContent: "center",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={0} mb={1}>
                      <label htmlFor="name">
                        What type of investment round is it?
                      </label>
                    </Box>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "10px",
                          border: "1px solid #d1d1d1",
                          height: "53px",
                          marginTop: "10px",
                          marginBottom: "16px",
                          alignContent: "center",
                        }),
                      }}
                      value={investmentInstruction}
                      options={InvestmentInstrument}
                      onChange={(option) =>
                        handleInputInstrument(option as StageSelector)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  mt={10}
                >
                  <Box textAlign={"center"}>
                    <button
                      type="submit"
                      className="button__secondary color__active"
                    >
                      Save Changes
                    </button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EditInvestorFoundingInnerModal;

import styled, { css } from "styled-components";

export const baseTextStyles = css`
  &&& {
    margin: 0;
  }
`;

export const Paragraph = styled.p<{
  color?: string;
  fontSize?: number;
  fontWeight?: string;
  $wordWrap?: "normal" | "break-word";
  $underline?: boolean;
  ellipsis?: boolean;
}>`
  ${baseTextStyles}
  &&& {
    color: ${(p) => p.color ?? "#1E1E1E"};
    margin: 0;
    word-wrap: ${(p) => p.$wordWrap};
  }
  ${(p) => `font-size: ${p.fontSize || 12}px`};
  font-weight: ${(p) => p.fontWeight};
  text-decoration: ${(p) => p.$underline && "underline"};
  ${p => p.ellipsis && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

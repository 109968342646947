// Styled
import { ElementContainer } from "../../../../../styled/Dashboard";
import { Paragraph } from "../../../../../styled/Paragraph";

interface FloatingElementProps {
  count: number;
}

const FloatingElement: React.FC<FloatingElementProps> = ({ count }) => (
  <ElementContainer>
    <Paragraph fontSize={30} fontWeight='700'>{count}</Paragraph>
    <Paragraph fontSize={14} color="gray">total visits</Paragraph>
  </ElementContainer>
);

export default FloatingElement
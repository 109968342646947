import React, { FC } from "react";
import Modal from "react-modal";
import { Box, Grid } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LiaTimesSolid } from "react-icons/lia";
import Swal from "sweetalert2";
//API Calls
import { createInviteForFounderProfile } from "../../../../../../helpers/Api";

interface newFounderInputProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  userId: string;
}

interface newFounderFormProps {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  role: string | null | undefined;
}

const InviteFounderInnerModal: FC<newFounderInputProps> = ({
  modalIsOpen,
  closeModal,
  userId,
}) => {
  const initialValues: newFounderFormProps = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  };

  const handleInviteFounder = async (values: newFounderFormProps) => {
    try {
      const response = await createInviteForFounderProfile(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.role,
        },
        userId
      );
      if (response) {
        Swal.fire({
          title: "Success",
          text: "Amazing you've invited another founder to join ",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });

        closeModal();
      }
    } catch (error) {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
          `,
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Invite a Co-founder</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values: newFounderFormProps) => {
            handleInviteFounder(values);
          }}
        >
          {() => (
            <Form>
              <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                spacing={3}
              >
                <Grid item mt={4} xs={6}>
                  <label htmlFor="firstName">First Name</label>
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="e.g. John"
                    className="form-control customField"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="p"
                    className="requiredField"
                  />
                </Grid>
                <Grid item mt={4} xs={6}>
                  <label htmlFor="lastName">Last Name</label>
                  <Field
                    type="text"
                    name="lastName"
                    placeholder="e.g. Doe"
                    className="form-control customField "
                  />
                  <ErrorMessage
                    name="lastName"
                    component="p"
                    className="requiredField"
                  />
                </Grid>
                <Grid item mt={4} xs={6}>
                  <label htmlFor="email">Email</label>
                  <Field
                    style={{ height: "53px" }}
                    type="text"
                    name="email"
                    placeholder="e.g. ecoyote@acme.com"
                    className="form-control  mb-3"
                  />
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="requiredField"
                  />
                </Grid>
                <Grid item mt={4} xs={6}>
                  <label htmlFor="role">Role</label>
                  <Field
                    style={{ height: "53px" }}
                    type="text"
                    name="role"
                    placeholder="e.g. CEO"
                    className="form-control  mb-3"
                  />
                  <ErrorMessage
                    name="role"
                    component="p"
                    className="requiredField"
                  />
                </Grid>
              </Grid>
              <Box mt={6} textAlign={"center"}>
                <button type="submit" className="button__primary color__active">
                  Invite
                </button>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default InviteFounderInnerModal;

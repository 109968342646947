import React, { FC, useContext, useState } from "react";
import { Grid, Box } from "@mui/material";
import { BsFillPlusCircleFill } from "react-icons/bs";
import ModalChoice from "../ModalChoice/ModalChoice";
import { AppContext } from "../../../../../context";
import MainCard from "../../../../../components/components/MainCard";
import { Paragraph } from "../../../../../styled/Paragraph";

interface CardEmptyProps {
  title: string;
  description: string;
  titleButton: string;
  idButtonOpener: string;
  userId: string;
}

const CardCompanyNoContent: FC<CardEmptyProps> = ({
  title,
  description,
  titleButton,
  idButtonOpener,
  userId,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalId, setModalId] = useState<string>("");
  const { getStartupProfile } = useContext(AppContext);

  const openTypeModal = (idButtonOpener: string) => {
    setModalId(idButtonOpener);
    openModal();
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    getStartupProfile();
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item md={12}>
        <MainCard title={title} subtext={(title === 'Investment' || title === 'Video') ? "Optional" : "Required *"}>
          <Box sx={{ p: 2 }}>
            <Paragraph fontSize={18}>{description}</Paragraph>
            <Box mt={2}>
              <button
                className="button__action color__active"
                onClick={() => openTypeModal(idButtonOpener)}
              >
                <BsFillPlusCircleFill style={{ marginRight: "8px" }} />
                {titleButton}
              </button>
            </Box>
          </Box>
        </MainCard>
      </Grid>
      <ModalChoice
        modalIsOpen={modalIsOpen}
        idButtonOpener={modalId}
        closeModal={closeModal}
        userId={userId}
      />
    </Grid>
  );
};

export default CardCompanyNoContent;

import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import { AxiosRequestConfig } from "axios";
import UAParser from 'ua-parser-js';

const parser = new UAParser();
const result = parser.getResult();

const setCookie = (configuration: AxiosRequestConfig) => {
  const cookies = Cookies.get();
  const browser = `${result.browser.name} ${result.browser.version}`;
  const os = `${result.os.name} ${result.os.version}`;
  const deviceType = result.device.type || 'Desktop';

  if (!cookies.device_id) {
    const uuid = uuidv4();
    Cookies.set('device_id', uuid, { expires: 7 });
    if (!configuration.headers) {
      configuration.headers = {};
    }
    configuration.headers['X-Device-Id'] = uuid;
  } else {
    if (!configuration.headers) {
      configuration.headers = {};
    }
    configuration.headers['X-Device-Id'] = cookies.device_id;
  }

  if (!cookies.browser) {
    Cookies.set('browser', browser, { expires: 7 });
  }

  if (!cookies.os) {
    Cookies.set('os', os, { expires: 7 });
  }

  if (!cookies.deviceType) {
    Cookies.set('deviceType', deviceType, { expires: 7 });
  }
};

const getCookiesData = () => {
  const cookies = Cookies.get();
  
  const email = cookies.email || null;
  const device_id = cookies.device_id || null;
  const browser = cookies.browser || null;
  const os = cookies.os || null;
  const deviceType = cookies.deviceType || null;

  return {
    email,
    device_id,
    browser,
    os,
    deviceType
  };
};


export {setCookie, getCookiesData};

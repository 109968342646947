import React, { useState, useEffect, FC, useContext } from "react";
import {
  Avatar,
  Badge,
  IconButton,
  Box,
  Grid,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { BiSolidPencil } from "react-icons/bi";
import { BiAlignLeft } from "react-icons/bi";
import { getProfileData } from "../../../helpers/Api";
import Swal from "sweetalert2";
import moment from "moment";
import { TbRefresh } from "react-icons/tb";
//Custom components
import ReadModeProfile from "./components/ReadModeProfile";
import EditModeProfile from "./components/EditModeProfile";
//Api Function Helper
import { uploadProfilePicture } from "../../../helpers/Api";
import { AppContext } from "../../../context";
import { Paragraph } from "../../../styled/Paragraph";

const ProfilePage: FC = () => {
  interface InputProfileData {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    tagLine: string | null | undefined;
    email: string | null | undefined;
    phoneNumber: string | null | undefined;
    bio: string | undefined;
    experiences: Experience[];
    linkedinUrl: string | null | undefined;
    xUrl: string | null | undefined;
    websiteUrl: string | null | undefined;
    calendlyUrl: string | null | undefined;
    pictureUrl: string | null | undefined;
  }

  interface Experience {
    title: string | null | undefined;
    company: string | null | undefined;
    startDate: string | null | undefined;
    endDate: string | null | undefined;
  }

  const [pictureUrl, setpictureUrl] = useState<string | undefined>(undefined);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [profileData, setProfileData] = useState<InputProfileData>(
    {} as InputProfileData
  );
  const { setUserData } = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const fetchData = async () => {
    try {
      const data = await getProfileData();
      if (data) {
        setUserData(data.firstName);
        setpictureUrl(data?.pictureUrl);
        setProfileData({
          ...data,
          experiences: data?.experiences.map(
            (exp: {
              title: string;
              company: string;
              startDate: moment.MomentInput;
              endDate: moment.MomentInput;
            }) => ({
              ...exp,
              startDate: exp.startDate
                ? moment(exp.startDate).format("MM/DD/YYYY")
                : null,
              endDate: exp.endDate
                ? moment(exp.endDate).format("MM/DD/YYYY")
                : null,
            })
          ),
        });
        if (data?.pictureUrl) {
          setpictureUrl(data?.pictureUrl);
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
            `,
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      }); // Set error state to show in UI
    } finally {
      setLoading(false);
    }
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    viewType: boolean
  ) => {
    viewType !== null && setIsEditing(viewType);
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      try {
        await uploadProfilePicture(formData);
        fetchData(); // Fetch data again to update the ProfilePage component
      } catch (error) {
        Swal.fire("Oops!, Error uploading profile picture", "error");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Grid container direction="column">
          <Grid
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            md={12}
          >
            <CircularProgress size={70} color="inherit" />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className="profile__box"
            direction="column"
            alignItems="stretch"
          >
            <Grid item md={12}>
              <Paragraph fontSize={14} fontWeight="500">
                Showcase your expertise
              </Paragraph>

              <Box display="flex" flexDirection="row" alignItems="center">
                <Paragraph fontSize={40} fontWeight="400" color="#035928">
                  Your professional identity
                </Paragraph>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className="profile__box"
            direction="row"
          >
            <Grid display={"flex"} direction={"column"} item xs={6}>
              <Avatar
                alt={`${profileData?.firstName} ${profileData?.lastName}`}
                src={pictureUrl}
                className="profile-avatar"
                sx={{
                  width: 80,
                  height: 80,
                  backgroundColor: "rgba(105, 226, 142, 0.6)",
                }}
              >
                {!pictureUrl && (
                  <span style={{ fontSize: "2em" }}>
                    {`${profileData?.firstName?.charAt(
                      0
                    )}${profileData?.lastName?.charAt(0)}`}
                  </span>
                )}
              </Avatar>
              <div className="profile__input_wrapper">
                <button className="profile__input_button">
                  <TbRefresh color="#ACB8BC" size={16} />
                </button>
                <input
                  id="profile-picture-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </Grid>
            <Grid
              item
              display={"flex"}
              justifyContent={{ lg: "center", xs: "right" }}
              xs={6}
              alignContent={"center"}
            >
              <ToggleButtonGroup
                value={isEditing}
                exclusive
                onChange={handleAlignment}
                className="button__edit color__active"
                sx={{
                  backgroundColor: "#04BF7B",
                  borderRadius: "8px",
                  padding: "8px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                aria-label="visualize option"
              >
                <ToggleButton
                  sx={{ border: "none", borderRadius: "15px" }}
                  value={true}
                  aria-label="edit profile"
                  style={{ color: "#FFFFFF" }}
                >
                  <BiSolidPencil size={22} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          {!isEditing ? (
            <ReadModeProfile profileData={profileData} />
          ) : (
            <EditModeProfile
              profileData={profileData}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProfilePage;

import MainCard from "../../../../../components/components/MainCard";
import { Paragraph } from "../../../../../styled/Paragraph";
import React, { FC, useContext, useState } from "react";
import { Grid, Box, Avatar } from "@mui/material";
import Swal from "sweetalert2";
import { BiSolidPencil } from "react-icons/bi";
import { BsFillPlusCircleFill, BsPatchCheck } from "react-icons/bs";
import { MdAccessTime } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import EditFounderInnerModal from "../ModalChoice/Modals/EditFounderInnerModal";
import InviteFounderInnerModal from "../ModalChoice/Modals/InviteFounderInnerModal";
import { deleteFounderProfile } from "../../../../../helpers/Api";
import { PublicFounder } from "../../../../../types/startup";
import { AppContext } from "../../../../../context";

interface CardChoosingProps {
  dataFounders: PublicFounder[];
  userId: string;
  isReadOnly?: boolean;
}

const CardFounders: FC<CardChoosingProps> = ({
  dataFounders,
  userId,
  isReadOnly = false,
}) => {
  const startupId = userId?.toString() || "";
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalAddNewFounder, setModalAddNewFounder] = useState<boolean>(false);
  const { getStartupProfile } = useContext(AppContext);

  const [founder, setFounder] = useState<PublicFounder>({
    id: 0,
    isConfirmed: false,
    isMain: false,
    firstName: "",
    lastName: "",
    pictureUrl: "",
    email: "",
    role: "",
  });

  const openModal = (founder: PublicFounder) => {
    if (!isReadOnly) {
      setModalIsOpen(true);
      setFounder(founder);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    getStartupProfile();
  };

  const openModalInviteFounder = () => {
    if (!isReadOnly) {
      setModalAddNewFounder(true);
    }
  };

  const closeModalInviteFounder = () => {
    setModalAddNewFounder(false);
    getStartupProfile();
  };

  const handlerDeleteMessage = async (founderId: string) => {
    if (isReadOnly) {
      return;
    }

    Swal.fire({
      title: "Are you sure to delete this profile?",
      text: "It will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal2-confirm btn btn-primary",
        cancelButton: "swal2-cancel btn btn-secondary",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteFounderProfile(startupId, founderId);
          if (response) {
            Swal.fire(
              "Success",
              "The profile was deleted successfully",
              "success"
            );
            closeModalInviteFounder();
          }
        } catch (error) {
          Swal.fire(
            "Oops",
            "There is a problem deleting the profile. Please try again later.",
            "error"
          );
        }
      }
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item md={12}>
        <MainCard title="Founding Team" subtext="Required *">
          <Grid container direction={"row"} justifyContent="flex-start">
            {dataFounders?.map((founder, index) => (
              <Grid
                key={index}
                item
                xs={8}
                sm={6}
                md={4}
                borderRadius={"15px"}
                sx={{
                  margin: "20px",
                  boxShadow:
                    "0px -3.792px 151.692px 0px rgba(4, 191, 123, 0.07), 0px -1.584px 63.373px 0px rgba(4, 191, 123, 0.05), 0px -0.847px 33.882px 0px rgba(4, 191, 123, 0.04), 0px -0.475px 18.994px 0px rgba(4, 191, 123, 0.04), 0px -0.252px 10.088px 0px rgba(4, 191, 123, 0.03), 0px -0.105px 4.198px 0px rgba(4, 191, 123, 0.02)",
                  position: "relative",
                  maxWidth: { xs: "100%", sm: "80%", md: "100%" },
                }}
              >
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  position="relative"
                >
                  {!isReadOnly && !founder.isMain && (
                    <button
                      onClick={() =>
                        handlerDeleteMessage(founder?.id?.toString()!)
                      }
                      className="button__delete color__active"
                      style={{
                        backgroundColor: "red", // Red
                        borderRadius: "8px",
                        padding: "8px",
                        marginRight: "10px",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease",
                      }}
                    >
                      <AiOutlineDelete size={18} />
                    </button>
                  )}
                  {!isReadOnly && (
                    <button
                      onClick={() => openModal(founder)}
                      className="button__edit color__active"
                      style={{
                        backgroundColor: "#04BF7B",
                        borderRadius: "8px",
                        padding: "8px",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease",
                      }}
                    >
                      <BiSolidPencil size={18} />
                    </button>
                  )}
                  {!isReadOnly &&
                    (founder.isConfirmed ? (
                      <BsPatchCheck
                        color="#04BF7B"
                        size={20}
                        style={{
                          position: "absolute",
                          bottom: -180,
                          right: 10,
                        }}
                      />
                    ) : (
                      <MdAccessTime
                        color="#FF0000"
                        size={20}
                        style={{
                          position: "absolute",
                          bottom: -180,
                          right: 10,
                        }}
                      />
                    ))}
                </Box>
                <Grid
                  container
                  display={"flex"}
                  direction={"column"}
                  alignItems={"center"}
                  p={2}
                >
                  <Box pb={2}>
                    <Avatar
                      sx={{ width: 60, height: 60 }}
                      alt={`${founder.firstName}`}
                      src={founder.pictureUrl || ""}
                    >
                      {" "}
                      {!founder.pictureUrl && `${founder.firstName[0]}`}
                    </Avatar>
                  </Box>

                  <Paragraph fontSize={18} fontWeight="500">
                    {founder.firstName}{" "}
                  </Paragraph>

                  <Paragraph fontSize={18} fontWeight="500">
                    {founder.lastName}
                  </Paragraph>

                  <Paragraph fontSize={16} fontWeight="500">
                    {founder.role || "Add a role"}
                  </Paragraph>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            //  mt={4}
            p={2}
          >
            <Grid item md={12}>
              {!isReadOnly && (
                <button
                  className="button__action color__active"
                  onClick={openModalInviteFounder}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 12px",
                    backgroundColor: "#04BF7B",
                    borderRadius: "8px",
                    color: "#FAF9F6",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <BsFillPlusCircleFill style={{ marginRight: "8px" }} />
                  Invite
                </button>
              )}
            </Grid>
            <InviteFounderInnerModal
              modalIsOpen={modalAddNewFounder}
              closeModal={closeModalInviteFounder}
              userId={startupId}
            />
            <EditFounderInnerModal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              firstName={founder.firstName}
              lastName={founder.lastName}
              email={founder.email}
              role={founder.role}
              userId={startupId}
              founderId={founder.id!}
              isConfirmed={founder.isConfirmed}
            />
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default CardFounders;

import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions, ChartData } from 'chart.js';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format, parseISO } from 'date-fns';
import {
    Box,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import FloatingElement from './FloatingElement';
import { Paragraph } from '../../../../../styled/Paragraph';
import { TopHeaderChart } from '../../../../../styled/Dashboard';
import { formatDataChart, getTotalVisits } from '../../../../../helpers/DataCharts';
import { GraphData } from '../../../../../types/dashboard';

ChartJS.register(ChartDataLabels);

interface LineChartsProps {
    title: string;
    color: string;
    dataChart?: GraphData;
    graph: string;
}

interface TimeOptions {
    value: string;
    label: string;
}

const LineCharts: React.FC<LineChartsProps> = ({ title, color, dataChart, graph }) => {
    const timeOptions: TimeOptions[] = [
        { value: 'lastWeek', label: 'Last week' },
        { value: 'lastMonth', label: 'Last month' },
        { value: 'lastSixMonth', label: 'Last six months' },
        { value: 'lastYear', label: 'Last year' }
    ];

    const [selectedOption, setSelectedOption] = React.useState<string>(timeOptions[0].value);

    const [maxValue, setMaxValue] = React.useState<number>(10);
    const [stepSize, setStepSize] = React.useState<number>(10);

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedOption(event.target.value as string);
    };

    const getChartType = () => {
        return `${graph}-${selectedOption}`
    }

    const chartData = formatDataChart(dataChart, getChartType(), color)
    const totals = getTotalVisits(dataChart, getChartType())
    const options: ChartOptions<'line'> = {
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                suggestedMin: 0,
                suggestedMax: maxValue,
                ticks: {
                    stepSize,
                }
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false, // Disable the default tooltip
                external: (context) => {
                    // Custom tooltip
                    const tooltipModel = context.tooltip;

                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.style.opacity = '0';
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.background = color;
                        tooltipEl.style.color = 'white';
                        tooltipEl.style.borderRadius = '3px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.transform = 'translate(-50%, 0)';
                        tooltipEl.style.transition = 'opacity 0.3s ease';

                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    // Set caret position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem: any) {
                        return bodyItem.lines;
                    }

                    // Set text
                    if (tooltipModel.body) {
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = '<div>';

                        bodyLines.forEach(function (body, i) {
                            innerHtml += `<div>${body} visits</div>`;
                        });
                        innerHtml += '</div>';

                        tooltipEl.innerHTML = innerHtml;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();

                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipModel.height * 1.1 + 'px';
                    tooltipEl.style.padding = 10 + 'px ' + 10 + 'px';
                }
            }
        },
    };

    const chartRef = useRef<ChartJS<'line'>>(null);

    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
            chart.update();
        }
    }, [selectedOption]);

    return (
        <>
            <TopHeaderChart>
                <Paragraph fontSize={24} fontWeight={'400'} color={'rgba(3, 89, 40, 1)'}>{title}</Paragraph>
                <Select
                    sx={{ height: 30, color: '#04BF7B' }}
                    value={selectedOption}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {timeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </TopHeaderChart>
            <Box
                mt={2}
                position="relative"
                borderRadius="15px"
                boxShadow={'0px -2.224px 88.97px 0px rgba(0, 0, 0, 0.07), 0px -0.929px 37.169px 0px rgba(0, 0, 0, 0.05), 0px -0.497px 19.873px 0px rgba(0, 0, 0, 0.04), 0px -0.279px 11.14px 0px rgba(0, 0, 0, 0.04), 0px -0.148px 5.917px 0px rgba(0, 0, 0, 0.03), 0px -0.062px 2.462px 0px rgba(0, 0, 0, 0.02);'}
                sx={{
                    backgroundColor: 'white',
                    padding: '4rem 2rem 2rem 4rem'
                }}
            >
                <div>&nbsp;</div>
                <FloatingElement count={totals}/>
                <Line ref={chartRef} data={chartData as ChartData<'line'>} options={options} />
            </Box>
        </>
    )
}

export default LineCharts;

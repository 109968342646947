import styled from "styled-components";

export const ButtonSecondary = styled.button<{$isDisabled?: boolean, $height?: number, $notSelected?: boolean}>`
  border-radius: 10px;
  border: none;
  height: ${p => p.$height ? `${p.$height}px` : '4rem'};
  width: 10rem;
  font-weight: 500;
  background: ${p => p.$isDisabled ? '#0000001f' : '#bdfdcc'};
  color: ${p => p.$isDisabled ? '#00000042' : '#04BF7B'};
`

export const ButtonPrimary = styled(ButtonSecondary)<{width?: string}>`
  background: ${p => p.$notSelected ? '#CEF2D7' : '#04BF7B'};
  color: ${p => p.$notSelected ? '#04BF7B' : 'white'};
  ${p => p.width && `width: ${p.width}` };
`
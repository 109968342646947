import React, { FC, useState } from "react";
import Modal from "react-modal";
import { Box, Grid, Slider } from "@mui/material";
import { LiaTimesSolid } from "react-icons/lia";
import Rating from "@mui/material/Rating";
import { AiOutlineTeam } from "react-icons/ai";
import { GoAlert } from "react-icons/go";
import { HiOutlineLightBulb } from "react-icons/hi";
import { PiStrategyLight } from "react-icons/pi";
import { BiPieChartAlt2 } from "react-icons/bi";
import { CategoryFeedback } from "../../../../../styled/Dashboard";
import { Review } from "../../../../../types/dashboard";
import { Paragraph } from "../../../../../styled/Paragraph";

type InputProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  review: Review;
};

const IndividualFeedbackModal: FC<InputProps> = ({
  modalIsOpen,
  closeModal,
  review,
}) => {
  const [category, setCategory] = useState<string>("team");

  const getCategoryValue = (category: string): number => {
    switch (category) {
      case "team":
        return review.teamValue ?? 0;
      case "problem":
        return review.problemValue ?? 0;
      case "solution":
        return review.solutionValue ?? 0;
      case "gtm":
        return review.gtmstrategyValue ?? 0;
      case "market":
        return review.marketoppValue ?? 0;
      default:
        return 0;
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Visitor's Feedback</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid container display="flex" p={4}>
        <Grid item xs={12}>
          <Paragraph fontSize={20} fontWeight="500">
            Overall Rating
          </Paragraph>
        </Grid>
        <Grid display={"flex"} justifyContent={"center"} item xs={12}>
          <Rating
            name="size-large"
            readOnly
            value={review.overalRating ?? 0}
            sx={{
              color: "#04BF7B",
              "& .MuiRating-iconFilled": {
                color: "#04BF7B",
              },
              fontSize: "4rem",
            }}
          />
        </Grid>
        <Grid display={"flex"} padding={"2rem 0rem 0rem 0rem"} item xs={12}>
          <Paragraph fontSize={16} fontWeight="500">
            Please select the tab below for detailed feedback
          </Paragraph>
        </Grid>
      </Grid>
      <Grid
        container
        display={"flex"}
        direction={"row"}
        justifyContent={"space-evenly"}
        className="overall__feedback_buttons"
        pt={1}
      >
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "team"}
            onClick={() => setCategory("team")}
          >
            <AiOutlineTeam
              size={40}
              color={category === "team" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>Team</p>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "problem"}
            onClick={() => setCategory("problem")}
          >
            <GoAlert
              size={40}
              color={category === "problem" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>Problem</p>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "solution"}
            onClick={() => setCategory("solution")}
          >
            <HiOutlineLightBulb
              size={40}
              color={category === "solution" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>Solution</p>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "gtm"}
            onClick={() => setCategory("gtm")}
          >
            <PiStrategyLight
              size={40}
              color={category === "gtm" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>GTM strategy</p>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "market"}
            onClick={() => setCategory("market")}
          >
            <BiPieChartAlt2
              size={40}
              color={category === "market" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>Market Opp</p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sx={{ padding: "0px 64px 0px 64px", width: "100%" }}>
          {category && (
            <Slider
              sx={{
                height: "30%",
                color: "#DDD",
                "& .MuiSlider-rail": {
                  opacity: 0.5,
                },
                "& .MuiSlider-track": {
                  color: "#04BF7B",
                },
                "& .MuiSlider-thumb": {
                  color: "#04BF7B",
                },
              }}
              aria-label="Score"
              value={getCategoryValue(category)}
              disabled={true}
              valueLabelDisplay="auto"
              step={1}
              marks={[
                { value: 1, label: "Limited" },
                { value: 5, label: "Exceptional" },
              ]}
              min={1}
              max={5}
            />
          )}
        </Grid>
      </Grid>
      {review.details ? (
        <Grid container>
          <Grid display={"flex"} padding={"2rem 0rem 0rem 2rem"} item xs={12}>
            <h6>Other Feedback </h6>
          </Grid>
          <Box
            padding={2}
            sx={{
              border: "1px solid #04BF7B",
              margin: "1.4rem",
              minWidth: "95%",
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <p>{review?.details ?? "-"}</p>
          </Box>
        </Grid>
      ) : null}
    </Modal>
  );
};

export default IndividualFeedbackModal;

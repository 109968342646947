import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-12"
          style={{ textAlign: "center", margin: "20vh 0px 0px" }}
        >
          <h1>Opps!</h1>
          <h5 className="mb-4">
            It seems that what you are looking for does not exist
          </h5>
          <Link to="/" className="btn btn-dark">
            Return to top
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;

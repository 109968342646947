import React from 'react';
import List from '@mui/material/List';

import { IoPeopleOutline } from "react-icons/io5";
import { FaEye, FaPlay } from "react-icons/fa";
import { CiShare2 } from "react-icons/ci";
import ListItemComponent from './ListItemComponent';
import { InitialDashBoard } from '../../../../../types/dashboard';

interface AlignItemsListProps {
  data?: InitialDashBoard
}

const AlignItemsList: React.FC<AlignItemsListProps> = ({ data }) => {
  return (
    <List sx={{
      boxShadow:
      '0px -3.792px 151.692px 0px rgba(4, 191, 123, 0.07), 0px -1.584px 63.373px 0px rgba(4, 191, 123, 0.05), 0px -0.847px 33.882px 0px rgba(4, 191, 123, 0.04), 0px -0.475px 18.994px 0px rgba(4, 191, 123, 0.04), 0px -0.252px 10.088px 0px rgba(4, 191, 123, 0.03), 0px -0.105px 4.198px 0px rgba(4, 191, 123, 0.02)',
      backgroundColor: 'white',
      borderRadius: '15px',
      padding: 6
    }}>
      <ListItemComponent
        icon={<IoPeopleOutline size={23} color="rgba(4, 191, 123, 1)" />}
        primaryText={data?.totalVisitor.total.toString() ?? '0'}
        secondaryText="Total visitors"
        tertiaryText={data?.totalVisitor.last_24_hours.toString() ?? '0'}
      />
      <ListItemComponent
        icon={<FaEye size={23} color="rgba(4, 191, 123, 1)" />}
        primaryText={data?.pitchViews.total.toString() ?? '0'}
        secondaryText="Pitch views"
        tertiaryText={data?.pitchViews.last_24_hours.toString() ?? '0'}
      />
      <ListItemComponent
        icon={<FaPlay size={23} color="rgba(4, 191, 123, 1)" />}
        primaryText={data?.videoViews.total.toString() ?? '0'}
        secondaryText="Video views"
        tertiaryText={data?.videoViews.last_24_hours.toString() ?? '0'}
      />
      <ListItemComponent
        icon={<CiShare2 size={23} color="rgba(4, 191, 123, 1)" />}
        primaryText={data?.totalShare.total.toString() ?? '0'}
        secondaryText="Total shares"
        tertiaryText={data?.totalShare.last_24_hours.toString() ?? '0'}
      />
    </List>
  );
}

export default AlignItemsList;

import React, { FC, useState, useEffect, useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  getIn,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import { AiOutlineDelete } from "react-icons/ai";

// Custom components
import { createProfileData } from "../../../../helpers/Api";
import ModalAddExperience from "./ModalAddExperience/ModalAddExperience";
import { AppContext } from "../../../../context";

interface InputProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  profileData: InputProfileData | undefined;
}

interface InputProfileData {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  tagLine: string | null | undefined;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  bio: string | undefined;
  experiences: Experience[];
  linkedinUrl: string | null | undefined;
  xUrl: string | null | undefined;
  websiteUrl: string | null | undefined;
  calendlyUrl: string | null | undefined;
  pictureUrl: string | null | undefined;
}

interface Experience {
  title: string | null | undefined;
  company: string | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}

const EditModeProfile: FC<InputProps> = ({ setIsEditing, profileData }) => {
  useEffect(() => {
    if (profileData) {
      setCharCount(profileData?.bio?.length ?? 0);
    }
  }, [profileData]);

  const [charCount, setCharCount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [currentExperienceIndex, setCurrentExperienceIndex] =
    useState<number>(-1);
  const { setIsChangeName } = useContext(AppContext);

  const openModalAddExp = () => {
    setModalIsOpen(true);
  };
  const closeModalAddExp = () => {
    setModalIsOpen(false);
  };

  const initialValues: InputProfileData = {
    firstName: profileData?.firstName || "",
    lastName: profileData?.lastName || "",
    tagLine: profileData?.tagLine || "",
    email: profileData?.email || "",
    phoneNumber: profileData?.phoneNumber || "",
    bio: profileData?.bio || "",
    experiences: profileData?.experiences || [],
    linkedinUrl: profileData?.linkedinUrl || "",
    xUrl: profileData?.xUrl || "",
    websiteUrl: profileData?.websiteUrl || "",
    calendlyUrl: profileData?.calendlyUrl || "",
    pictureUrl: profileData?.pictureUrl || "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    tagLine: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string().required("Required"),
    bio: Yup.string().required("Required"),
    experiences: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Required"),
        company: Yup.string().required("Required"),
        startDate: Yup.string()
          .required("Start date is required")
          .matches(
            /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
            "Date must be in MM/DD/YYYY format"
          )
          .test(
            "startDate",
            "Start Date cannot be in the future",
            function (value) {
              return moment(value, "MM/DD/YYYY").toDate() <= moment().toDate();
            }
          ),
        endDate: Yup.string()
          .nullable()
          .when("startDate", (startDate, schema) =>
            startDate
              ? schema
                  .nullable()
                  .test(
                    "endDate",
                    "End Date must be after Start Date",
                    function (value) {
                      return (
                        !value ||
                        moment(value, "MM/DD/YYYY").toDate() >=
                          moment(startDate, "MM/DD/YYYY").toDate()
                      );
                    }
                  )
              : schema
          )
          .matches(
            /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
            "Date must be in MM/DD/YYYY format"
          )
          .matches(
            /^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?\d\d$/,
            "Date must be in MM/DD/YYYY format"
          )
          .test(
            "endDate",
            "End Date cannot be in the future",
            function (value) {
              return (
                !value ||
                moment(value, "MM/DD/YYYY").toDate() <= moment().toDate()
              );
            }
          ),
      })
    ),

    linkedinUrl: Yup.string()
      .url("Invalid URL")
      .required("LinkedIn URL is required"),
    xUrl: Yup.string().url("Invalid URL"),
    websiteUrl: Yup.string().url("Invalid URL"),
    calendlyUrl: Yup.string().url("Invalid URL"),
  });

  const handleSubmit = async (values: InputProfileData) => {
    // Convert dates to ISO format before submitting
    const formattedValues = {
      ...values,
      experiences: values.experiences.map((exp) => ({
        ...exp,
        startDate: exp.startDate
          ? moment(exp.startDate, "MM/DD/YYYY").toISOString()
          : null,
        endDate: exp.endDate
          ? moment(exp.endDate, "MM/DD/YYYY").toISOString()
          : null,
      })),
    };

    try {
      await createProfileData(formattedValues);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your profile has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsEditing(true);
      setIsChangeName(true);
    } catch (error) {
      throw error;
    }
  };

  // TODO: Save in documentation for external cases
  // Listen for form dirty state
  // const DirtyListener = () => {
  //   const { dirty } = useFormikContext<InputProfileData>();
  //   useEffect(() => {
  //     setFormDirty(dirty);
  //   }, [dirty]);

  //   return null;
  // };

  const deleteExperience = (arrayHelpers: any, index: any) => {
    Swal.fire({
      title: "Warning!",
      text: "You're about to delete this experience. Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: "Delete",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Back to Edit",
      showCancelButton: true,
      customClass: {
        confirmButton: "swal2-confirm btn btn-primary",
        cancelButton: "swal2-cancel btn btn-default",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        return arrayHelpers.remove(index);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, touched, errors, dirty, isValid }) => (
        <Form>
          {/* TODO: For documentation */}
          {/* <DirtyListener /> */}
          <Grid container>
            {dirty ? (
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={6}
                mt={0}
                sx={{
                  backgroundColor: "#fff",
                  top: 0,
                  position: "sticky",
                }}
              >
                <Grid item display={"flex"} md={6} sm={6} xs={12}></Grid>
                <Grid
                  display={"flex"}
                  justifyContent={{ md: "center", xs: "flex-end" }}
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  {dirty && isValid ? (
                    <button
                      className="button__action color__active"
                      style={{ marginBottom: "10px" }}
                      onClick={() => {
                        setIsEditing(false);
                        handleSubmit(values);
                      }}
                    >
                      Save
                    </button>
                  ) : (
                    <button className="button__action color__inactive">
                      Save
                    </button>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                mt={0}
                direction="row"
                alignItems="center"
                spacing={6}
              >
                <Grid item display={"flex"} md={6} sm={6} xs={12}></Grid>
                <Grid
                  display={"flex"}
                  justifyContent={{ md: "center", xs: "flex-end" }}
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  {dirty && isValid ? (
                    <button
                      className="button__action color__active"
                      onClick={() => {
                        setIsEditing(false);
                        handleSubmit(values);
                      }}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      style={{ color: "#9A9DA5" }}
                      className="button__action"
                      disabled
                    >
                      Save
                    </button>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid
              display={"flex"}
              alignItems={"center"}
              container
              mt={4}
              direction="row"
            >
              <Grid
                item
                className="profile__formsInteraction"
                xs={12}
                md={6}
                lg={4}
              >
                <p>First name</p>
              </Grid>
              <Grid item className="profile__descriptionForm" xs={12} md={6}>
                <Field
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className={`form-control customField mb-3 ${
                    touched.firstName
                      ? errors.firstName
                        ? "is-invalid"
                        : "is-valid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  name="firstName"
                  component="p"
                  className="requiredField"
                />
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              container
              mt={4}
              direction="row"
            >
              <Grid
                item
                className="profile__formsInteraction"
                xs={12}
                md={6}
                lg={4}
              >
                <p>Last name</p>
              </Grid>
              <Grid item className="profile__descriptionForm" xs={12} md={6}>
                <Field
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className={`form-control customField mb-3 ${
                    touched.lastName
                      ? errors.lastName
                        ? "is-invalid"
                        : "is-valid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  name="lastName"
                  component="p"
                  className="requiredField"
                />
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              container
              mt={4}
              direction="row"
            >
              <Grid
                item
                className="profile__formsInteraction"
                xs={12}
                md={6}
                lg={4}
              >
                <p>Tagline</p>
              </Grid>
              <Grid item className="profile__descriptionForm" xs={12} md={6}>
                <Field
                  type="text"
                  name="tagLine"
                  placeholder="tag Line"
                  className={`form-control customField mb-3 ${
                    touched.tagLine
                      ? errors.tagLine
                        ? "is-invalid"
                        : "is-valid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  name="tagLine"
                  component="p"
                  className="requiredField"
                />
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              container
              mt={4}
              direction="row"
            >
              <Grid
                item
                className="profile__formsInteraction"
                xs={12}
                md={6}
                lg={4}
              >
                <p>Contact info</p>
              </Grid>
              <Grid item className="profile__descriptionForm" xs={12} md={6}>
                <Field
                  type="email"
                  name="email"
                  className={`form-control customField mb-3`}
                  placeholder="Email"
                  disabled
                />
                <Field
                  name="phoneNumber"
                  type="tel"
                  className={`form-control customField mb-3`}
                  placeholder="Phone"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              container
              mt={4}
              direction="row"
            >
              <Grid
                item
                className="profile__formsInteraction"
                xs={12}
                md={6}
                lg={4}
              >
                <p>Bio</p>
              </Grid>
              <Grid item className="profile__descriptionForm" xs={12} md={6}>
                <textarea
                  name="bio"
                  style={{
                    width: "100%",
                    height: "200px",
                    resize: "none",
                  }}
                  className={`form-control customField mb-3 ${
                    touched.bio ? (errors.bio ? "is-invalid" : "is-valid") : ""
                  }`}
                  placeholder="Edit your profile by adding a brief biography! Tell us a bit about yourself and your journey in the industry*."
                  maxLength={250}
                  value={values.bio ?? ""}
                  onChange={(e) => {
                    setFieldValue("bio", e.target.value);
                    setCharCount(e.target.value.length);
                  }}
                />

                <Typography variant="caption" color="textSecondary">
                  {charCount}/250 characters
                </Typography>

                <div style={{ marginTop: "1.5rem" }}>
                  <ErrorMessage
                    name="bio"
                    component="p"
                    className="requiredField"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              container
              mt={2}
              direction="row"
            >
              <Grid
                item
                className="profile__formsInteraction"
                xs={12}
                md={6}
                lg={4}
              >
                <p>Contact info</p>
              </Grid>
              <Grid item className="profile__descriptionForm" xs={12} md={6}>
                <Box mb={5}>
                  <Typography variant="body1">LinkedIn Profile Link</Typography>
                  <Field
                    type="text"
                    name="linkedinUrl"
                    placeholder="LinkedIn Profile Link*"
                    value={values.linkedinUrl}
                    className={`form-control customField mb-3 ${
                      touched.linkedinUrl
                        ? errors.linkedinUrl
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="linkedinUrl"
                    component="p"
                    className="requiredField"
                  />
                </Box>
                <Box mb={5}>
                  <Typography variant="body1">Twitter Profile Link</Typography>
                  <Field
                    type="text"
                    name="xUrl"
                    value={values.xUrl}
                    className={`form-control customField mb-3 ${
                      touched.xUrl && values.xUrl ? "is-valid" : ""
                    }`}
                    placeholder="Twitter Profile Link"
                  />
                  <ErrorMessage
                    name="xUrl"
                    component="p"
                    className="requiredField"
                  />
                </Box>
                <Box mb={5}>
                  <Typography variant="body1">
                    Website, GitHub, or Personal Portfolio
                  </Typography>
                  <Field
                    type="text"
                    name="websiteUrl"
                    placeholder="Website, GitHub, or Personal Portfolio"
                    value={values.websiteUrl}
                    className={`form-control customField mb-3 ${
                      touched.websiteUrl
                        ? errors.websiteUrl
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="websiteUrl"
                    component="p"
                    className="requiredField"
                  />
                </Box>
                <Box mb={5}>
                  <Typography variant="body1">
                    Calendar Appointment Link (Recommended)*
                  </Typography>
                  <Field
                    type="text"
                    name="calendlyUrl"
                    value={values.calendlyUrl}
                    className={`form-control customField mb-3 ${
                      touched.calendlyUrl && values.calendlyUrl
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder="Calendar Appointment PageLink (e.g. https://cal.com/myname/30-min)"
                  />
                  <ErrorMessage
                    name="calendlyUrl"
                    component="p"
                    className="requiredField"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              container
              mt={4}
              direction="row"
            >
              <Grid
                item
                className="profile__formsInteraction"
                xs={12}
                md={6}
                lg={4}
              >
                <p>Work History</p>
              </Grid>
              <Grid item className="profile__descriptionForm" xs={12} md={6}>
                <FieldArray
                  name="experiences"
                  render={(arrayHelpers) => (
                    <Grid item xs={12}>
                      {values.experiences.length > 0 ? (
                        values.experiences.map((exp, index) => (
                          <Box key={index} mb={2} style={{ width: "100%" }}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={10}>
                                <Typography variant="body1">
                                  {exp.title || "No Title"} at{" "}
                                  {exp.company || "No Company"}
                                </Typography>
                                <Typography variant="body2">
                                  {exp.startDate || "No Start Date"} -{" "}
                                  {exp.endDate || "Present"}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: "right" }}>
                                <IconButton
                                  onClick={() =>
                                    deleteExperience(arrayHelpers, index)
                                  }
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    backgroundColor: "transparent",
                                    color: "#FF0000",
                                    justifyContent: "flex-end",
                                    display: "flex",
                                  }}
                                >
                                  <AiOutlineDelete size={36} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        ))
                      ) : (
                        <Typography>No experiences added yet.</Typography>
                      )}
                      <Box mt={2} textAlign="center">
                        {values?.experiences.length > 2 ? (
                          <button
                            type="button"
                            className="button__action color__inactive responsive"
                            onClick={openModalAddExp}
                            disabled
                          >
                            Experiences limit reached
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="button__action color__active responsive"
                            onClick={openModalAddExp}
                          >
                            Add Experience
                          </button>
                        )}
                      </Box>

                      <ModalAddExperience
                        arrayHelpers={arrayHelpers}
                        index={currentExperienceIndex}
                        modalIsOpen={modalIsOpen}
                        closeModal={closeModalAddExp}
                      />
                    </Grid>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditModeProfile;

import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import PumpJuiceLogo from "../../../../../assets/pompjuice_logo.svg";
import pomegranate from "../../../../../assets/pomegranate.svg";
import TermsPublicForm from "./TermsPublicForm";
import TermsPrivateForm from "./TermsPrivateForm";
import { PublicStartupResponse } from "../../../../../types/startup";

interface TermsProps {
  startUpId: any;
  email?: any;
  token?: any;
  isPrivate: boolean | null;
  setNewVisitor: React.Dispatch<React.SetStateAction<boolean | null>>;
  setStartUpData: React.Dispatch<React.SetStateAction<PublicStartupResponse | null | undefined>>;
}

const TermsComponent: React.FC<TermsProps> = ({
  startUpId,
  email,
  token,
  isPrivate,
  setNewVisitor,
  setStartUpData,
}) => {
  return (
    <Container maxWidth={false} disableGutters>
      <Grid container minHeight={"100vh"} className="login__box">
        <Grid
          container
          item
          xs={12}
          md={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className="login__brand"
        >
          <div className="login__semiCircle">
            <p></p>
          </div>
          <img src={PumpJuiceLogo} className="login_img" alt="PumpJuiceLogo" />
          <div className="login__brand_text">
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Terms & Conditions
            </p>
            <p> Please review our policies and guidelines</p>
          </div>
          <img
            src={pomegranate}
            className="login_pomegranate"
            alt="GitHub Logo"
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={8}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className="login__card"
        >
          <div style={{ paddingTop: "2rem" }}>
            <h2 style={{ textAlign: "center", marginBottom: "1rem" }}>
              Terms & Conditions
            </h2>
          </div>
          <Typography
            variant="body1"
            sx={{
              mb: 8,
              width: "100%",
              maxWidth: "500px",
              textAlign: "center",
            }}
          >
            {isPrivate ? (
              <TermsPrivateForm
                startUpId={startUpId}
                email={email}
                token={token}
                setNewVisitor={setNewVisitor}
                setStartUpData={setStartUpData}
              />
            ) : (
              <TermsPublicForm
                startUpId={startUpId}
                setNewVisitor={setNewVisitor}
                setStartUpData={setStartUpData}
              />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TermsComponent;

import React, { FC } from "react";
import Chip from "@mui/material/Chip";

type CustomChipProps = {
  label: string;
  modelType: string;
  modelChoosen: string;
  optionSelected: (businessModel: string) => void;
};

const CustomChip: FC<CustomChipProps> = ({
  label,
  modelType,
  modelChoosen,
  optionSelected,
}) => {
  const selectedStyles = {
    border: "none",
    borderRadius: "10px",
    margin: "10px",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "27px",
    backgroundColor: "#04BF7B",
    color: "#FFFFFF",
    boxShadow:
      "0px -3.792px 151.692px 0px rgba(4, 191, 123, 0.07), 0px -1.584px 63.373px 0px rgba(4, 191, 123, 0.05), 0px -0.847px 33.882px 0px rgba(4, 191, 123, 0.04), 0px -0.475px 18.994px 0px rgba(4, 191, 123, 0.04), 0px -0.252px 10.088px 0px rgba(4, 191, 123, 0.03), 0px -0.105px 4.198px 0px rgba(4, 191, 123, 0.02)",
    "&:hover": {
      backgroundColor: "#03A86A",
      color: "#FFFFFF",
      boxShadow: "0px 0px 10px rgba(4, 191, 123, 0.4)",
      border: "none",
    },
    "&:active": {
      backgroundColor: "#03A86A",
      color: "#FFFFFF",
    },
    "&.MuiChip-clickable:hover": {
      backgroundColor: "#03A86A",
      color: "#FFFFFF",
    },
  };

  const unselectedStyles = {
    border: "1px solid #04BF7B",
    borderRadius: "10px",
    margin: "10px",
    fontSize: "16px",
    padding: "27px",
    color: "#9A9DA5",
    "&:hover": {
      backgroundColor: "#E8F5E9",
      color: "#04BF7B",
    },
  };

  return (
    <>
      {modelType === modelChoosen ? (
        <Chip
          label={label}
          component="a"
          href="#basic-chip"
          variant="outlined"
          clickable
          onClick={() => optionSelected(modelType)}
          sx={selectedStyles}
        />
      ) : (
        <Chip
          label={label}
          component="a"
          href="#basic-chip"
          variant="outlined"
          clickable
          onClick={() => optionSelected(modelType)}
          sx={unselectedStyles}
        />
      )}
    </>
  );
};

export default CustomChip;

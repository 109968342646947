import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@mui/material";
import "chart.js/auto";
import LineCharts from "./components/Charts/LineCharts";
import AlignItemsList from "./components/List/AlignItemsList";
import VisitorsList from "./components/Table/VisitorsList";
import { getDashboardData } from "../../../api/dashboard";
import { AppContext } from "../../../context";
import { Paragraph } from "../../../styled/Paragraph";

interface StartupsData {
  name: string;
  id: number;
}

const Dashboard = () => {
  const { setInitialDashboard, initialDashboard } = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const blue = "rgba(0, 173, 255, 1)";
  const purple = "rgba(155, 89, 182, 1)";
  const darkPurple = "rgba(90, 56, 253, 1)";
  const lightPruple = "rgba(211, 138, 241, 1)";

  // const startupsMock = [
  //   {name: 'Healtech solutions', id: 3},
  //   {name: 'Other', id: 4},
  // ]
  // const [startups, setStartups] = useState<StartupsData[]>(startupsMock)
  // const [selectedStartup, setSelectedStartup] = useState<number | string>(startupsMock[0].id)

  useEffect(() => {
    const getData = async () => {
      try {
        const mixpanelData = await getDashboardData();
        setInitialDashboard(mixpanelData);
      } catch (err) {
        setError("Failed to fetch data");
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid className="profile__box" direction="column" alignItems="stretch">
        <Grid item md={12}>
          <Paragraph fontSize={14} fontWeight="500">
            Dive into your startup's analytics{" "}
          </Paragraph>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <Paragraph fontSize={40} fontWeight="400" color="#035928">
              {" "}
              Navigate your startup's success
            </Paragraph>

            {/* Disabled for now */}
            {/* <Select
                    sx={{ height: 30, color: '#04BF7B' }}
                    value={selectedStartup}
                    onChange={(e) => setSelectedStartup(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {startups.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select> */}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row"}
        alignItems={"start"}
        sx={{ display: "flex", alignItems: "stretch" }}
        spacing={4}
      >
        <Grid item xs={12} lg={6}>
          <AlignItemsList data={initialDashboard?.initialDashBoard} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <VisitorsList
            visitors={initialDashboard?.initialDashBoard?.recentActivity}
          />
        </Grid>
      </Grid>
      <Grid container p={2} direction={"column"}>
        <Grid style={{ maxWidth: "100%" }} item xs={12} mt={6}>
          <LineCharts
            title="Page Visits"
            color={blue}
            dataChart={initialDashboard?.initialDashBoard?.pageVisit}
            graph={"pageVisit"}
          />
        </Grid>
        <Grid style={{ maxWidth: "100%" }} item xs={12} mt={6}>
          <LineCharts
            title="Pitch Deck Views"
            color={purple}
            dataChart={initialDashboard?.initialDashBoard?.pitchDeckView}
            graph={"pitchDeckView"}
          />
        </Grid>
        <Grid style={{ maxWidth: "100%" }} item xs={12} mt={6}>
          <LineCharts
            title="Total Shares"
            color={darkPurple}
            dataChart={initialDashboard?.initialDashBoard?.totalShares}
            graph={"totalShares"}
          />
        </Grid>
        <Grid style={{ maxWidth: "100%" }} item xs={12} mt={6} mb={18}>
          <LineCharts
            title="Video View"
            color={lightPruple}
            dataChart={initialDashboard?.initialDashBoard?.videoView}
            graph={"videoView"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;

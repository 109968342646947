import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from './context';
import Users from './routes';
import './App.css';
import { ProjectProvider } from './context/ProjectProvider';
function App() {
  return (
    <div className = "App">
    <BrowserRouter>
      <AppProvider>
      <ProjectProvider>
        <Users />
        </ProjectProvider>
      </AppProvider>
     
    </BrowserRouter>
    </div>
  );
}

export default App;

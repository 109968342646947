import React, { FC, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import { LiaTimesSolid } from "react-icons/lia";
import Select from "react-select";
import Modal from "react-modal";
import Swal from "sweetalert2";
import ButtonGroup from "@mui/material/ButtonGroup";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormat } from "react-number-format";
//Custom components
import { StageCollection } from "../../../../../../helpers/StageSelector";
import { createRoundType, editRoundType } from "../../../../../../helpers/Api";

type IdProp = {
  setLoading: (value: boolean) => void;
  modalIsOpen: boolean;
  userId: string;
  closeModal: () => void;
};

type StageSelector = {
  value: string | null | undefined;
  label: string | null | undefined;
};

type CreateRoundProps = {
  roundType: string | null | undefined;
  amount: number | null | undefined;
  raisedAmount: number | null | undefined;
  date: Date | null | undefined;
  investors: string[];
};

const RoundTrackInnerModal: FC<IdProp> = ({
  setLoading,
  modalIsOpen,
  closeModal,
  userId,
}) => {
  const initialValues: CreateRoundProps = {
    roundType: "",
    amount: 0,
    raisedAmount: 0,
    date: null,
    investors: [],
  };

  const startupId = userId.toString();
  const [stage, setStage] = useState<StageSelector>({ label: "", value: "" });
  const [dates, setDates] = useState(null);

  const handlerDate = (choosenDates: any) => {
    setDates(choosenDates);
  };

  const handleInputStage = (option: StageSelector) => {
    return setStage(option);
  };

  const handleCreateRound = async (values: CreateRoundProps) => {
    try {
      const response = await createRoundType(
        {
          roundType: stage.value,
          amount: values.amount,
          raisedAmount: values.raisedAmount,
          date: dates,
          investors: [],
        },
        startupId
      );
      if (response) {
        Swal.fire({
          title: "Success",
          text: "Amazing you've added your Investment details",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
          `,
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">
            Let’s talk about your business
          </p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values: CreateRoundProps) => {
            handleCreateRound(values);
          }}
        >
          {(props) => (
            <Form>
              <Grid container mt={1} spacing={2}>
                <Grid item xs={12}>
                  <Box mt={0} mb={1}>
                    <label htmlFor="roundType">
                      What type of investment round is it?
                    </label>
                  </Box>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: "10px",
                        border: "1px solid #d1d1d1",
                        height: "53px",
                        marginTop: "10px",
                        marginBottom: "16px",
                        alignContent: "center",
                      }),
                    }}
                    value={stage}
                    options={StageCollection}
                    onChange={(option) =>
                      handleInputStage(option as StageSelector)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box mt={0} mb={1}>
                    <label htmlFor="amount">
                      What is the total value of the round?
                    </label>
                  </Box>
                  <NumericFormat
                    type="text"
                    prefix={"$"}
                    suffix={" USD"}
                    decimalSeparator={"."}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    placeholder="$0.0"
                    value={0}
                    onValueChange={(value) =>
                      props.setFieldValue("amount", value.floatValue)
                    }
                    className="form-control"
                    style={{
                      textAlign: "left",
                      height: "51px",
                      borderRadius: "10px",
                      border: "1px solid #d1d1d1",
                      marginTop: "10px",
                      marginBottom: "16px",
                      alignContent: "center",
                    }}
                  />
                </Grid>
                <Grid item md={8} sm={12}>
                  <Box mb={1}>
                    <label htmlFor="raisedAmount">
                      How much have you raised to date?
                    </label>
                  </Box>
                  <NumericFormat
                    type="text"
                    prefix={"$"}
                    suffix={" USD"}
                    decimalSeparator={"."}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    placeholder="$0.0"
                    value={0}
                    onValueChange={(value) =>
                      props.setFieldValue("raisedAmount", value.floatValue)
                    }
                    className="form-control"
                    style={{
                      textAlign: "left",
                      height: "51px",
                      borderRadius: "10px",
                      border: "1px solid #d1d1d1",
                      marginTop: "10px",
                      marginBottom: "16px",
                      alignContent: "center",
                    }}
                  />
                </Grid>
                <Grid item md={4} sm={12}>
                  <Box mb={1}>
                    <label htmlFor="date">Pick a date</label>
                  </Box>
                  <DatePicker
                    className="form-control customField"
                    selected={dates ? new Date(dates) : null}
                    onChange={handlerDate}
                    wrapperClassName="buildProfile__modal_atepicker"
                    dateFormat="dd/MM/yyyy"
                    isClearable={true}
                    placeholderText="Pick a date"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={6}
              >
                <Box textAlign={"center"}>
                  <button
                    type="submit"
                    className="button__secondary color__active"
                  >
                    Save round
                  </button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default RoundTrackInnerModal;

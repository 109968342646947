import styled from "styled-components";

export const ElementContainer = styled.div`
  position: absolute;
  top: 3%;
  right: 10%;
  display: flex;
  gap: 4px;
  align-items: baseline;
`

export const TopHeaderChart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

export const CategoryFeedback = styled.button<{$isSelected?: boolean}>`
  border: none;
  width: 72.269px;
  height: 67.496px;
  border: #04BF7B 1px solid;
  border-radius: 10px;
  background: none;
  background: ${p => p.$isSelected ? '#04BF7B' : 'none'};
`
import React, { FC } from "react";
import Modal from "react-modal";
import { Grid, Box } from "@mui/material";
import { LiaTimesSolid } from "react-icons/lia";
import StartupDescriptionInnerModal from "./Modals/StartupDescriptionInnerModal";
import BusinessTractionInnerModal from "./Modals/BusinessTractionInnerModal";
import PitchDeckInnerModal from "./Modals/PitchDeckInnerModal";
import RoundTrackInnerModal from "./Modals/RoundTrackInnerModal";
import VideoInnerModal from "./Modals/VideoInnerModal";

//TEST COMPONENT//

interface ModalChoiceProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  idButtonOpener: string;
  userId: string;
}

const ModalChoice: FC<ModalChoiceProps> = ({
  modalIsOpen,
  idButtonOpener,
  closeModal,
  userId,
}) => {
  return (
    <>
      {idButtonOpener === "stp" ? (
        <StartupDescriptionInnerModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          description={""}
          userId={userId}
        />
      ) : idButtonOpener === "biz" ? (
        <BusinessTractionInnerModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          userId={userId}
        />
      ) : idButtonOpener === "pit" ? (
        <PitchDeckInnerModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          userId={userId}
        />
      ) : idButtonOpener === "inv" ? (
        <RoundTrackInnerModal
          setLoading={() => {}}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          userId={userId}
        />
      ) : (
        <VideoInnerModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          userId={userId}
        />
      )}
      {/* </Grid> */}
    </>
  );
};

export default ModalChoice;

import React from 'react';
import TextField from "@mui/material/TextField";
import { useField } from 'formik';

const CustomTextField = ({
  name,
  isMultiline,
  placeholderMessage,
  rowsNumber,
  sx, // New prop for styles
}) => {
  const [field, meta] = useField(name);
  const configTextField = {
    ...field,
    fullWidth: true,
    multiline: isMultiline,
    placeholder: placeholderMessage,
    variant: 'outlined',
    sx,
    rows: rowsNumber, // Assign the style prop to the style property of configTextField
  };

  if(meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return (
    <TextField {...configTextField} />
  );
};

export default CustomTextField;

import React, { FC, useState } from "react";
import Modal from "react-modal";
import {
  Box,
  Grid,
  IconButton,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { LiaTimesSolid } from "react-icons/lia";
import Rating from "@mui/material/Rating";
import { AiOutlineTeam } from "react-icons/ai";
import { GoAlert } from "react-icons/go";
import { HiOutlineClipboardList, HiOutlineLightBulb } from "react-icons/hi";
import { PiStrategyLight } from "react-icons/pi";
import { BiPieChartAlt2 } from "react-icons/bi";
import { CategoryFeedback } from "../../../../../styled/Dashboard";
import { Review } from "../../../../../types/dashboard";
import { Paragraph } from "../../../../../styled/Paragraph";

type InputProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  reviews: Review[];
};

const OverallFeedback: FC<InputProps> = ({
  modalIsOpen,
  closeModal,
  reviews,
}) => {
  const [category, setCategory] = useState<string>("team");

  const getAverageRating = (): number => {
    return (
      reviews.reduce((sum, review) => sum + (review.overalRating ?? 0), 0) /
      reviews.length
    );
  };

  const getAverageCategoryValue = (): number => {
    if (!category) return 0;
    const sum = reviews.reduce((acc, review) => {
      switch (category) {
        case "team":
          return acc + (review.teamValue ?? 0);
        case "problem":
          return acc + (review.problemValue ?? 0);
        case "solution":
          return acc + (review.solutionValue ?? 0);
        case "gtm":
          return acc + (review.gtmstrategyValue ?? 0);
        case "market":
          return acc + (review.marketoppValue ?? 0);
        default:
          return acc;
      }
    }, 0);
    return sum / reviews.length;
  };

  // if (reviews.length === 0) {
  //   return (
  //     <Modal
  //       isOpen={modalIsOpen}
  //       onRequestClose={closeModal}
  //       className="Modal"
  //       overlayClassName="Overlay"
  //       contentLabel="Choice Modal"
  //       ariaHideApp={false}
  //     >
  //       <Grid
  //         container
  //         display="flex"
  //         direction="row"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         color={"white"}
  //         bgcolor={"#012626"}
  //       >
  //         <Box>
  //           <p className="buildProfile__modal_paragraph">
  //             Overall Feedback Summary
  //           </p>
  //         </Box>
  //         <Box>
  //           <button
  //             style={{
  //               backgroundColor: "transparent",
  //               border: "none",
  //               padding: "10px",
  //             }}
  //             onClick={closeModal}
  //           >
  //             <LiaTimesSolid size={32} color="#04BF7B" />
  //           </button>
  //         </Box>
  //       </Grid>
  //       <Grid container display="flex" p={4}>
  //         <Grid item xs={12}>
  //           <Paragraph fontSize={20} fontWeight="500">
  //             No feedback available yet. Check back later for updates.{" "}
  //           </Paragraph>
  //         </Grid>
  //       </Grid>
  //     </Modal>
  //   );
  // }
  if (reviews.length === 0) {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Choice Modal"
        ariaHideApp={false}
      >
        <Grid
          container
          display="flex"
          direction="column"
          sx={{ minHeight: "300px" }}
        >
          <Grid
            item
            container
            display="flex"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            color="white"
            bgcolor="#012626"
          >
            <Box>
              <p className="buildProfile__modal_paragraph">
                Overall Feedback Summary
              </p>
            </Box>
            <Box>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: "10px",
                }}
                onClick={closeModal}
              >
                <LiaTimesSolid size={32} color="#04BF7B" />
              </button>
            </Box>
          </Grid>
          <Grid
            item
            container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            p={4}
          >
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "80%",
                mb: 3,
              }}
            >
              <Paragraph fontSize={24} fontWeight="500" color="#04BF7B">
                No Feedback Available
              </Paragraph>
              <Paragraph fontSize={16} color="#666">
                Feedback hasn't been provided yet. Please check back later for
                updates.
              </Paragraph>
            </Box>
            <HiOutlineClipboardList size={80} color="#ccc" />
          </Grid>
        </Grid>
      </Modal>
    );
  }
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">
            Overall Feedback Summary
          </p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid container display="flex" p={4}>
        <Grid item xs={12}>
          <Paragraph fontSize={20} fontWeight="500">
            Overall Rating
          </Paragraph>
        </Grid>
        <Grid display={"flex"} justifyContent={"center"} item xs={12}>
          <Rating
            name="size-large"
            readOnly
            value={getAverageRating()}
            sx={{
              color: "#04BF7B",
              "& .MuiRating-iconFilled": {
                color: "#04BF7B",
              },
              fontSize: "4rem",
            }}
          />
        </Grid>
        <Grid display={"flex"} padding={"2rem 0rem 0rem 0rem"} item xs={12}>
          <Paragraph fontSize={16} fontWeight="500">
            Please select the tab below for detailed feedback
          </Paragraph>
        </Grid>
      </Grid>
      <Grid
        container
        display={"flex"}
        direction={"row"}
        justifyContent={"space-evenly"}
        className="overall__feedback_buttons"
        pt={1}
      >
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "team"}
            onClick={() => setCategory("team")}
          >
            <AiOutlineTeam
              size={40}
              color={category === "team" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>Team</p>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "problem"}
            onClick={() => setCategory("problem")}
          >
            <GoAlert
              size={40}
              color={category === "problem" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>Problem</p>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "solution"}
            onClick={() => setCategory("solution")}
          >
            <HiOutlineLightBulb
              size={40}
              color={category === "solution" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>Solution</p>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "gtm"}
            onClick={() => setCategory("gtm")}
          >
            <PiStrategyLight
              size={40}
              color={category === "gtm" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>GTM strategy</p>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          item
          xs={2}
        >
          <CategoryFeedback
            $isSelected={category === "market"}
            onClick={() => setCategory("market")}
          >
            <BiPieChartAlt2
              size={40}
              color={category === "market" ? "#fff" : "#9A9DA5"}
            />
          </CategoryFeedback>
          <p>Market Opp</p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sx={{ padding: "0px 64px 0px 64px", width: "100%" }}>
          {category && (
            <Slider
              sx={{
                height: "30%",
                color: "#DDD",
                "& .MuiSlider-rail": {
                  opacity: 0.5,
                },
                "& .MuiSlider-track": {
                  color: "#04BF7B",
                },
                "& .MuiSlider-thumb": {
                  color: "#04BF7B",
                },
              }}
              aria-label="Score"
              value={getAverageCategoryValue()}
              disabled={true}
              valueLabelDisplay="auto"
              step={1}
              marks={[
                { value: 1, label: "Limited" },
                { value: 5, label: "Exceptional" },
              ]}
              min={1}
              max={5}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default OverallFeedback;

import { Grid } from "@mui/material";
import investorImg from "../../../../../assets/investorimg.png";
import { Paragraph } from "../../../../../styled/Paragraph";
import { IoIosLink } from "react-icons/io";
import { ButtonPrimary } from "../../../../../styled/Button";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import { useContext, useState } from "react";
import ContactModal from "../ContactModal/ContactModal";
import { Investor } from "../../../../../types/fundraising";
import { addInvestorToFav, getInvestorEmail, removeInvestorFromFav } from "../../../../../api/fundraising";
import { AppContext } from "../../../../../context";

interface InvestorCardProps {
  isInvestor: boolean;
  checkSize: string;
  boxShadow?: boolean;
  investor: Investor;
  isFavList?: boolean;
  isSaved?: boolean;
  setIsSaved?: (arg0: boolean) => void;
  updateData?: (searchQuery: string) => Promise<void>;
}

const InvestorCard = ({
  isInvestor,
  checkSize,
  boxShadow = true,
  investor,
  isFavList = false,
  isSaved,
  setIsSaved,
  updateData,
}: InvestorCardProps) => {
  const openInNewTab = (url: string) => {
    window.open(url, "_blank");
  };
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const { startupProfile } = useContext(AppContext)
  const openModal = async () => {
    setModalIsOpen(true)
    const email = await getInvestorEmail(startupProfile?.id!, investor.id)
    setEmail(email)
  };
  const closeModal = () => setModalIsOpen(false);

  const addToFav = async () => {
    await addInvestorToFav(startupProfile?.id!, investor.id)
    if(!!setIsSaved) setIsSaved(!isSaved)
  }

  const removeFromFav = async () => {
    await removeInvestorFromFav(startupProfile?.id!, investor.id)
    if(!!setIsSaved) setIsSaved(!isSaved)
    if(!!updateData) updateData('')
  }

  return (
    <>
    <Grid
      container
      sx={{
        boxShadow: boxShadow ? " 0px 0px 20px rgba(48, 163, 221, 0.2)" : "",
        borderRadius: "8px",
        padding: isInvestor ? "24px" : "6px 24px 24px 24px",
        backgroundColor: "white",
      }}
      >
     {isInvestor && <Grid item lg={3}>
        <img src={investorImg} alt={`${investor.firm_name}-profile`} />
      </Grid>}
      <Grid item lg={!isInvestor ? 12 : 9}>
        <Paragraph
          fontSize={24}
          fontWeight="400"
          color="#1E1E1E"
          style={{ lineHeight: "1.1" }}
          >
          {isInvestor ? `${investor.first_name} ${investor.last_name}`: investor.firm_name}
        </Paragraph>
        <Paragraph
          fontSize={16}
          fontWeight="300"
          color="#1E1E1E"
          style={{ lineHeight: "1" }}
          >
          {isInvestor ? investor.firm_name : investor.fund_stage}
        </Paragraph>

        <div style={{ display: "flex", gap: 8, marginTop: 6 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              cursor: "pointer",
            }}
            onClick={() => openInNewTab(investor.website)}
            >
            <IoIosLink />
            <Paragraph fontSize={14} fontWeight="400" color="#1E1E1E">
              Website
            </Paragraph>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              cursor: "pointer",
            }}
            onClick={() => openInNewTab(investor.linkedin_link)}
            >
            <IoIosLink />
            <Paragraph fontSize={14} fontWeight="400" color="#1E1E1E">
              LinkedIn
            </Paragraph>
          </div>
        </div>

        {isInvestor && (
          <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
            marginTop: 10,
          }}
          >
            <Paragraph fontSize={14} fontWeight="400" color="#1E1E1E">
              Check size:
            </Paragraph>
            <Paragraph fontSize={16} fontWeight="400" color="#035928">
              {checkSize}
            </Paragraph>
          </div>
        )}

        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
            marginTop: 4,
            flexWrap: "wrap",
          }}
          >
          <Paragraph fontSize={16} fontWeight="400" color="#1E1E1E">
            Invests in: {investor.industry}
          </Paragraph>
        </div>

        <Paragraph
          fontSize={14}
          fontWeight="300"
          color="#1E1E1E"
          style={{ marginTop: 4 }}
          >
          {investor.description}
        </Paragraph>

        <div style={{ marginTop: 12, display: 'flex', gap: 24 }}>
          <ButtonPrimary $height={52} onClick={openModal}>
            Contact
          </ButtonPrimary>
          {!isInvestor &&
            <>
              {(isSaved || isFavList) ? (
                <ButtonPrimary $height={52} onClick={removeFromFav}>
                  Remove from List
                </ButtonPrimary>
              ) : (
                <ButtonPrimary $height={52} onClick={addToFav}>
                  Save to List
                </ButtonPrimary>
              )}
            </>
          }
        </div>
      </Grid>
    </Grid>
    <ContactModal
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
      investor={{...investor, email}}
    />
    </>
  );
};

export default InvestorCard;

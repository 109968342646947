import React from "react";
import { Link } from "react-router-dom";
import PumpJuiceLogo from "../../../assets/pompjuice_logo.svg";
import pomegranate from "../../../assets/pomegranate.svg";
import { Container, Grid } from "@mui/material";
// import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Grid container minHeight={"100vh"} className="login__box">
        <Grid
          container
          item
          xs={12}
          md={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className="login__brand"
        >
          <div className="login__semiCircle">
            <p></p>
          </div>

          <img src={PumpJuiceLogo} className="login_img" alt="PumpJuiceLogo" />
          <p>Please review our policies and guidelines</p>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Terms & Conditions
          </p>

          <img
            src={pomegranate}
            alt="Pomegranate"
            className="login_pomegranate"
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          md={8}
          display="flex"
          flexDirection="column"
          alignItems={{ md: "stretch", xs: "center" }}
          justifyContent="center"
          className="login__card"
        >
          <div style={{ padding: "1rem" }}>
            <div
              className="scrollable-content"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                paddingRight: "4vw",
                boxSizing: "content-box",
                height: "80vh",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <h1 style={{ display: "block" }}>Terms of Service</h1>
              <p>
                By using PomJuice, you agree to these terms. Your access to and
                use of PomJuice is subject to your acceptance and compliance
                with these Terms. PomJuice provides a platform for users to view
                startup information and connect founders with third-parties. We
                strive to maintain the security and integrity of our platform;
                however, we are not responsible for any third-party content or
                actions. By using PomJuice, you agree to use the platform
                responsibly and respect the rights of others.
              </p>
              <p>
                These Terms of Service (“Terms”) form a legally binding
                agreement between PomJuice, Inc., if you are based in the United
                States, its territories and possessions, Canada, or Mexico
                (“North America“) or PomJuice International Unlimited Company if
                you are based outside North America (each, for the purpose of
                these Terms, “PomJuice,” “us,” “we” or “our”) and the individual
                or entity agreeing to these terms (“Customer,” “you,” or
                “your”). These Terms govern your access to and use of PomJuice's
                services. By accessing or using PomJuice's services, you accept
                these Terms, acknowledge that you have read and understand them,
                and agree to be bound by them. Please note that these Terms
                supersede any previous agreements or terms.
              </p>
              <p>
                If you are agreeing to these Terms on behalf of an organization,
                you represent and warrant that you have the authority to bind
                that organization to these Terms. If you do not have such
                authority or do not agree with these Terms, you must not sign up
                for PomJuice's services.
              </p>

              <p>
                <strong>Services:</strong>
              </p>
              <p>
                <strong>Provision:</strong> These Terms govern access to, and
                use of, the services, and any associated software, ordered by
                Customer through an Order Form. Customer may access and use the
                services in accordance with the Terms.
              </p>
              <p>
                <strong>Modifications:</strong> PomJuice may update the services
                from time to time. If PomJuice changes the services in a manner
                that materially reduces their functionality, PomJuice will
                notify Customer at the email address associated with the
                account, and Customer may provide notice within thirty days of
                the change to terminate the Terms. This termination right will
                not apply to updates made to features provided on a beta or
                evaluation basis.
              </p>
              <p>
                <strong>Software:</strong>
              </p>
              <p>
                <strong>Generally:</strong> Some of the services may allow
                Customer and End Users to download software that may update
                automatically. PomJuice hereby grants to Customer during the
                Term a limited non-exclusive license to use the software solely
                in connection with the services and in accordance with the
                Terms. This license is non-transferable (subject to Section
                16.2), irrevocable (except as set forth in Section 8),
                non-sublicensable, and will be fully paid up upon Customer’s
                payment of the Fees.
              </p>
              <p>
                <strong>Open Source:</strong> If any component of the software
                is offered under an open-source license, PomJuice will make the
                license available to Customer and to the extent the provisions
                of that license grant Customer additional rights, those
                provisions will expressly override some provisions of the Terms
                solely with respect to that component of the software.
              </p>
              <p>
                <strong>Service-Specific Terms:</strong> Certain services, or
                portions thereof, may be subject to additional terms, including
                third-party terms and conditions, that are specific to the
                particular services and are set forth in the Service-Specific
                Terms. By accessing or using services covered by any
                Service-Specific Terms, you agree to the applicable
                Service-Specific Terms. If there is a conflict between these
                Terms and the Service-Specific Terms, the Service-Specific Terms
                will control with respect to the applicable services or portions
                thereof. Your use of the services may also be subject to
                additional policies, guidelines, or rules we post on the
                services or make available to you.
              </p>
              <p>
                <strong>Reference Materials:</strong> PomJuice may make certain
                reference materials, including without limitation an NDA,
                available through the services. Such materials are for
                informational purposes only, and PomJuice makes no
                representations or warranties as to their validity, reliability,
                or sufficiency. The materials, including the NDA, are not
                intended to (a) constitute legal advice or (b) create an
                attorney-client relationship. Customer acknowledges and agrees
                that each situation is highly fact-specific and requires
                knowledge of both state and federal laws. Therefore, any party
                should seek legal advice from a licensed attorney in the
                relevant jurisdictions.
              </p>
              <p>
                <strong>PomJuice Rights:</strong> PomJuice reserves the right to
                access Customer’s account as necessary to provide the services.
                Furthermore, Customer agrees that PomJuice may, but is not
                required to: (a) monitor the services or Customer data for
                violations of these Terms and for compliance with our policies;
                (b) refuse, restrict access to or the availability of, or remove
                or disable access to the materials or Customer data or any
                portion thereof, without prior notice to Customer, at any time
                for any reason (including upon receipt of claims or allegations
                from third parties or authorities relating to Customer data), or
                for no reason at all; (c) report to law enforcement authorities
                and/or take legal action against anyone who violates these
                Terms; or (d) manage the services in a manner designed to
                protect our and third parties’ rights and property or to
                facilitate the proper functioning of the service.
              </p>
              <p>
                <strong>Third-Party Services and Materials:</strong> The
                services may contain links to third-party services and
                third-party materials. PomJuice does not own or operate these
                third-party services, and we do not endorse any third-party
                services or third-party materials. If Customer accesses or uses
                any third-party services or third-party materials: (a) Customer
                is solely responsible for this access and use; (b) PomJuice is
                not responsible for any act or omission of the third party or
                the availability, accuracy, the related content, products, or
                services of third-party services or third-party materials; and
                (c) these Terms do not apply to the third-party services. Before
                accessing or using a third-party service, you should review the
                third-party service’s terms and conditions, privacy policy, and
                all of the third-party service’s other documents and inform
                yourself of the terms, policies, and practices of the
                third-party service.
              </p>

              <p>
                <strong>Customer Obligations:</strong>
              </p>
              <p>
                <strong>Registration:</strong> In order to use the services,
                customers and End Users must first register with us through our
                online registration process. Customer agrees to provide
                accurate, current, and complete information during the
                registration process and to update such information to keep it
                accurate, current, and complete. PomJuice reserves the right to
                suspend or terminate your account if any information provided
                during the registration process or thereafter proves to be
                inaccurate, not current, or incomplete.
              </p>
              <p>
                <strong>Data Protection and Privacy:</strong> PomJuice respects
                your privacy and is committed to protecting it. Our Privacy
                Policy explains how we collect, use, disclose, and protect
                information that applies to our service and your choices about
                the collection and use of your information. Please review our
                Privacy Policy before accessing or using our services.
              </p>
              <p>
                <strong>End User Conduct:</strong> Customer is solely
                responsible for its conduct and the conduct of its End Users and
                for compliance with the Acceptable Use Policy. Customer will
                ensure that all End Users comply with Customer’s obligations
                under the Terms and that the terms of Customer’s agreement with
                each End User are consistent with these Terms. If PomJuice has
                reason to believe that there is likely to be a breach of
                security or misuse of the services, we may require Customer to
                change its password or suspend your account.
              </p>
              <p>
                <strong>PomJuice's Support Services:</strong> PomJuice will
                provide Customer with support for the services in accordance
                with the Support Services Guidelines. PomJuice may amend the
                Support Services Guidelines from time to time in its sole
                discretion. PomJuice may elect to discontinue support for older
                versions of the services at any time, in its sole discretion,
                provided that we continue to support the immediately preceding
                Major Release of the services for an additional twelve months
                after the new Major Release.
              </p>
              <p>
                <strong>Billing and Payment:</strong>
              </p>
              <p>
                <strong>Fees:</strong> Customer will pay PomJuice the applicable
                fees set forth in the Order Form or other agreement between the
                parties (the “Fees”). Except as otherwise specified in the Order
                Form or these Terms, (i) Fees are quoted and payable in United
                States dollars (ii) Fees are based on services purchased and not
                actual usage, (iii) payment obligations are non-cancelable and
                Fees paid are non-refundable, and (iv) the number of user
                subscriptions purchased cannot be decreased during the relevant
                subscription term stated on the Order Form. User subscription
                fees are based on monthly or annual periods that begin on the
                subscription start date and each monthly or annual anniversary
                thereof; therefore, fees for user subscriptions added in the
                middle of a monthly or annual period will be charged for that
                full monthly or annual period and the monthly or annual periods
                remaining in the subscription term. If Customer’s use of the
                services exceeds the service capacity or otherwise requires the
                payment of additional fees (per the terms of the Order Form),
                Customer shall be billed for such usage and Customer agrees to
                pay the additional fees in the manner provided herein.
              </p>
              <p>
                <strong>Overdue Charges:</strong> If any invoiced amount is not
                received by PomJuice by the due date, then without limiting our
                rights or remedies, (a) those charges may accrue late interest
                at the rate of 1.5% of the outstanding balance per month, or the
                maximum rate permitted by law, whichever is lower, and/or (b)
                PomJuice may condition future subscription renewals and Order
                Forms on payment terms shorter than those specified in Section
                5.1.
              </p>
              <p>
                <strong>Taxes:</strong> All Fees are exclusive of taxes, and
                Customer shall be responsible for all applicable taxes, levies,
                or duties, excluding only taxes based solely on PomJuice's
                income. If PomJuice has the legal obligation to pay or collect
                taxes for which Customer is responsible, PomJuice will invoice
                Customer and Customer will pay that amount unless Customer
                provides PomJuice with a valid tax exemption certificate
                authorized by the appropriate taxing authority.
              </p>

              <p>
                <strong>Term and Termination:</strong>
              </p>
              <p>
                <strong>Term:</strong> These Terms commence on the Effective
                Date and continue until all subscriptions granted in accordance
                with these Terms have expired or been terminated. Termination of
                these Terms will terminate all subscriptions granted hereunder.
              </p>
              <p>
                <strong>Termination:</strong> A party may terminate these Terms
                for cause (i) upon 30 days written notice to the other party of
                a material breach if such breach remains uncured at the
                expiration of such period, or (ii) if the other party becomes
                the subject of a petition in bankruptcy or any other proceeding
                relating to insolvency, receivership, liquidation, or assignment
                for the benefit of creditors.
              </p>
              <p>
                <strong>Effect of Termination:</strong> Upon any expiration or
                termination of these Terms, Customer’s rights under these Terms
                will terminate, and Customer will cease all use of the services
                and PomJuice Confidential Information. Termination of these
                Terms will not limit either party from pursuing other remedies
                available to it, including injunctive relief, nor will
                termination relieve Customer of its obligation to pay all fees
                that have accrued or that Customer has committed to paying under
                these Terms.
              </p>
              <p>
                <strong>Surviving Provisions:</strong> The sections titled “Fees
                and Payment,” “Confidentiality,” “Term and Termination,”
                “Indemnification,” “Limitation of Liability,” “Miscellaneous,”
                and any other provision of these Terms which by its nature
                should survive termination or expiration will survive
                termination or expiration of these Terms.
              </p>

              <p>
                <strong>Confidentiality:</strong>
              </p>
              <p>
                <strong>Definition:</strong> “Confidential Information” means
                all information disclosed by a party (“Disclosing Party”) to the
                other party (“Receiving Party”), whether orally or in writing,
                that is designated as confidential or that reasonably should be
                understood to be confidential given the nature of the
                information and the circumstances of disclosure. Confidential
                Information includes the terms and conditions of these Terms, as
                well as business and marketing plans, technology and technical
                information, product plans and designs, and business processes
                disclosed by PomJuice. Confidential Information does not include
                any information that (i) is or becomes generally known to the
                public without breach of any obligation owed to the Disclosing
                Party, (ii) was known to the Receiving Party prior to its
                disclosure by the Disclosing Party without breach of any
                obligation owed to the Disclosing Party, (iii) is received from
                a third party without breach of any obligation owed to the
                Disclosing Party, or (iv) was independently developed by the
                Receiving Party.
              </p>
              <p>
                <strong>Protection of Confidential Information:</strong> The
                Receiving Party will use the same degree of care that it uses to
                protect the confidentiality of its own confidential information
                of like kind (but in no event less than reasonable care) (i) not
                to use any Confidential Information of the Disclosing Party for
                any purpose outside the scope of these Terms and (ii) except as
                otherwise authorized by the Disclosing Party in writing, to
                limit access to Confidential Information of the Disclosing Party
                to those of its and its affiliates’ employees, contractors, and
                agents who need that access for purposes consistent with these
                Terms and who have signed confidentiality agreements with the
                Receiving Party containing protections no less stringent than
                those herein.
              </p>
              <p>
                <strong>Compelled Disclosure:</strong> The Receiving Party may
                disclose Confidential Information of the Disclosing Party to the
                extent compelled by law to do so, provided the Receiving Party
                gives the Disclosing Party prior notice of the compelled
                disclosure (to the extent legally permitted) and reasonable
                assistance, at the Disclosing Party’s cost, if the Disclosing
                Party wishes to contest the disclosure. If the Receiving Party
                is compelled by law to disclose the Disclosing Party’s
                Confidential Information as part of a civil proceeding to which
                the Disclosing Party is a party, and the Disclosing Party is not
                contesting the disclosure, the Disclosing Party will reimburse
                the Receiving Party for its reasonable cost of compiling and
                providing secure access to that Confidential Information.
              </p>

              <p>
                <strong>Warranty Disclaimer:</strong> The services and the
                software are provided “as is,” excluding any warranty,
                condition, or representation, including without limitation the
                implied warranties of merchantability, fitness for a particular
                purpose, or non-infringement, or any warranty arising from a
                course of dealing, performance, or trade usage. PomJuice does
                not warrant that the services will meet Customer’s requirements
                or that the operation of the services will be uninterrupted or
                error-free. PomJuice does not warrant that the software will be
                error-free or that errors will be corrected.
              </p>

              <p>
                <strong>Indemnification:</strong> Customer will defend PomJuice
                against any claim, demand, suit, or proceeding made or brought
                against PomJuice by a third party alleging that Customer Data or
                Customer’s use of the services in breach of these Terms
                infringes or misappropriates the intellectual property rights of
                a third party or violates applicable law (a “Claim Against
                PomJuice”), and will indemnify PomJuice from any damages,
                attorney fees, and costs finally awarded against PomJuice as a
                result of, or for any amounts paid by PomJuice under a
                settlement approved by Customer in writing of, a Claim Against
                PomJuice; provided that PomJuice (a) promptly gives Customer
                written notice of the Claim Against PomJuice; (b) gives Customer
                sole control of the defense and settlement of the Claim Against
                PomJuice (provided that Customer may not settle any Claim
                Against PomJuice unless the settlement unconditionally releases
                PomJuice of all liability); and (c) provides to Customer all
                reasonable assistance, at Customer’s expense.
              </p>

              <p>
                <strong>Limitation of Liability:</strong> In no event will
                PomJuice’s aggregate liability arising out of or related to
                these Terms, whether in contract, tort, or under any other
                theory of liability, exceed the total amount paid by Customer
                hereunder for the services giving rise to the liability in the
                12 months preceding the last event giving rise to liability. The
                foregoing will not limit Customer’s payment obligations under
                Section 5 (Fees and Payment of Fees).
              </p>

              <p>
                <strong>Miscellaneous:</strong>
              </p>
              <p>
                <strong>Entire Agreement:</strong> These Terms, together with
                the Order Form and any other documents incorporated herein by
                reference, constitute the entire agreement between the parties
                and supersedes all prior and contemporaneous agreements,
                proposals or representations, written or oral, concerning its
                subject matter.
              </p>
              <p>
                <strong>Governing Law:</strong> These Terms are governed by and
                construed in accordance with the laws of the State of California
                without regard to its conflict of laws provisions. The United
                Nations Convention on Contracts for the International Sale of
                Goods will not apply to these Terms. Each party hereby consents
                to the exclusive jurisdiction and venue of the state and federal
                courts located in San Francisco County, California in connection
                with any action arising out of or in connection with these
                Terms.
              </p>
              <p>
                <strong>Force Majeure:</strong> Neither party will be liable for
                any failure or delay in its performance under these Terms due to
                any cause beyond its reasonable control, including acts of war,
                acts of God, earthquake, flood, embargo, riot, sabotage, labor
                shortage or dispute, governmental act, or failure of the
                internet, provided that the delayed party: (a) gives the other
                party prompt notice of such cause, and (b) uses its reasonable
                commercial efforts to promptly correct such failure or delay in
                performance.
              </p>
              <p>
                <strong>Assignment:</strong> Neither party may assign any of its
                rights or obligations hereunder, whether by operation of law or
                otherwise, without the prior written consent of the other party
                (not to be unreasonably withheld). Notwithstanding the
                foregoing, either party may assign these Terms in their
                entirety, without consent of the other party, to its affiliate
                or in connection with a merger, acquisition, corporate
                reorganization, or sale of all or substantially all of its
                assets.
              </p>
              <p>
                <strong>Waiver:</strong> No failure or delay by either party in
                exercising any right under these Terms will constitute a waiver
                of that right. No waiver under these Terms will be effective
                unless made in writing and signed by an authorized
                representative of the party being deemed to have granted the
                waiver.
              </p>
              <p>
                <strong>Severability:</strong> If any provision of these Terms
                is held by a court of competent jurisdiction to be contrary to
                law, the provision will be deemed null and void, and the
                remaining provisions of these Terms will remain in effect.
              </p>
              <p>
                <strong>Notice:</strong> Any notice or communication under these
                Terms must be in writing and must be delivered by email to the
                other party at the email address provided in the Order Form (or
                as subsequently updated by the party entitled to receive
                notice). Notice will be effective upon receipt.
              </p>
              <p>
                <strong>Counterparts:</strong> These Terms may be executed in
                counterparts, each of which will be deemed an original and all
                of which together will constitute one and the same instrument.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TermsAndConditions;

import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { FaCrown } from "react-icons/fa";
import { AppContext } from "../../../context";
import {
  createCheckoutSession,
  createCustomerPortalSession,
} from "../../../helpers/Api";
import Swal from "sweetalert2";
import {
  MainCardContainer,
  MainCardContent,
  MainCardTitle,
} from "../../../styled/MainCard";
import { Paragraph } from "../../../styled/Paragraph";
import { ButtonPrimary } from "../../../styled/Button";

const SubscriptionBilling = () => {
  const { startupProfile, startupPlan, setStartupPlan } =
    useContext(AppContext);

  useEffect(() => {
    if (startupProfile?.plan) {
      setStartupPlan(startupProfile.plan);
      localStorage.setItem("startupPlan", startupProfile.plan);
    }
  }, [startupProfile, setStartupPlan]);

  const userPlan =
    startupPlan || localStorage.getItem("startupPlan") || "basic";

  const handleUpgrade = async () => {
    try {
      if (!startupProfile?.id) {
        throw new Error("Startup profile ID is missing");
      }
      const response = await createCheckoutSession(startupProfile.id);
      window.open(response.url, "_blank");
    } catch (error) {
      Swal.fire(
        "Error",
        "Unable to process upgrade request. Please try again later.",
        "error"
      );
    }
  };

  const handleManageSubscription = async () => {
    try {
      if (!startupProfile?.id) {
        throw new Error("Startup profile ID is missing");
      }
      const response = await createCustomerPortalSession(startupProfile.id);
      window.open(response.url, "_blank");
    } catch (error) {
      console.error("Error creating customer portal session:", error);
      Swal.fire(
        "Error",
        "Unable to access subscription management. Please try again later.",
        "error"
      );
    }
  };

  const getPlanDetails = (plan: string) => {
    switch (plan) {
      case "trial":
        return {
          name: "Trial",
          duration: "14 days",
          features: ["Access to all Pro features", "Limited time offer"],
        };
      case "basic":
        return {
          name: "Basic",
          price: "Free",
          features: [
            "Core features",
            "Limited startup profile",
            "Basic analytics",
          ],
        };
      case "pro":
        return {
          name: "Pro",
          price: "$99.99/year",
          features: [
            "All features",
            "Full startup profile",
            "Advanced analytics",
            "Priority support",
          ],
        };
      default:
        return { name: "Unknown", features: [] };
    }
  };

  const planDetails = getPlanDetails(userPlan);

  return (
    <MainCardContainer>
      <MainCardContent>
        <Box mb={2}>
          <Paragraph fontSize={18} fontWeight="600" color="#1E1E1E">
            Current Plan: {planDetails.name}
          </Paragraph>
          <Paragraph fontSize={16} fontWeight="400" color="#1E1E1E">
            Price:{" "}
            {planDetails.price ||
              (userPlan === "trial" ? "Free during trial" : "N/A")}
          </Paragraph>
          {userPlan === "trial" && (
            <Paragraph fontSize={16} fontWeight="400" color="#1E1E1E">
              Duration: {planDetails.duration}
            </Paragraph>
          )}
        </Box>
        <Box mb={2}>
          <Paragraph fontSize={18} fontWeight="600" color="#1E1E1E">
            Features:
          </Paragraph>
          <ul>
            {planDetails.features.map((feature, index) => (
              <li key={index}>
                <Paragraph fontSize={16} fontWeight="400" color="#1E1E1E">
                  {feature}
                </Paragraph>
              </li>
            ))}
          </ul>
        </Box>
        <Box>
          {userPlan === "basic" && (
            <ButtonPrimary onClick={handleUpgrade}>
              Upgrade to Pro Now!
            </ButtonPrimary>
          )}
          {userPlan === "pro" && (
            <ButtonPrimary onClick={handleManageSubscription}>
              Manage Subscription
            </ButtonPrimary>
          )}
          {userPlan === "trial" && (
            <ButtonPrimary onClick={handleManageSubscription}>
              Finalize Pro Subscription!
            </ButtonPrimary>
          )}
        </Box>
      </MainCardContent>
    </MainCardContainer>
  );
};

export default SubscriptionBilling;

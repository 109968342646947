import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../components/MainLayout";
import SayHello from "../../../../assets/say_hi.svg";
import { Box } from "@mui/system";
import { AppContext } from "../../../../context";


const StartupProfile = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AppContext);

  return (
    <>
      <Grid
      container
      className="startupProfile__box"
      direction="column"
      justifyContent="space-evenly"
      alignItems="flex-start"
      >
        <Grid md={12} item>
          <p>Let’s begin your startup journey</p>
          <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          >
          <p
            style={{
              fontWeight: "400",
              fontSize: "40px",
            }}
          >
            Hey {userData}, get started now!
          </p>
          <img src={SayHello}
            style={{
            marginBottom: '20px',
            marginLeft: '20px',
            }}

            alt="Hand hello" />
          </Box>
          <Box
          pt={'3rem'}
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          >
            <button onClick={() => navigate("/startup-profile/tell-us") } className="button__add_startup">
              <p>+ Add Startup</p>
            </button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StartupProfile;

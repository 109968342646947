import React, { FC } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { LiaTimesSolid } from "react-icons/lia";
import Modal from "react-modal";
import { Paragraph } from "../../../../../styled/Paragraph";
import { ButtonPrimary } from "../../../../../styled/Button";
import { formatDateToMonthYear } from "../../../../../helpers/Utils";

type ConnectModalProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  startupData: any;
};

const ConnectModal: FC<ConnectModalProps> = ({
  modalIsOpen,
  closeModal,
  startupData,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      maxWidth: "600px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "15px",
      border: "none",
      boxShadow: "0px 11px 30px 12px rgba(0, 0, 0, 0.04)",
      padding: "0",
    },
    overlay: { zIndex: 1000 },
  };

  const mainFounder = startupData?.founders?.find((f: any) => f.role === 'Main Founder') ?? startupData.founders[0]

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Feedback Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <Typography variant="h6" component="div" color="inherit" sx={{ fontSize: 40, color: '#04BF7B' }}>
            Founder Profile
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={closeModal}
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </Button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
        alignContent='center'
        sx={{
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Grid container spacing={2} alignItems="center" sx={{ placeContent: 'center', marginBottom: 2}}>
          <Avatar sx={{ width: 86, height: 86 }} alt="Remy Sharp" src={startupData.mainFunder.pictureUrl}/>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Paragraph fontSize={35} fontWeight="700">{startupData.mainFunder.firstName} {startupData.mainFunder.lastName}</Paragraph>
            <Paragraph fontSize={16} fontWeight="500" color="#9A9DA5">{mainFounder.role} | {startupData.name}</Paragraph>
          </Grid>
          <Grid item xs={4} sx={{ wordWrap: 'break-word'}}>
            <Paragraph fontSize={16} fontWeight="400" color="#035928">Contact Info</Paragraph>
            <Paragraph fontSize={16} fontWeight="400">{mainFounder.email} {mainFounder.phoneNumber}</Paragraph>
          </Grid>
        </Grid>
        <Grid container mt={3} direction={'column'}>
          <Paragraph fontSize={24} fontWeight="300">Bio</Paragraph>
          <Paragraph fontSize={16} fontWeight="400">{mainFounder.bio ?? '-'}</Paragraph>
        </Grid>
        {startupData?.founders[0]?.experiences?.length > 0 && <Grid container mt={3} direction={'column'}>
          <Paragraph fontSize={24} fontWeight="300">Experience</Paragraph>
          {startupData?.founders[0]?.experiences?.map((exp: any, i: number) => (
            <Paragraph key={i} fontSize={16} fontWeight="400">{exp.title}, {exp.company} ({formatDateToMonthYear(exp.startDate)} - {formatDateToMonthYear(exp.endDate)})</Paragraph>
          ))}
        </Grid>}
        <Grid container mt={3} flexDirection={'column'}>
          <Paragraph fontSize={24} fontWeight="300">Relevant Links</Paragraph>
          <Grid item>
            <Link href={startupData.websiteUrl} target="_blank" rel="noopener" variant="body2" mt={1}>
              {startupData.websiteUrl}
            </Link>
          </Grid>
        </Grid>
        <Grid container mt={3} spacing={2}>
          {mainFounder.linkedinUrl && <Grid item>
            <ButtonPrimary $height={37} onClick={() => window.open(mainFounder.linkedinUrl)}>
              LinkedIn
            </ButtonPrimary>
          </Grid>}
          {mainFounder.calendlyUrl && <Grid item>
            <ButtonPrimary $height={37} onClick={() => window.open(mainFounder.calendlyUrl)}>
              Calendar
            </ButtonPrimary>
          </Grid>}
         {mainFounder.xUrl && <Grid item>
            <ButtonPrimary $height={37} onClick={() => window.open(mainFounder.xUrl)}>
              X
            </ButtonPrimary>
          </Grid>}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConnectModal;

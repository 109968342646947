/* eslint-disable indent */
import React, {
  useReducer,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { getAllStartupProfile } from "../helpers/Api";
import { StartupResponse } from "../types/startup";
import { InitialDashBoardData } from "../types/dashboard";
import { StartupPlan } from "../types/startup";

interface State {
  authed: boolean;
  signupStep: number;
}

interface Action {
  type: string;
  payload?: any;
}

interface UserProp {
  name: string;
}

interface Loader {
  isLoading: boolean;
  loader: string[];
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "OPEN_AUTH":
      return { ...state, authed: true };
    case "CLOSE_AUTH":
      return { ...state, authed: false };
    case "SET_SIGNUP_STEP":
      return { ...state, signupStep: action.payload };
    default:
      return state;
  }
};

const initialState: State = {
  authed: !!localStorage.getItem("token"),
  signupStep: 0, // Initial signup step
};

// const AppContext = createContext<{
//   state: State;
//   dispatch: React.Dispatch<Action>;
// }>({
//   state: initialState,
//   dispatch: () => {},
// });
const AppContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
  isChangeName: boolean;
  setIsChangeName: React.Dispatch<React.SetStateAction<boolean>>;
  initialDashboard: InitialDashBoardData | null | undefined;
  setInitialDashboard: React.Dispatch<
    React.SetStateAction<InitialDashBoardData | undefined>
  >;
  getStartupProfile: () => void;
  startupProfile: StartupResponse | null;
  loading: Loader;
  isOnboarding: boolean;
  setIsOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
  userData: string;
  setUserData: React.Dispatch<React.SetStateAction<string>>;
  isSharingDisabled: () => boolean;
  startupPublicLink?: string;
  setStartupPublicLink: React.Dispatch<React.SetStateAction<string>>;
  startupPlan: StartupPlan | null;
  setStartupPlan: React.Dispatch<React.SetStateAction<StartupPlan | null>>;
  isSharingEligible: boolean;
  setIsSharingEligible: React.Dispatch<React.SetStateAction<boolean>>;
  checkSharingEligibility: (profile: StartupResponse) => void;
}>({
  state: initialState,
  dispatch: () => {},
  isChangeName: false,
  isOnboarding: true,
  setIsChangeName: () => {},
  initialDashboard: undefined,
  setInitialDashboard: () => {},
  getStartupProfile: () => {},
  startupProfile: null,
  loading: { isLoading: false, loader: [] },
  setIsOnboarding: () => {},
  userData: "",
  setUserData: () => {},
  isSharingDisabled: () => true,
  startupPublicLink: "",
  setStartupPublicLink: () => {},
  startupPlan: null,
  setStartupPlan: () => {},
  isSharingEligible: false,
  setIsSharingEligible: () => {},
  checkSharingEligibility: () => {},
});

function AppProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isChangeName, setIsChangeName] = useState(false);
  const [userData, setUserData] = useState<string>("");
  const [initialDashboard, setInitialDashboard] =
    useState<InitialDashBoardData>();
  const [startupProfile, setStartupProfile] = useState<StartupResponse | null>(
    null
  );
  const [startupPlan, setStartupPlan] = useState<StartupPlan | null>(() => {
    const storedPlan = localStorage.getItem("startupPlan");
    return storedPlan ? (storedPlan as StartupPlan) : null;
  });

  const [loading, setLoading] = useState<Loader>({
    isLoading: false,
    loader: [],
  });
  const isOnboardingStorage = localStorage.getItem("isOnboarding") === "true";
  const [isOnboarding, setIsOnboarding] = useState(isOnboardingStorage);
  const [startupPublicLink, setStartupPublicLink] = useState<string>("");
  const isDisabled = useCallback(() => {
    if (!startupProfile) {
      return true;
    }
    return (
      !startupProfile.description ||
      !startupProfile.founders ||
      !startupProfile.businessTractions ||
      !startupProfile.pitchDeckUrl
    );
  }, [startupProfile]);

  const [isSharingEligible, setIsSharingEligible] = useState<boolean>(() => {
    return localStorage.getItem("isSharingEligible") === "true";
  });

  const checkSharingEligibility = useCallback((profile: StartupResponse) => {
    const isEligible = !!(
      profile.description &&
      profile.founders &&
      profile.businessTractions &&
      profile.pitchDeckUrl
    );
    setIsSharingEligible(isEligible);
    localStorage.setItem("isSharingEligible", isEligible.toString());
  }, []);

  const getStartupProfile = useCallback(async () => {
    setLoading({ isLoading: true, loader: ["startupProfile"] });

    try {
      const response = await getAllStartupProfile();
      setStartupProfile(response);
      checkSharingEligibility(response);
      if (response.plan) {
        setStartupPlan(response.plan);
        localStorage.setItem("startupPlan", response.plan);
      }
    } catch (error) {
      console.error("Error fetching startup profile:", error);
    } finally {
      setLoading({ isLoading: false, loader: [] });
    }
  }, [checkSharingEligibility]);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      isChangeName,
      setIsChangeName,
      initialDashboard,
      setInitialDashboard,
      getStartupProfile,
      startupProfile,
      loading,
      isOnboarding,
      setIsOnboarding,
      userData,
      setUserData,
      isSharingDisabled: isDisabled,
      startupPublicLink,
      setStartupPublicLink,
      startupPlan,
      setStartupPlan,
      isSharingEligible,
      setIsSharingEligible,
      checkSharingEligibility,
    }),
    [
      state,
      isChangeName,
      initialDashboard,
      startupProfile,
      loading,
      isOnboarding,
      userData,
      startupPublicLink,
      startupPlan,
      isDisabled,
      getStartupProfile,
      isSharingEligible,
      checkSharingEligibility,
    ]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export { AppContext, AppProvider };

import React, { FC, useState, useEffect } from "react";
import { Grid, Box, TextField, Autocomplete } from "@mui/material";
import Modal from "react-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import Select from "react-select";

//Selectors Collection
import { IndustryCollection } from "../../../../../../helpers/IndustriesSelector";
import { EmployeeCollection } from "../../../../../../helpers/EmployeeSelector";
import { StageCollection } from "../../../../../../helpers/StageSelector";
//API
import { editStartupProfile, getIndustries, getTechSector } from "../../../../../../helpers/Api";
import { IndustryOptionItem } from "../../../../../../types/startup";
import useDebounce from "../../../../../../hooks/useDebounce";
import { getLocations } from "../../../../../../api/gralInformation";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface StartupInputProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  name: string | null | undefined;
  location: string | null | undefined;
  industrySelected: IndustryOptionItem | null | undefined;
  techSectorSelected: TechSectorSelector | null | undefined;
  employeeCountSelected: string | null | undefined;
  stageSelected: string | null | undefined;
  websiteUrl: string | null | undefined;
  userId: number | string | null | undefined;
  foundationDate: string | null | undefined;
}

interface StartupFormProfileProps {
  name: string;
  description: string;
  location: string;
  industry: { id: string; name: string }[];
  techSector: { id: string; name: string }[];
  employeeCount: { label: string; value: string }[];
  stage: { label: string; value: string }[];
  websiteUrl: string;
  foundationDate: string | null;
}

export type IndustriesSelector = {
  id: string;
  name: string;
};

export type TechSectorSelector = {
  id: string;
  name: string;
};

type EmployeeSelector = {
  value: string;
  label: string;
};

type StageSelector = {
  value: string;
  label: string;
};

const CompanyDetails: FC<StartupInputProps> = ({
  modalIsOpen,
  closeModal,
  name,
  location,
  industrySelected,
  techSectorSelected,
  employeeCountSelected,
  stageSelected,
  websiteUrl,
  userId,
  foundationDate
}) => {
  const startupId = userId?.toString() || "";
  const [industries, setIndustries] = useState<any[]>([]);
  const [techSectors, setTechSectors] = useState<any[]>([]);
  const [industry, setIndustry] = useState<IndustriesSelector>(industrySelected ?? {id: '', name: ''});
  const [techSector, setTechSector] = useState<TechSectorSelector>(techSectorSelected ?? {id: '', name: ''});
  const [employees, setEmployees] = useState<EmployeeSelector>({
    label: `${employeeCountSelected}`,
    value: `${employeeCountSelected}`,
  });
  const [stage, setStage] = useState<StageSelector>({
    label: `${stageSelected}`,
    value: `${stageSelected}`,
  });
  const [countries, setCountries] = useState<string[]>([]);
  const [countrySelected, setCountrySelected] = useState<string>(location ?? "");

  const initialValues: StartupFormProfileProps = {
    name: name || "",
    description: "",
    location: location || "",
    industry: [{ id: `${industrySelected}`, name: `${industrySelected}` }],
    techSector: [{ id: `${techSectorSelected}`, name: `${techSectorSelected}` }],
    employeeCount: [
      { label: `${employeeCountSelected}`, value: `${employeeCountSelected}` },
    ],
    stage: [{ label: `${stageSelected}`, value: `${stageSelected}` }],
    websiteUrl: websiteUrl || "",
    foundationDate: foundationDate || null
  };

  const handleCreateStartup = async (values: StartupFormProfileProps) => {
    try {
      const response = await editStartupProfile(
        {
          name: values.name,
          description: null,
          location: countrySelected,
          industry: industry.id,
          techSector: techSector.id,
          employeeCount: employees.value,
          stage: stage.value,
          websiteUrl: values.websiteUrl,
          foundationDate: values.foundationDate
          ? moment(values.foundationDate).format("YYYY-MM-DD")
          : null,
        },
        startupId
      );
      if (response) {
        Swal.fire({
          title: "Success",
          text: "Amazing you've created startup profile",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        closeModal();
      }
    } catch (error) {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
          `,
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  const handleIndustry = (option: IndustriesSelector) => {
    return setIndustry(option);
  };

  const handleTechSector = (option: TechSectorSelector) => {
    return setTechSector(option);
  };

  const handleInputEmployees = (option: EmployeeSelector) => {
    return setEmployees(option);
  };

  const handleInputStage = (option: StageSelector) => {
    return setStage(option);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [industriesData, setTechSectorsData] = await Promise.all([
          getIndustries(),
          getTechSector()
        ])
        setIndustries(industriesData)
        setTechSectors(setTechSectorsData)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
  
    fetchData()
  }, [])

  const handleSearchLocation = async (value: string) => {
    const apiResponse = await getLocations(value);

    if (apiResponse) {
      setCountries(apiResponse.map((el: { location: string }) => el.location));
    }
  };

  const { debouncedCallback } = useDebounce((newInputValue: string) => {
    if (newInputValue) {
      handleSearchLocation(newInputValue);
    }
  }, 500);


  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Company Details</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Box p={1}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Formik
              initialValues={initialValues}
              onSubmit={(values: StartupFormProfileProps) => {
                handleCreateStartup(values);
              }}
            >
              {(props) => (
                <>
                  <Grid item md={12} pt={4}>
                    <Form>
                      <div className="startupProfile__input">
                        <label htmlFor="name">Startup name</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="e.g. Unicorn Inc."
                          style={{ width: "100%" }}
                          className="form-control mb-3"
                        />
                        <ErrorMessage
                          name="name"
                          component="p"
                          className="requiredField"
                        />
                        <label htmlFor="name">Foundation date</label>
                        <DatePicker
                          className="fullWidth mb-2 datepicker-style"
                          onChange={(date) =>
                            props.setFieldValue(
                              "foundationDate",
                              date
                            )
                          }
                          selected={
                            props.values.foundationDate
                              ? moment(props.values.foundationDate, "YYYY-MM-DD").toDate()
                              : null
                          }
                          wrapperClassName="fullWidth mb-2"
                          dateFormat="MM/dd/yyyy"
                          isClearable={true}
                        />
                        <ErrorMessage
                          name="name"
                          component="p"
                          className="requiredField"
                        />
                        <label htmlFor="location">Location</label>
                        <Autocomplete
                          id="location"
                          options={countries}
                          autoHighlight
                          value={countrySelected}
                          getOptionLabel={(option) => option}
                          onChange={(_event: any, newValue: string | null) =>
                            newValue && setCountrySelected(newValue)
                          }
                          onInputChange={(_event, newInputValue) => {
                            debouncedCallback(newInputValue);
                          }}
                          sx={{
                            borderRadius: "10px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                          renderOption={(props, option) => {
                            return (
                              <Box
                                component="li"
                                {...props}
                                sx={{
                                  borderRadius: "10px",
                                }}
                              >
                                {option}
                              </Box>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="e.g. San Francisco, CA"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              sx={{
                                "& .MuiInputBase-input": {
                                  margin: 0,
                                  padding: 0,
                                  height: 20
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "10px",
                                },
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name="location"
                          component="p"
                          className="requiredField"
                        />
                        <label>Industry</label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "10px",
                              border: "1px solid #d1d1d1",
                              height: "53px",
                              marginTop: "10px",
                              marginBottom: "16px",
                              alignContent: "center",
                            }),
                          }}
                          value={industry}
                          options={industries}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(option) =>
                            handleIndustry(option as IndustriesSelector)
                          }
                        />

                        <label>Tech Sector</label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "10px",
                              border: "1px solid #d1d1d1",
                              height: "53px",
                              marginTop: "10px",
                              marginBottom: "16px",
                              alignContent: "center",
                            }),
                          }}
                          value={techSector}
                          options={techSectors}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(option) =>
                            handleTechSector(option as TechSectorSelector)
                          }
                        />

                        <label>Employees number</label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "10px",
                              border: "1px solid #d1d1d1",
                              height: "53px",
                              marginTop: "10px",
                              marginBottom: "16px",
                              alignContent: "center",
                            }),
                          }}
                          value={employees}
                          options={EmployeeCollection}
                          onChange={(option) =>
                            handleInputEmployees(option as EmployeeSelector)
                          }
                        />

                        <label>Stage</label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "10px",
                              border: "1px solid #d1d1d1",
                              height: "53px",
                              marginTop: "10px",
                              marginBottom: "16px",
                              alignContent: "center",
                            }),
                          }}
                          value={stage}
                          options={StageCollection}
                          onChange={(option) =>
                            handleInputStage(option as StageSelector)
                          }
                        />
                        <label htmlFor="email">Website</label>
                        <Field
                          type="text"
                          name="websiteUrl"
                          placeholder="e.g. www.unicorn.com"
                          style={{ width: "100%" }}
                          className="form-control mb-3"
                        />
                        <ErrorMessage
                          name="websiteUrl"
                          component="p"
                          className="requiredField"
                        />
                      </div>
                      <Grid
                        item
                        display={"flex"}
                        justifyContent="center"
                        mt={6}
                        md={12}
                      >
                        <button
                          type="submit"
                          className="button__navigation color__active"
                        >
                          Save
                        </button>
                      </Grid>
                    </Form>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default CompanyDetails;

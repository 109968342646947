import React, { FC, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Rating,
  Slider,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { LiaTimesSolid } from "react-icons/lia";
import Modal from "react-modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// Api Calls to Post the Review
import {
  postStartupReviewFromViewers,
  trackClickConnectStartup,
} from "../../../../../helpers/Api";
import { Params, useParams } from "react-router-dom";
import { ProjectContext } from "../../../../../context/ProjectProvider";
import { getCookiesData } from "../../../../../helpers/Cookie";
import Swal from "sweetalert2";

type FeedbackModalProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  companyName: string;
};

interface FormValues {
  rating: number;
  team: number;
  problem: number;
  solution: number;
  gtmStrategy: number;
  marketOpportunity: number;
  feedback?: string;
  anonymous: boolean;
}

interface TrackConnet {
  email_as_user_id: string | null | undefined;
  user_browser: string | null | undefined;
  user_os: string | null | undefined;
  user_device_type: string | null | undefined;
  startup_id: number | null | undefined;
  connect_yes: boolean | null | undefined;
}

interface Aspect {
  name: keyof FormValues;
  labelLeft: string;
  labelRight: string;
}

const FeedbackModal: FC<FeedbackModalProps> = ({
  modalIsOpen,
  closeModal,
  companyName,
}) => {
  const { id } = useParams<Params>();
  const { email } = useContext(ProjectContext);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      width: "50%",
      maxWidth: "600px",
      height: "80%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "15px",
      border: "none",
      boxShadow: "0px 11px 30px 12px rgba(0, 0, 0, 0.04)",
      padding: "0",
    },
    overlay: { zIndex: 1000 },
  };

  const initialValues: FormValues = {
    rating: 0,
    team: 0,
    problem: 0,
    solution: 0,
    gtmStrategy: 0,
    marketOpportunity: 0,
    feedback: "",
    anonymous: false,
  };

  const validationSchema = Yup.object().shape({
    rating: Yup.number()
      .min(1, "Rating is required")
      .required("Rating is required"),
    team: Yup.number()
      .min(1, "Team rating is required")
      .required("Team rating is required"),
    problem: Yup.number()
      .min(1, "Problem rating is required")
      .required("Problem rating is required"),
    solution: Yup.number()
      .min(1, "Solution rating is required")
      .required("Solution rating is required"),
    gtmStrategy: Yup.number()
      .min(1, "GTM strategy rating is required")
      .required("GTM strategy rating is required"),
    marketOpportunity: Yup.number()
      .min(1, "Market opportunity rating is required")
      .required("Market opportunity rating is required"),
    feedback: Yup.string().max(250, "Feedback must be 250 characters or less"),
  });

  const sendTrackClickConnect = async () => {
    const cookiesData = getCookiesData();
    const properties: TrackConnet = {
      email_as_user_id: email,
      user_browser: cookiesData.browser,
      user_os: cookiesData.os,
      user_device_type: cookiesData.deviceType,
      startup_id: Number(id),
      connect_yes: true,
    };
    await trackClickConnectStartup(properties);
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      if (id && email) {
        await postStartupReviewFromViewers({
          email: email,
          startupId: id,
          rating: values.rating,
          team: values.team,
          problem: values.problem,
          solution: values.solution,
          gtmStrategy: values.gtmStrategy,
          marketOpportunity: values.marketOpportunity,
          feedback: values.feedback,
          anonymous: values.anonymous,
        });
        closeModal();
        Swal.fire({
          title: "Feedback sent",
          text: "Your feedback has been sent successfully",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#04BF7B",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        sendTrackClickConnect();
      } else {
        alert({ id, email });
        throw new Error("Missing startupId or reviewerEmail");
      }
    } catch (error) {
      let errorMessage = "Something went wrong";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#04BF7B",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  const aspects: Aspect[] = [
    { name: "team", labelLeft: "Limited", labelRight: "Exceptional" },
    { name: "problem", labelLeft: "Insignificant", labelRight: "Critical" },
    {
      name: "solution",
      labelLeft: "Ineffective",
      labelRight: "Highly effective",
    },
    { name: "gtmStrategy", labelLeft: "Confusing", labelRight: "Well defined" },
    {
      name: "marketOpportunity",
      labelLeft: "Limited",
      labelRight: "Lucrative",
    },
  ];

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Feedback Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <Typography variant="h6" component="div" color="inherit">
            Thank you for exploring {companyName}!
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={closeModal}
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </Button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
        sx={{
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Your feedback is invaluable to us. Please take a moment to share your
          thoughts on the following aspects.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, isValid, dirty }) => (
            <Form>
              <Box mb={2} textAlign={"center"}>
                <Typography component="legend">Overall rating</Typography>
                <Rating
                  name="rating"
                  value={values.rating}
                  onChange={handleChange}
                  sx={{
                    color: "#04BF7B",
                    "& .MuiRating-iconFilled": {
                      color: "#04BF7B",
                    },
                    fontSize: "4rem",
                  }}
                />
                <ErrorMessage
                  name="rating"
                  component="p"
                  className="requiredField_signUp"
                />
              </Box>
              {aspects.map((aspect) => (
                <Box mb={2} key={aspect.name}>
                  <Typography gutterBottom>
                    {aspect.name.charAt(0).toUpperCase() + aspect.name.slice(1)}
                  </Typography>
                  <Slider
                    name={aspect.name}
                    value={values[aspect.name] as number}
                    onChange={handleChange}
                    aria-labelledby={`${aspect.name}-slider`}
                    step={1}
                    min={0}
                    max={5}
                    marks
                    valueLabelDisplay="auto"
                    sx={{
                      height: "30%",
                      color: "#DDD",
                      "& .MuiSlider-rail": {
                        opacity: 0.5,
                      },
                      "& .MuiSlider-track": {
                        color: "#04BF7B",
                      },
                      "& .MuiSlider-thumb": {
                        color: "#04BF7B",
                      },
                    }}
                  />
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption" gutterBottom>
                      {aspect.labelLeft}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      {aspect.labelRight}
                    </Typography>
                  </Box>
                  <ErrorMessage
                    name={aspect.name}
                    component="p"
                    className="requiredField"
                  />
                </Box>
              ))}
              <Box mb={2}>
                <Field
                  as={TextField}
                  fullWidth
                  multiline
                  rows={2}
                  label="Other feedback"
                  name="feedback"
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                />
                <ErrorMessage
                  name="feedback"
                  component="p"
                  className="requiredField"
                />
              </Box>
              <Box mb={2} alignItems="flex-start">
                <FormControlLabel
                  control={
                    <Switch
                      name="anonymous"
                      checked={values.anonymous}
                      onChange={handleChange}
                    />
                  }
                  label="Enable anonymous feedback?"
                  labelPlacement="top"
                  sx={{
                    alignItems: "flex-start",
                    "& .MuiFormControlLabel-label": {
                      marginLeft: 0,
                    },
                  }}
                />
              </Box>
              <Box textAlign="center">
                <Typography variant="body2" gutterBottom>
                  Thank you for your time and feedback!
                </Typography>
                <button
                  type="submit"
                  className="button__secondary color__active"
                >
                  Submit
                </button>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default FeedbackModal;

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { format, subDays } from "date-fns";
import { encode as base64Encode } from "base-64";
import Cookies from "js-cookie";
import { setCookie } from "./Cookie";

const BASE_URL: string = process.env.REACT_APP_API as string;

const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const configuration: AxiosRequestConfig = config;
  setCookie(configuration);
  if (!configuration.url?.includes("/auth/")) {
    const token = localStorage.getItem("token");
    if (token) {
      if (!configuration.headers) {
        configuration.headers = {};
      }
      configuration.headers.Authorization = `Bearer ${token}`;
    }
  }
  return configuration;
});

//API for handling 401 errors
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const loginUser = async (email: string, password: string) => {
  const user = {
    email,
    password,
  };
  try {
    const response = await instance.post("/auth/login/", user);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("lifetime", response.data.lifetime);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    localStorage.setItem("is_temporary_pass", response.data.is_temporary_pass);
    localStorage.setItem("isOnboarding", response.data.isOnboarding);
    instance.defaults.headers.common.Authorization = `Token ${response.data.token}`;
    return { success: true, data: response.data };
  } catch (err: any) {
    if (err.response && err.response.data) {
      return {
        success: false,
        message:
          err.response.data.message ||
          "Login failed. Please check your credentials.",
      };
    } else {
      return {
        success: false,
        message: "An unexpected error occurred. Please try again.",
      };
    }
  }
};
//API for ending a temporary password to the user through email only returns status
const forgotPassword = async (email: string) => {
  const data = {
    email,
  };
  try {
    const response = await instance.post("/auth/forgot-password/", data);
    return response;
  } catch (err: any) {
    return err.json();
  }
};
//API for resending Text SMS
const resendSMS = async () => {
  try {
    const response = await instance.post("/auth/resend-code/");
    return response;
  } catch (err: any) {
    return err.json();
  }
};

const verifycode = async (otp: string) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found in localStorage");
  }
  const user = {
    code: otp,
  };
  try {
    const response = await instance.post("/auth/verificate-code/", user, {
      headers: { Authorization: `Bearer ${token}` },
    });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("lifetime", response.data.lifetime);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    localStorage.setItem("is_temporary_pass", response.data.is_temporary_pass);
    localStorage.setItem("isOnboarding", response.data.isOnboarding);
    instance.defaults.headers.common.Authorization = `Token ${response.data.token}`;
    return { success: true, data: response.data };
  } catch (err: any) {
    if (err.response && err.response.data) {
      return {
        success: false,
        message: err.response.data.message || "Verification failed",
      };
    } else {
      return { success: false, message: "An unexpected error occurred" };
    }
  }
};
const signupUser = async (
  phone: string,
  email: string,
  password: string,
  checkbox: boolean
) => {
  const data = {
    phoneNumber: phone,
    email,
    password,
    isTermsAcepted: checkbox,
  };
  try {
    const response = await instance.post("/auth/signup/", data);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("lifetime", response.data.lifetime);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    instance.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
    return { success: true, data: response.data };
  } catch (err: any) {
    if (err.response && err.response.data) {
      return {
        success: false,
        message: err.response.data.message || "Verification failed",
      };
    } else {
      return { success: false, message: "An unexpected error occurred" };
    }
  }
};

const activateAccount = async (token: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await instance.post(
      `${BASE_URL}/activar-usuario/`,
      token
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

type InviteFounderProps = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  role: string | null | undefined;
};

const createInviteForFounderProfile = async (
  data: InviteFounderProps,
  startupId: string
) => {
  try {
    const response = await instance.post(
      `startup/${startupId}/founders/`,
      Array.of(data)
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

type EditFounderProps = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  role: string | null | undefined;
  email: string | null | undefined;
};

const editFounderProfile = async (
  data: EditFounderProps,
  startupId: string,
  founderId: string
) => {
  try {
    const response = await instance.put(
      `startup/${startupId}/founders/${founderId}/edit/`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

const deleteFounderProfile = async (startupId: string, founderId: string) => {
  try {
    const response = await instance.delete(
      `startup/${startupId}/founders/${founderId}/delete/`
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

type BusinessTractionProps = {
  businessModel: string[];
  businessRevenueSources: string | null | undefined;
  mrr: number | null | undefined;
  topCustomers: string[] | null | undefined;
};

const createBusinessTraction = async (
  data: BusinessTractionProps,
  startupId: string
) => {
  try {
    const response = await instance.post(
      `startup/${startupId}/business-traction/`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

const editBusinessTraction = async (
  data: BusinessTractionProps,
  startupId: string
) => {
  try {
    const response = await instance.put(
      `startup/${startupId}/business-traction/`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

type CreateRoundProps = {
  roundType: string | null | undefined;
  amount: number | null | undefined;
  raisedAmount: number | null | undefined;
  date: Date | null | undefined;
  investors: string[];
};

const createRoundType = async (data: CreateRoundProps, startupId: string) => {
  try {
    const response = await instance.post(
      `startup/${startupId}/investments/`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

type EditRoundProps = {
  roundType: string | null | undefined;
  amount: number | null | undefined;
  raisedAmount: number | null | undefined;
  date: Date | null | undefined;
};

const editRoundType = async (
  data: EditRoundProps,
  startupId: string,
  roundId: number
) => {
  try {
    const response = await instance.put(
      `startup/${startupId}/investments/${roundId}/edit/`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

const deleteRoundType = async (startupId: string, roundId: number) => {
  try {
    const response = await instance.delete(
      `startup/${startupId}/investments/${roundId}/delete/`
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

type InvestorsProps = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  firmName: string | null | undefined;
  email: string | null | undefined;
  amountInvested: number | null | undefined;
  investedType: string | null | undefined;
};

const createInvestorFromRound = async (
  data: InvestorsProps,
  startupId: string,
  investmentId: number
) => {
  try {
    const response = await instance.post(
      `startup/${startupId}/investments/${investmentId}/add-investor/`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

type editInvestorProps = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  firmName: string | null | undefined;
  email: string | null | undefined;
  amountInvested: number | null | undefined;
  investedType: any;
};

const editInvestorFromRound = async (
  data: editInvestorProps,
  startupId: string,
  roundId: number,
  investorId: number
) => {
  try {
    const response = await instance.put(
      `startup/${startupId}/investments/${roundId}/edit-investor/${investorId}`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

const deleteInvestorFromRound = async (
  startupId: string,
  roundId: number,
  investorId: number
) => {
  try {
    const response = await instance.delete(
      `startup/${startupId}/investments/${roundId}/delete-investor/${investorId}`
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

type ShareProfileProps = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  relationship: string | null | undefined;
  email: string | null | undefined;
};

const createShareProfile = async (
  data: ShareProfileProps,
  startupId: string
) => {
  try {
    const response = await instance.post(`/startup/${startupId}/share/`, data);
    return response.status;
  } catch (err) {
    throw err;
  }
};

const getAllInvestmentsRound = async (startupId: string) => {
  try {
    const response = await instance.get(`startup/${startupId}/investments/`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getBasicProfile = async () => {
  try {
    const response = await instance.get(`profile`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllStartupProfile = async () => {
  try {
    const response = await instance.get(`startup/`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
type StartupProfileProps = {
  name: string;
  description: null;
  location: string;
  industry: string | null | undefined;
  techSector: string | null | undefined;
  employeeCount: string;
  stage: string;
  websiteUrl: string;
  foundationDate: string | null;
};
// Profiles endoints
const createStartupProfile = async (data: StartupProfileProps) => {
  try {
    const response = await instance.post(`startup/`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
const editStartupProfile = async (
  data: StartupProfileProps,
  startupId: string
) => {
  try {
    const response = await instance.put(`startup/${startupId}/edit/`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

type DescriptionProps = {
  description: string | null | undefined;
};

const editStartupDescription = async (
  data: DescriptionProps,
  startupId: string
) => {
  try {
    const response = await instance.put(
      `startup/${startupId}/edit-description/`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

type PrivacyProps = {
  isPrivacy: boolean | null | undefined;
};

const editStartupPrivacy = async (data: PrivacyProps, startupId: string) => {
  try {
    const response = await instance.put(
      `startup/${startupId}/edit-privacy/`,
      data
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

const createStartupAddVideo = async (data: any | null, startupId: string) => {
  try {
    const response = await instance.put(
      `startup/${startupId}/edit-video/`,
      data,
      { timeout: 60000 }
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

const createPitchDeck = async (
  pitchDeck: any | null,
  startupId: string | number | null | undefined
) => {
  try {
    const response = await instance.put(
      `/startup/${startupId}/edit-pitchdeck/`,
      pitchDeck
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

const ProfileCreation = async (
  firstName: string,
  lastName: string,
  description: string
) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found in localStorage");
  }
  const data = {
    firstName,
    lastName,
    tagLine: description,
  };
  try {
    const response = await instance.post("/profile", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (err: any) {
    console.error("Profile Creation error:", err);
    if (err.response && err.response.data) {
      return err.response.data;
    } else {
      throw new Error("Please Try Again");
    }
  }
};

const getProfileData = async () => {
  try {
    const response = await instance.get("/profile");
    return response.data;
  } catch (err) {
    throw err;
  }
};

type ProfileData = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  tagLine: string | null | undefined;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  bio: string | undefined;
  experiences: Experience[];
  linkedinUrl: string | null | undefined;
  xUrl: string | null | undefined;
  websiteUrl: string | null | undefined;
  calendlyUrl: string | null | undefined;
  pictureUrl: string | null | undefined;
};

interface Experience {
  title: string | null | undefined;
  company: string | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}

const createProfileData = async (profileData: ProfileData) => {
  try {
    const response = await instance.put("/profile/update/", profileData);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const mixpanelQuery = async (eventName: string): Promise<any[]> => {
  const apiSecret = "413799c2381f4b14c58aee1ac3fde575";
  const url = "https://mixpanel.com/api/2.0/jql";

  const toDate = new Date();
  const fromDate = subDays(toDate, 365);

  const fromDateStr = format(fromDate, "yyyy-MM-dd");
  const toDateStr = format(toDate, "yyyy-MM-dd");

  const script = `
      function main() {
          return Events({
              from_date: "${fromDateStr}",
              to_date: "${toDateStr}"
          }).filter(function(event) {
              return event.name === "${eventName}";
          });
      }
  `;

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Basic ${base64Encode(`${apiSecret}:`)}`,
  };

  const data = new URLSearchParams({
    script: script,
  });

  try {
    const response = await axios.post(url, data, { headers });

    return response.data;
  } catch (error) {
    console.error("Error al obtener datos del evento:", error);
    throw error;
  }
};

const checkPublicVisitor = async (startupId: string) => {
  try {
    const response = await instance.get(
      `/startup/${startupId}/check-public-visitor/`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const checkPrivateVisitor = async (
  startupId: string,
  email: string,
  token: string
) => {
  try {
    const response = await instance.get(
      `/startup/${startupId}/${email}/${token}/check-private-visitor/`
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const getPublicStartup = async (
  startupId: string,
  email: string,
  isTermsAcepted: boolean
) => {
  const data = {
    email,
    isTermsAcepted,
  };
  try {
    const response = await instance.post(`/startup/${startupId}/public/`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

const getPrivateStartup = async (
  startupId: string,
  token: string,
  email: string,
  isTermsAcepted: boolean
) => {
  try {
    const data = {
      isTermsAcepted,
    };
    const response = await instance.post(
      `/startup/${startupId}/${email}/${token}/private/`,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};
interface ReviewData {
  email: string;
  anonymous: boolean;
  startupId: string;
  feedback?: string;
  gtmStrategy: number;
  marketOpportunity: number;
  problem: number;
  rating: number;
  solution: number;
  team: number;
}
const postStartupReviewFromViewers = async (data: ReviewData) => {
  const requestData = {
    email: data.email,
    overalRating: data.rating,
    teamValue: data.team,
    problemValue: data.problem,
    solutionValue: data.solution,
    gtmstrategyValue: data.gtmStrategy,
    marketoppValue: data.marketOpportunity,
    details: data?.feedback,
    isAnonymous: data.anonymous,
  };
  try {
    const response = await instance.post(
      `/reviews/${data.startupId}/create/`,
      requestData
    );
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw new Error(err.response?.data.error || "Unknown error occurred");
    }
    throw err;
  }
};

//Api for changing profile picture doesnt return anything,
const uploadProfilePicture = async (formData: FormData) => {
  try {
    const response = await instance.put(
      "/profile/change-profile-image/",
      formData
    );
    if (!response) {
      throw new Error("Failed to upload profile picture");
    }
  } catch (error) {
    throw error;
  }
};

//Api for changing profile picture doesnt return anything,
const uploadCompanyLogo = async (
  startupId: string | undefined,
  formData: FormData
) => {
  try {
    const response = await instance.put(
      `/startup/${startupId}/edit-image/`,
      formData
    );
    if (!response) {
      throw new Error("Failed to upload profile picture");
    }
  } catch (error) {
    throw error;
  }
};

type ChangePasswordProps = {
  oldPassword: string;
  newPassword: string;
  secondPassword: string;
};

//Enpoints which use headers with no iterceptor
const createChangePassword = async (
  passwordData: ChangePasswordProps,
  token: string
) => {
  try {
    const response = await instance.post(
      "/auth/change-password/",
      passwordData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.status;
  } catch (error) {
    throw error;
  }
};

interface TrackEventData {
  email_as_user_id: string | null | undefined;
  user_browser: string | null | undefined;
  user_os: string | null | undefined;
  user_device_type: string | null | undefined;
  startup_id: number | string | null | undefined;
  [key: string]: any;
}

const trackVisitStartupPage = async (
  properties: TrackEventData & { time_spent_on_page: string | null | undefined }
) => {
  try {
    const cookies = Cookies.get();
    let data = {
      event_name: "Visit_Startup_Page",
      distinct_id: cookies.device_id,
      properties,
    };
    const response = await instance.post("/track-event/", data);
    return response.status;
  } catch (error) {
    throw error;
  }
};

const trackClickPitchDeck = async (
  properties: TrackEventData & {
    total_time: number | null | undefined;
    number_slides_viewed: number | null | undefined;
    time_spent_per_slide: any;
  }
) => {
  try {
    const cookies = Cookies.get();
    let data = {
      event_name: "Click_Pitch_Deck",
      distinct_id: cookies.device_id,
      properties,
    };
    const response = await instance.post("/track-event/", data);
    return response.status;
  } catch (error) {
    throw error;
  }
};

const trackClickVideo = async (
  properties: TrackEventData & {
    total_time_spent_on_video: string | null | undefined;
    finished_video: boolean | null | undefined;
  }
) => {
  try {
    const cookies = Cookies.get();
    let data = {
      event_name: "Click_Video",
      distinct_id: cookies.device_id,
      properties,
    };
    const response = await instance.post("/track-event/", data);
    return response.status;
  } catch (error) {
    throw error;
  }
};

const trackDeckDownload = async (
  properties: TrackEventData & { deck_download_yes: boolean | null | undefined }
) => {
  try {
    const cookies = Cookies.get();
    let data = {
      event_name: "Deck_Download",
      distinct_id: cookies.device_id,
      properties,
    };
    const response = await instance.post("/track-event/", data);
    return response.status;
  } catch (error) {
    throw error;
  }
};

const trackClickPassStartup = async (
  properties: TrackEventData & { pass_yes: boolean | null | undefined }
) => {
  try {
    const cookies = Cookies.get();
    let data = {
      event_name: "Click_Pass_Startup_Button",
      distinct_id: cookies.device_id,
      properties,
    };
    const response = await instance.post("/track-event/", data);
    return response.status;
  } catch (error) {
    throw error;
  }
};

const trackClickConnectStartup = async (
  properties: TrackEventData & { connect_yes: boolean | null | undefined }
) => {
  try {
    const cookies = Cookies.get();
    let data = {
      event_name: "Click_Connect_Startup_Button",
      distinct_id: cookies.device_id,
      properties,
    };
    const response = await instance.post("/track-event/", data);
    return response.status;
  } catch (error) {
    throw error;
  }
};

const trackTotalShares = async (data: TrackEventData) => {
  try {
    const response = await instance.post("/track-event/total-shares/", data);
    return response.status;
  } catch (error) {
    throw error;
  }
};
const createCheckoutSession = async (startupId: string) => {
  try {
    const response = await instance.get(
      `startup/${startupId}/create-checkout-session/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const createCustomerPortalSession = async (startupId: string) => {
  try {
    const response = await instance.get(
      `startup/${startupId}/create-billing-portal-session/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateSocialMediaLinks = async (
  linkedin: string,
  twitter?: string,
  calendar?: string
) => {
  try {
    const response = await instance.put("/profile/change-social-media/", {
      linkedinUrl: linkedin,
      xUrl: twitter,
      calendlyUrl: calendar,
    });
    if (!response) {
      throw new Error("Failed to upload profile picture");
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

const getIndustries = async () => {
  try {
    const response = await instance.get(
      `startup/categories/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getTechSector = async () => {
  try {
    const response = await instance.get(
      `startup/tech-sectors/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const deleteAccount = async () => {
  try {
    const response = await instance.delete(
      `auth/delete-account/`
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

const deleteStartupAddVideo = async ( startupId: string) => {
  try {
    const response = await instance.delete(
      `startup/${startupId}/edit-video/`
    );
    return response.status;
  } catch (err) {
    throw err;
  }
};

export {
  signupUser,
  activateAccount,
  verifycode,
  forgotPassword,
  resendSMS,
  loginUser,
  getBasicProfile,
  getAllStartupProfile,
  createStartupProfile,
  editStartupProfile,
  editStartupDescription,
  editStartupPrivacy,
  createStartupAddVideo,
  createPitchDeck,
  editFounderProfile,
  createInviteForFounderProfile,
  deleteFounderProfile,
  createBusinessTraction,
  editBusinessTraction,
  createRoundType,
  editRoundType,
  deleteRoundType,
  createInvestorFromRound,
  editInvestorFromRound,
  deleteInvestorFromRound,
  getAllInvestmentsRound,
  createShareProfile,
  ProfileCreation,
  getProfileData,
  createProfileData,
  uploadProfilePicture,
  uploadCompanyLogo,
  createChangePassword,
  checkPublicVisitor,
  checkPrivateVisitor,
  getPublicStartup,
  getPrivateStartup,
  postStartupReviewFromViewers,
  mixpanelQuery,
  trackVisitStartupPage,
  trackClickPitchDeck,
  trackClickVideo,
  trackDeckDownload,
  trackClickPassStartup,
  trackClickConnectStartup,
  trackTotalShares,
  createCheckoutSession,
  createCustomerPortalSession,
  updateSocialMediaLinks,
  getIndustries,
  getTechSector,
  deleteAccount,
  deleteStartupAddVideo,
};

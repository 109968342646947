import React from "react";
interface SubmitButtonProps {
  isValid: boolean;
  dirty: boolean;
  label: string;
  onClick?: () => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isValid,
  dirty,
  label,
  onClick,
}) => {
  return (
    <button
      type="submit"
      className="cta-button align-self-end mr-3 mb-3"
      style={{
        width: "123.849px",
        height: "68.43px",
        flexShrink: 0,
        borderRadius: "15px",
        background: isValid && dirty ? "#04BF7B" : "#CEF2D7",
        color: isValid && dirty ? "#FFFFFF" : "#04BF7B",
        boxShadow:
          isValid && dirty ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none",
      }}
      disabled={!(isValid && dirty)}
    >
      <span style={{ color: isValid && dirty ? "#FFFFFF" : "#04BF7B" }}>
        {label}
      </span>{" "}
      <span style={{ color: isValid && dirty ? "#FFFFFF" : "#04BF7B" }}>
        &rarr;
      </span>{" "}
    </button>
  );
};

export default SubmitButton;

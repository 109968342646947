import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import VisitorCard from "./VisitorCard";
import { useContext } from "react";
import { ReviewsDashBoard } from "../../../../../types/dashboard";
import { AppContext } from "../../../../../context";
import { IoLockOpenOutline } from "react-icons/io5";
import { useState, useEffect, useMemo } from "react";
import { createCheckoutSession } from "../../../../../helpers/Api";
import Swal from "sweetalert2";
import MainCard from "../../../../../components/components/MainCard";

const ITEMS_PER_PAGE = 10;

interface VisitorListFullProps {
  data: ReviewsDashBoard[];
  loading: boolean;
  error: string;
  searchTerm: string;
}

const VisitorsListFull: React.FC<VisitorListFullProps> = ({
  loading,
  error,
  searchTerm,
  data,
}) => {
  const { startupProfile, startupPlan } = useContext(AppContext);
  const userPlan = startupPlan || "basic";

  const isBasicPlan = userPlan === "basic";

  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState<ReviewsDashBoard[]>([]);
  const filteredData = useMemo(
    () =>
      data.filter((visitor) =>
        visitor.email.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [data, searchTerm]
  );

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
  }, [currentPage, filteredData]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  const handleUpgrade = async () => {
    try {
      if (!startupProfile?.id) {
        throw new Error("Startup profile ID is missing");
      }
      const response = await createCheckoutSession(startupProfile.id);
      window.open(response.url, "_blank");
    } catch (error) {
      Swal.fire(
        "Error",
        "Unable to process upgrade request. Please try again later.",
        "error"
      );
    }
  };

  const visibleData = isBasicPlan ? paginatedData.slice(0, 4) : paginatedData;
  const hiddenDataCount = isBasicPlan
    ? Math.max(0, filteredData.length - 4)
    : 0;

  return (
    <>
      <MainCard
        title={"Recent activity"}
        pagination
        totalPages={!isBasicPlan ? totalPages : 1}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
      >
        <Grid
          container
          sx={{ padding: "20px 0px 0px 0px" }}
          display="flex"
          direction="row"
        >
          <Grid item xs={4}>
            <Typography variant="subtitle1">Visitors</Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="center">
            <Typography variant="subtitle1">Total Visits</Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            <Typography variant="subtitle1">Last Visit</Typography>
          </Grid>
        </Grid>

        <Grid container sx={{ padding: "16px 0px", gap: 1 }}>
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : visibleData.length > 0 ? (
            <>
              {visibleData.map((visitor: ReviewsDashBoard, index: number) => (
                <VisitorCard
                  key={`${visitor.email}+${index}`}
                  visitorData={visitor}
                />
              ))}
              {isBasicPlan && hiddenDataCount > 0 && (
                <Box sx={{ position: "relative", width: "100%", mt: 2 }}>
                  <Box
                    sx={{
                      filter: "blur(4px)",
                      pointerEvents: "none",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    {data
                      .slice(4)
                      .map((visitor: ReviewsDashBoard, index: number) => (
                        <VisitorCard key={index} visitorData={visitor} />
                      ))}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "#ffffff",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "2rem",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <IoLockOpenOutline size={100} color="#04BF7B" />
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", margin: "1rem" }}
                    >
                      Unlock the Full List!
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textAlign: "center", marginBottom: "1rem" }}
                    >
                      Discover Additional {hiddenDataCount} Visitors Who Viewed
                      Your Startup!
                    </Typography>
                    <button
                      className="button__primary color__active"
                      style={{
                        backgroundColor: "#04BF7B",
                        color: "#fff",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#03a86b")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#04BF7B")
                      }
                      onClick={handleUpgrade}
                    >
                      Upgrade to Pro Now!
                    </button>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Typography variant="body1">No Visitors found.</Typography>
          )}
        </Grid>
      </MainCard>
    </>
  );
};

export default VisitorsListFull;

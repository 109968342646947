import React, { FC, useContext, useState } from "react";
import Modal from "react-modal";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LiaTimesSolid } from "react-icons/lia";
import Swal from "sweetalert2";
import Select from "react-select";
//Custom Components
import { createShareProfile } from "../../../helpers/Api";
import { RelationShipCollection } from "../../../helpers/RelationShip";
import { FiCopy } from "react-icons/fi";
import { AppContext } from "../../../context";
import { Paragraph } from "../../../styled/Paragraph";

interface ShareProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  startupId?: number | string;
  url?: string | null;
}

type DescriptionFormProps = {
  firstName: string;
  lastName: string;
  relationship: string;
  email: string;
};

type RelationSelector = {
  value: string;
  label: string;
};

const Share: FC<ShareProps> = ({ modalIsOpen, closeModal, startupId, url }) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    relationship: "",
    email: "",
  };
  const [isCopied, setIsCopied] = useState(false);
  const { startupPublicLink, startupProfile } = useContext(AppContext);
  url = startupPublicLink;

  const [relation, setRelation] = useState<RelationSelector>(
    RelationShipCollection[0]
  );

  const handleRelationship = (option: RelationSelector) => {
    return setRelation(option);
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(url || "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleShareJuice = async (values: DescriptionFormProps) => {
    try {
      const response = await createShareProfile(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          relationship: relation.value,
          email: values.email,
        },
        startupId?.toString()!
      );
      if (response === 201) {
        Swal.fire({
          title: "Success",
          text: "Your startup juice has been shared",
          icon: "success",
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        closeModal();
      }
    } catch (error) {
      Swal.fire(
        "Oops",
        `Looks like there is something wrong <br />
          `,
        "error"
      );
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap={"nowrap"}
        color={"white"}
        sx={{padding: "0px 12px"}}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Share your startup</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            firstName: Yup.string().required("First Name is required"),
            lastName: Yup.string().required("Last Name is required"),
            email: Yup.string()
              .email("Invalid email address")
              .required("Email is required"),
          })}
          onSubmit={(values: DescriptionFormProps) => {
            handleShareJuice(values);
          }}
        >
          {() => (
            <Form>
              <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                spacing={4}
              >
                <Grid item xs={6}>
                  <label htmlFor="firstName">First Name</label>
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="e.g. Elon"
                    className="form-control customField mb-3"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="p"
                    className="requiredField"
                  />
                  <label style={{ marginTop: "10px" }} htmlFor="email">
                    Email
                  </label>
                  <Field
                    type="text"
                    name="email"
                    placeholder="e.g. elon@juicemaker.com"
                    className="form-control customField mb-4"
                  />
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="requiredField"
                  />
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor="lastName">Last Name</label>
                  <Field
                    type="text"
                    name="lastName"
                    placeholder="e.g. Musk"
                    className="form-control customField mb-3"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="p"
                    className="requiredField"
                  />
                  <label style={{ marginTop: "10px" }} htmlFor="role">
                    Relationship
                  </label>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: "10px",
                        border: "1px solid #d1d1d1",
                        height: "53px",
                        marginTop: "0px",
                        marginBottom: "16px",
                        alignContent: "center",
                      }),
                    }}
                    value={relation}
                    options={RelationShipCollection}
                    onChange={(option) =>
                      handleRelationship(option as RelationSelector)
                    }
                  />
                </Grid>
                {url && startupProfile?.isPublic ? (
                  <>
                    {/* <Divider variant="inset" component="li" /> <br /> */}
                    {/* <label
                      htmlFor="name"
                      style={{ color: "ButtonText", textAlign: "center" }}
                    >
                      You can easily share it with investors, collaborators, and
                      mentors through this unique link.
                    </label> */}

                    <label
                      htmlFor="name"
                      style={{ color: "ButtonText", textAlign: "start" }}
                    >
                      You can easily share it with investors, collaborators, and
                      mentors through this unique link.
                    </label>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={url}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <Button onClick={handleCopy}>
                              <FiCopy
                                size={24}
                                color={isCopied ? "green" : "#04BF7B"}
                              />
                            </Button>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Box mt={6} textAlign={"center"}>
                <button type="submit" className="button__primary color__active">
                  Share
                </button>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default Share;

import React, { FC, useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { Founder } from "../../../../types/startup";
import { useTheme, useMediaQuery } from "@mui/material";

type StepsPathProps = {
  Information: string | undefined | null;
  Description: string | undefined | null;
  Founders?: Founder[] | null;
  Traction: string | undefined | null;
  Investment: boolean | undefined | null;
  PitchDeck: string | undefined | null;
  Video: string | undefined | null;
};

const StepsPath: FC<StepsPathProps> = ({
  Information,
  Description,
  Founders,
  Traction,
  Investment,
  PitchDeck,
  Video,
}) => {
  const [stepsToDo, setStepsToDo] = useState<string[]>([]);
  const [stepsDone, setStepsDone] = useState<string[]>([]);
  const [stepAlreadyDone, setStepAlreadyDone] = useState<number>(1);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const steps = [
      { value: Information, label: "Information" },
      { value: Description, label: "Description" },
      { value: Founders, label: "Founders" },
      { value: Traction, label: "Traction" },
      { value: Investment, label: "Investment" },
      { value: PitchDeck, label: "PitchDeck" },
      { value: Video, label: "Video" },
    ];

    const stepperOrganizer = () => {
      const stepsToDo: string[] = [];
      const stepsDone: string[] = [];

      steps.forEach((info) => {
        if (!info.value) {
          stepsToDo.push(info.label);
        } else {
          stepsDone.push(info.label);
        }
      });
      setStepsToDo(stepsToDo);
      setStepsDone(stepsDone);
      setStepAlreadyDone(stepsDone.length);
    };

    stepperOrganizer();
  }, [
    Information,
    Description,
    Founders,
    Traction,
    Investment,
    PitchDeck,
    Video,
  ]);

  return (
    <div>
      {!isSmallScreen && (
        <Stepper
          sx={{
            maxWidth: "100%",
            "& .MuiStepLabel-root .Mui-completed": {
              color: "rgba(4, 191, 123, 1)", // circle color (COMPLETED)
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "rgba(4, 191, 123, 1)", // text label (COMPLETED)
              },
            "& .MuiStepLabel-root .Mui-active": {
              color: "grey", // circle color (ACTIVE)
            },
            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
              color: "common.grey", // text label (ACTIVE)
            },
            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
              fill: "white", // circle's number (ACTIVE)
            },
          }}
          activeStep={stepAlreadyDone}
          alternativeLabel={!isSmallScreen}
          orientation={isSmallScreen ? "vertical" : "horizontal"}
        >
          {stepsDone.map((label, index) => (
            <Step key={index}>
              <StepButton>{label}</StepButton>
            </Step>
          ))}
          {stepsToDo.map((label, index) => (
            <Step key={index}>
              <StepButton>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
      )}
    </div>
  );
};

export default StepsPath;

import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: baseline;
  gap: 12px;
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 650px) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
  }
`
import React, { FC } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { LiaTimesSolid } from "react-icons/lia";
import Modal from "react-modal";
import { Paragraph } from "../../../../../styled/Paragraph";
import { ButtonPrimary } from "../../../../../styled/Button";
import { Investor } from "../../../../../types/fundraising";

type ContactModalProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  investor: Investor;
};

const ContactModal: FC<ContactModalProps> = ({
  modalIsOpen,
  closeModal,
  investor,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      maxWidth: "600px",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "15px",
      border: "none",
      boxShadow: "0px 11px 30px 12px rgba(0, 0, 0, 0.04)",
      padding: "0",
    },
    overlay: { zIndex: 1000 },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Feedback Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box sx={{padding: '0px 24px'}}>
          <Typography variant="h6" component="div" color="inherit" sx={{ fontSize: 40, color: '#04BF7B' }}>
            Investor Profile
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={closeModal}
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </Button>
        </Box>
      </Grid>
      <Grid
        container
        display="flex"
        direction="column"
        alignItems="stretch"
        alignContent='center'
        sx={{
          width: "100%",
          boxSizing: "border-box",
          padding: '24px 40px'
        }}
      >
        <Grid container spacing={2} direction={'column'}>
          <Grid item xs={8}>
            <Paragraph fontSize={32} fontWeight="700">{`${investor.first_name} ${investor.last_name}`}</Paragraph>
            <Paragraph fontSize={20} fontWeight="500" color="#9A9DA5">{investor.firm_name}</Paragraph>
          </Grid>
          <Grid item xs={4} sx={{ wordWrap: 'break-word'}}>
            <Paragraph fontSize={16} fontWeight="400">{investor.description}</Paragraph>
          </Grid>
        </Grid>
        <Grid container mt={3} direction={'column'}>
          <Paragraph fontSize={18} fontWeight="500">Contact</Paragraph>
          <Paragraph fontSize={16} fontWeight="400">{investor.website}</Paragraph>
        </Grid>
        <Grid container mt={3} spacing={2}>
          {investor.email && <Grid item>
            <ButtonPrimary $height={37} onClick={() => window.open(investor.email)}>
              Email
            </ButtonPrimary>
          </Grid>}
          {investor.linkedin_link && <Grid item>
            <ButtonPrimary $height={37} onClick={() => window.open(investor.linkedin_link)}>
              LinkedIn
            </ButtonPrimary>
          </Grid>}
         {investor.twitter_link && <Grid item>
            <ButtonPrimary $height={37} onClick={() => window.open(investor.twitter_link)}>
              Twitter / X
            </ButtonPrimary>
          </Grid>}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ContactModal;

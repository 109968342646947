import React, { FC } from "react";
import Modal from "react-modal";
import { Box, Grid } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LiaTimesSolid } from "react-icons/lia";
import Swal from "sweetalert2";
//API Calls
import { editFounderProfile } from "../../../../../../helpers/Api";

interface DescriptionInputProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  role: string | null | undefined;
  userId: string;
  founderId: number;
  isConfirmed: boolean | null | undefined;
}

interface DescriptionFormProps {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  role: string | null | undefined;
}

const EditFounderInnerModal: FC<DescriptionInputProps> = ({
  modalIsOpen,
  closeModal,
  firstName,
  lastName,
  email,
  role,
  userId,
  founderId,
  isConfirmed,
}) => {
  const founderEditId = founderId?.toString() || "";

  const initialValues: DescriptionFormProps = {
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    role: role || "",
  };

  const handleEditFounder = async (values: DescriptionFormProps) => {
    try {
      const response = await editFounderProfile(
        {
          firstName: values.firstName || "",
          lastName: values.lastName || "",
          role: values.role || "",
          email: values.email || "",
        },
        userId,
        founderEditId
      );
      if (response) {
        Swal.fire({
          title: "Success",
          text: "Amazing you've edited  founders profile",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        closeModal();
      }
    } catch (error) {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
            `,
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Edit a founder</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values: DescriptionFormProps) => {
            handleEditFounder(values);
          }}
        >
          {() => (
            <Form>
              <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                spacing={4}
              >
                <Grid item spacing={4} mt={4} xs={6}>
                  <label htmlFor="firstName">First Name</label>
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="e.g. John"
                    className="form-control customField mb-3"
                    disabled={isConfirmed}
                  />
                  <ErrorMessage
                    name="firstName"
                    component="p"
                    className="requiredField"
                  />
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    name="email"
                    placeholder="john.doe@example.com"
                    className="form-control customField mb-4"
                    disabled={isConfirmed}
                  />
                  <ErrorMessage
                    name="last"
                    component="p"
                    className="requiredField"
                  />
                  {/* <button className="button__link">Upload Image</button> */}
                </Grid>
                <Grid item mt={4} xs={6}>
                  <label htmlFor="lastName">Last Name</label>
                  <Field
                    type="text"
                    name="lastName"
                    placeholder="e.g. ecoyote@acme.com"
                    className="form-control customField mb-3"
                    disabled={isConfirmed}
                  />
                  <ErrorMessage
                    name="lastName"
                    component="p"
                    className="requiredField"
                  />
                  <label htmlFor="role">Role</label>
                  <Field
                    type="text"
                    name="role"
                    placeholder="e.g. CEO"
                    className="form-control customField mb-3"
                  />
                  <ErrorMessage
                    name="role"
                    component="p"
                    className="requiredField"
                  />
                </Grid>
              </Grid>
              <Box mt={6} textAlign={"center"}>
                <button type="submit" className="button__primary color__active">
                  Add
                </button>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default EditFounderInnerModal;

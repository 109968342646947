import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {Checkbox, FormControlLabel } from "@mui/material";
import Cookies from "js-cookie";
import SubmitButton from '../../../../../helpers/SubmitButton';
import { getPrivateStartup } from '../../../../../helpers/Api';
import { PublicStartupResponse } from "../../../../../types/startup";

interface TermsProps {
  startUpId: any;
  email: any;
  token: any;
  setNewVisitor: React.Dispatch<React.SetStateAction<boolean | null>>;
  setStartUpData: React.Dispatch<React.SetStateAction<PublicStartupResponse | null | undefined>>;
}

const TermsPrivateForm: React.FC<TermsProps> = ({
  startUpId,
  email,
  token,
  setNewVisitor,
  setStartUpData,
}) => {
  const [error, setError] = useState<string | null>(null);

  const onSubmitGetData = async (values: any) => {
    const isTermsAcepted = values.checkbox;
    const response = await getPrivateStartup(
      startUpId,
      token,
      email,
      isTermsAcepted
    );
    if (response.status === 200) {
      setStartUpData(response.data);
      Cookies.set('email', email, { expires: 7 });
      setNewVisitor(false);
    }
  };

  return (
    <Formik
      initialValues={{
        checkbox: false,
      }}
      validationSchema={Yup.object({
        checkbox: Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("Required"),
      })}
      onSubmit={(values: any) => {
        onSubmitGetData(values);
      }}
    >
      {({ setFieldValue, values, touched, errors, isValid, dirty }) => (
        <Form noValidate role="form">
          <div className="signup__input">
            <p>Your account details</p>
            <FormControlLabel
              className={`mb-3`}
              control={
                <Field
                  type="checkbox"
                  name="checkbox"
                  as={Checkbox}
                  onChange={() => setFieldValue("checkbox", !values.checkbox)}
                />
              }
              label={
                <span>
                  I agree to PomJuice’s Terms & Conditions and Privacy Policy.
                </span>
              }
            />
            <ErrorMessage
              name="checkbox"
              component="p"
              className="requiredField"
            />

            <div>
              <p>Message and data rates may apply.</p>
            </div>
            {error && <p className="text-danger">{error}</p>}
            <div className="d-flex justify-content-end mt-3">
              <SubmitButton isValid={isValid} dirty={dirty} label="Next" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TermsPrivateForm;

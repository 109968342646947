import React, { FC, useState } from "react";
import Button from "@mui/material/Button";

type CustomChipProps = {
  label: string;
  modelType: string;
  modelChoosen: string;
  optionSelected: (businessModel: string) => void;
};

const CustomButtonGroup: FC<CustomChipProps> = ({
  label,
  modelType,
  modelChoosen,
  optionSelected,
}) => {
  return (
    <>
      {modelType === modelChoosen ? (
        <Button
          onClick={() => optionSelected(modelType)}
          sx={{
            width: "100%",
            borderRadius: "10px",
            border: "1px solid #04BF7B",
            // backgroundColor: '#09E769',
            backgroundColor: "#04BF7B",
            height: "51px",
            fontWeight: "bold",
            color: "#FFFF",
          }}
        >
          {label}
        </Button>
      ) : (
        <Button
          onClick={() => optionSelected(modelType)}
          sx={{
            width: "100%",
            borderRadius: "10px",
            borderColor: "#ACB8BC",
            height: "51px",
            color: "#9A9DA5",
          }}
        >
          {label}
        </Button>
      )}
    </>
  );
};

export default CustomButtonGroup;

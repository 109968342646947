import React, { FC, useState, useEffect, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import { verifycode } from "../../../helpers/Api";
import { resendSMS } from "../../../helpers/Api";
//Image assets
import PumpJuiceLogo from "../../../assets/pompjuice_logo.svg";
import pomegranate from "../../../assets/pomegranate.svg";
import second_step from "../../../assets/second_step.svg";
import { AppContext } from "../../../context";
import { useLocation } from "react-router-dom";

interface VerificationFormValues {
  phoneNumber: string;
  email: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
}

const PhoneNumberVerification: FC = () => {
  const location = useLocation();

  const { dispatch } = useContext(AppContext);

  const phoneNumber = location.state?.phoneNumber || "";
  const [values, setValues] = useState<VerificationFormValues>({
    phoneNumber: phoneNumber,
    email: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [resendCode, setResendCode] = useState<boolean>(false);

  useEffect(() => {
    setIsValid(
      values.otp1.length === 1 &&
        values.otp2.length === 1 &&
        values.otp3.length === 1 &&
        values.otp4.length === 1
    );
  }, [values]);
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 4); // Limit refs to 4 elements
  }, []);

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newValue = value.slice(0, 1);
      const updatedValues = { ...values, [`otp${index}`]: newValue };
      setValues(updatedValues);
      setDirty(true);

      if (newValue !== "") {
        if (index < 4) {
          // Focus on the next input field one after another
          const nextInput = document.getElementById(`otp${index + 1}`);
          if (nextInput) {
            nextInput.focus();
          }
        }
      } else if (index > 0) {
        // If the input field is cleared, focus on the previous input field
        const prevInput = document.getElementById(`otp${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    };

  const handleCodeVerification = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    try {
      const otp = `${values.otp1}${values.otp2}${values.otp3}${values.otp4}`;
      const result = await verifycode(otp);

      if (result.success) {
        dispatch({ type: "SET_SIGNUP_STEP", payload: 2 });
        navigate("/signup/verification");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.message || "Something went wrong. Please try again.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
      }
    } catch (err: any) {
      let errorMessage = "Something went wrong. Please try again.";
      if (err.message) {
        errorMessage = err.message;
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errorMessage,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };
  const handleResendCode = async () => {
    try {
      const data = await resendSMS();
      if (data.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Verification code has been resent",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.message || "Something went wrong. Please try again.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
      }
    } catch (err: any) {
      let errorMessage = "Something went wrong. Please try again.";
      if (err.response && err.response.data) {
        errorMessage = err.response.data.message || errorMessage;
      } else if (err.message) {
        errorMessage = err.message;
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errorMessage,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container minHeight={"100vh"} className="login__box">
        <Grid
          item
          xs={12}
          md={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className="login__brand"
          height="100%"
        >
          <div className="login__semiCircle">
            <p></p>
          </div>
          <img src={PumpJuiceLogo} className="login_img" alt="PumpJuiceLogo" />

          <div className="login__brand_text">
            <p>Enter the 4 digit code</p>
            <p>
              Already have an account?{" "}
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontWeight: "inherit",
                }}
              >
                <b>Log in</b>
              </Link>
            </p>
          </div>
          <img
            src={pomegranate}
            className="login_pomegranate"
            alt="GitHub Logo"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          justifyContent="center"
          className="login__card"
        >
          <Box className="signup__form__verification">
            <Typography
              variant="h4"
              style={{
                textAlign: "start",
                marginBottom: "20px",
              }}
            >
              Verify your number
            </Typography>
            <div className="login__brand_text">
              <p style={{ fontSize: 18, fontWeight: 700 }}>
                We’ve sent a verification code to {values.phoneNumber}.
              </p>
            </div>
            <form onSubmit={handleCodeVerification}>
              {/* OTP input fields */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                  flexWrap: "nowrap",
                  gap: "2%",
                }}
              >
                {[1, 2, 3, 4].map((index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    id={`otp${index}`}
                    data-testid={`otp${index}`}
                    style={{
                      flexGrow: 1,
                      minWidth: "50px",
                      maxWidth: "143.019px",
                      height: "auto",
                      minHeight: "10vw",
                      borderRadius: "10px",
                      border: `1px solid ${
                        values[`otp${index}` as keyof VerificationFormValues]
                          .length >= 1
                          ? "#04BF7B"
                          : "#ACB8BC"
                      }`,
                      marginRight: index !== 4 ? "10px" : "0",
                      textAlign: "center",
                      fontSize: "calc(1.5rem + 1vw)",
                    }}
                    value={
                      values[
                        `otp${index}` as keyof VerificationFormValues
                      ] as string
                    }
                    onChange={handleChange(index)}
                  />
                ))}
              </div>

              <Typography style={{ textAlign: "start", marginBottom: "20px" }}>
                This code will expire in 10 minutes. Need a new code?
              </Typography>
              <Link
                to="#"
                style={{ color: "#048C4D" }}
                onClick={handleResendCode}
              >
                Resend Code
              </Link>

              <div className="d-flex flex-column flex-md-row align-items-center mt-3 flex-wrap justify-content-center justify-content-md-between">
                <img
                  src={second_step}
                  alt="First Step"
                  className="img-fluid"
                  style={{
                    marginTop: "30px",
                    cursor: "pointer",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  onClick={() => {
                    window.history.back();
                  }}
                />
                <button
                  className="button__primary color__active"
                  type="submit"
                  style={{ marginTop: "30px", width: "25%" }}
                >
                  Next
                </button>
              </div>
            </form>
            <div className=" loginSmallDevices__brand_text">
              <p style={{ fontWeight: 400, marginTop: "25px" }}>
                Already have an account?{" "}
                <b>
                  <Link
                    to="/login"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      fontWeight: "inherit",
                    }}
                  >
                    Log in
                  </Link>
                </b>
              </p>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PhoneNumberVerification;

import React, { useContext, useState } from "react";
import { Modal, Grid, Box, Button, TextField } from "@mui/material";
import { LiaTimesSolid } from "react-icons/lia";
import { FiCopy } from "react-icons/fi";
import { AppContext } from "../../../context";

interface ShareProfileModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  onCancel: () => void;
}

const ShareProfileModal: React.FC<ShareProfileModalProps> = ({
  modalIsOpen,
  closeModal,
  onCancel,
}) => {
  const { startupPublicLink } = useContext(AppContext);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(startupPublicLink || "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleCancel = () => {
    onCancel();
    closeModal();
  };

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      aria-labelledby="share-profile-modal-title"
      aria-describedby="share-profile-modal-description"
    >
      <Box
        sx={{
          padding: "1rem",
          borderRadius: "10px",
          maxWidth: "800px",
          width: "90%",
          margin: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          color: "#fff",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderRadius: "15px 15px 0px 0px", padding: "0px 12px" }}
          color={"white"}
          bgcolor={"#012626"}
          flexWrap={"nowrap"}
        >
          <Box>
            <p className="buildProfile__modal_paragraph">
              Your startup profile is now public!
            </p>
          </Box>
          <button
            onClick={closeModal}
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          p={4}
          bgcolor={"#fff"}
          borderRadius={"0px 0px 15px 15px"}
        >
          <Box>
            <label
              htmlFor="name"
              style={{ color: "ButtonText", textAlign: "start" }}
            >
              You can easily share it with investors, collaborators, and mentors
              through this unique link.
            </label>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "1rem 0",
              width: "100%",
            }}
          >
            <TextField
              fullWidth
              value={startupPublicLink}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Button onClick={handleCopy}>
                    <FiCopy size={24} color={isCopied ? "green" : "#04BF7B"} />
                  </Button>
                ),
              }}
            />
          </Box>

          <Box>
            <label
              htmlFor="name"
              style={{ color: "ButtonText", textAlign: "start" }}
            >
              Now, when you want to share your profile, you'll have the option
              to do it using this link.
            </label>
          </Box>

          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            style={{ marginTop: "2rem" }}
          >
            <Grid item xs={12} sm={6}>
              <button
                className="button__primary color__active"
                style={{
                  backgroundColor: "red",
                  width: "100%",
                }}
                onClick={handleCancel}
              >
                Cancel
              </button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <button
                className="button__primary color__active"
                style={{ width: "100%" }}
                onClick={closeModal}
              >
                Confirm
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ShareProfileModal;

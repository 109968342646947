import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import { Paragraph } from '../../../../../styled/Paragraph'
import InvestorCard from '../InvestorCard/InvestorCard'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DebtInvestor } from '../../../../../types/fundraising';
import DebtCard from '../DebtCard/DebtCard';

const checkSize = '$50k - 150k'

interface Props {
  investor: DebtInvestor
}

const DebtItemList = ({ investor }: Props) => {
  const { name, status, financing_amount } = investor

  return (
    <Accordion
    key={1}
    sx={{
      "&.MuiAccordion-root": {
        borderRadius: "8px",
        position: 'static',
        padding: {
          xs: '16px',
          sm: '0px',
        },
      },
      width: "100%",
      marginTop: 1,
      boxShadow:
        "0px -2px 80px 0px rgba(0, 0, 0, 0.07), 0px -0.836px 33.422px 0px rgba(0, 0, 0, 0.05), 0px -0.447px 17.869px 0px rgba(0, 0, 0, 0.04), 0px -0.25px 10.017px 0px rgba(0, 0, 0, 0.04), 0px -0.133px 5.32px 0px rgba(0, 0, 0, 0.03), 0px -0.055px 2.214px 0px rgba(0, 0, 0, 0.02)",
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1d-content"
      id="panel1d-header"
      sx={{
        '& .MuiAccordionSummary-content': {
          margin: '0',
          width: '90%'
        },
        '&.Mui-expanded .MuiAccordionSummary-content': {
          margin: '0',
        },
      }}
    >
    <Grid container direction="row" sx={{ alignItems: 'center', padding: 0, justifyContent: 'center' }} spacing={2} wrap="wrap">
      <Grid item xs={12} sm={4} sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: { xs: 'flex-start', sm: 'start' } }}>
        <Paragraph fontSize={16} fontWeight='400' style={{ lineHeight: '1.1'}}>{name}</Paragraph>
      </Grid>
      <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center' }, alignItems: 'center' }}>
        <Paragraph ellipsis fontSize={16} fontWeight='400' color='#035928' style={{ textAlign: 'center' }}>{status}</Paragraph>
      </Grid>
      <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center' }, alignItems: 'center' }}>
        <Paragraph ellipsis fontSize={16} fontWeight='400' style={{ textAlign: 'center' }}>{financing_amount}</Paragraph>
      </Grid>
      <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center' }, alignItems: 'center' }}>
        <div style={{ textAlign: 'center', color: '#1E1E1E' }}>View details</div>
      </Grid>
    </Grid>

    </AccordionSummary>
    <AccordionDetails sx={{ padding: '0' }}>
      <DebtCard investor={investor} />
    </AccordionDetails>
  </Accordion>
  )
}

export default DebtItemList
import instance from ".";

// export const getReviews = async (startupId: string) => {
//   try {
//     const response = await instance.get(`reviews/${startupId}/`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };

export const getDashboardData = async () => {
  try {
    const response = await instance.get(`/initial-dashboard/`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
import React, { FC, useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
// import Select from "react-select";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosArrowRoundBack } from "react-icons/io";
import circleBg from "../../../../assets/circle_bg.svg";
//Selectors Collection
import { IndustryCollection } from "../../../../helpers/IndustriesSelector";
import { EmployeeCollection } from "../../../../helpers/EmployeeSelector";
import { StageCollection } from "../../../../helpers/StageSelector";
//API
import { createStartupProfile, getIndustries, getTechSector } from "../../../../helpers/Api";
import { AppContext } from "../../../../context";
import useDebounce from "../../../../hooks/useDebounce";
import { getLocations } from "../../../../api/gralInformation";
import { IndustriesSelector, TechSectorSelector} from "../components/ModalChoice/Modals/CompanyDetailsInnerModal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StartupProfile: FC = () => {
  type StartupProfileProps = {
    name: string;
    description: string | null;
    location: string;
    industry: [{ label: string; value: string }];
    techSector: [{ label: string; value: string }];
    employeeCount: [{ label: string; value: string }];
    stage: [{ label: string; value: string }];
    websiteUrl: string;
    foundationDate: string | null;
  };

  type OptionItem = {
    value: string;
    label: string;
  };

  type IndustryOptionItem = {
    id: string;
    name: string;
  };

  const navigate = useNavigate();
  const [industries, setIndustries] = useState<any[]>([]);
  const [techSectors, setTechSectors] = useState<any[]>([]);
  const [industry, setIndustry] = useState<IndustriesSelector>(industries[0]);
  const [techSector, setTechSector] = useState<TechSectorSelector>(techSectors[0]);
  const [employees, setEmployees] = useState<string>(
    EmployeeCollection[0].value
  );
  const [stage, setStage] = useState<string>(StageCollection[0].value);
  const [countries, setCountries] = useState<string[]>([]);
  const [countrySelected, setCountrySelected] = useState<string>("");
  const [startupUrl, setStartupUrl] = useState<string>("");
  const [startupName, setStartupName] = useState<string>("");
  const [foundationDate, setFoundationDate] = useState<string | null>();
  const { setIsOnboarding } = useContext(AppContext);

  const initialValues: StartupProfileProps = {
    name: "",
    description: null,
    location: "",
    industry: [{ label: "", value: "" }],
    techSector:[{ label: "", value: "" }],
    employeeCount: [{ label: "", value: "" }],
    stage: [{ label: "", value: "" }],
    websiteUrl: "",
    foundationDate: ''
  };

  const handleCreateStartup = async (values: StartupProfileProps) => {
    try {
      const response = await createStartupProfile({
        name: startupName,
        description: null,
        location: countrySelected,
        industry: industry.id,
        techSector: techSector.id,
        employeeCount: employees,
        stage: stage,
        websiteUrl: startupUrl,
        foundationDate: foundationDate || null
      });
      if (response) {
        setIsOnboarding(false);
        localStorage.setItem("isOnboarding", "false");

        Swal.fire({
          title: "Success",
          text: "Amazing you've created the startup profile",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        navigate("/startup-profile/UserProfile");
      }
    } catch (error) {
      Swal.fire(
        "Oops",
        `Looks like there is something wrong <br />
          `,
        "error"
      );
    }
  };

  const handleIndustry = (option: IndustriesSelector) => {
    return setIndustry(option);
  };

  const handleTechSector = (option: TechSectorSelector) => {
    return setTechSector(option);
  };

  const handleInputEmployees = (option: string) => {
    return setEmployees(option);
  };

  const handleInputStage = (option: string) => {
    return setStage(option);
  };

  const handleSearchLocation = async (value: string) => {
    const apiResponse = await getLocations(value);

    if (apiResponse) {
      setCountries(apiResponse.map((el: { location: string }) => el.location));
    }
  };

  const { debouncedCallback } = useDebounce((newInputValue: string) => {
    if (newInputValue) {
      handleSearchLocation(newInputValue);
    }
  }, 500);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [industriesData, setTechSectorsData] = await Promise.all([
          getIndustries(),
          getTechSector()
        ])
        setIndustries(industriesData)
        setTechSectors(setTechSectorsData)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
  
    fetchData()
  }, [])

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: StartupProfileProps) => {
          handleCreateStartup(values);
        }}
      >
        {(props) => (
          <>
            <Form>
              <Grid
                container
                className="startupProfile__box"
                direction="column"
                justifyContent="space-evenly"
                alignItems="flex-start"
                sx={{ width: "auto" }}
              >
                <Grid item md={12}>
                  <p>
                    We need essential information from your startup to get ready
                  </p>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "40px",
                      }}
                    >
                      Tell us a bit about your startup
                    </p>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={12}
                  pt={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label htmlFor="name">Startup name</label>
                    <TextField
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. Unicorn Inc."
                      onChange={(e) => setStartupName(e.target.value)}
                      error={Boolean(props.errors.name && props.touched.name)}
                      helperText={<ErrorMessage name="name" />}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label htmlFor="foundationDate">Foundation date</label>
                    <DatePicker
                      className="fullWidth customField mb-2 pd-2"
                      selected={
                        foundationDate
                          ? moment(foundationDate, "YYYY-MM-DD").toDate()
                          : null
                      }
                      onChange={(date) =>
                        setFoundationDate(date ? moment(date).format('YYYY-MM-DD') : '')
                      }
                      wrapperClassName="fullWidth customField mb-2 pd-2"
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label htmlFor="location">Location</label>
                    <Autocomplete
                      id="location"
                      options={countries}
                      autoHighlight
                      getOptionLabel={(option) => option}
                      onChange={(_event: any, newValue: string | null) =>
                        newValue && setCountrySelected(newValue)
                      }
                      onInputChange={(_event, newInputValue) => {
                        debouncedCallback(newInputValue);
                      }}
                      sx={{
                        borderRadius: "10px",
                      }}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            {...props}
                            sx={{
                              borderRadius: "10px",
                            }}
                          >
                            {option}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="e.g. San Francisco, CA"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                          sx={{
                            "& .MuiInputBase-input": {
                              margin: 0,
                              padding: 0,
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="location"
                      component="p"
                      className="requiredField"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Industry</label>
                    <Select
                      value={industry?.id || ''}
                      onChange={(e) => {
                        const selectedIndustry = industries.find(
                          (industry) => industry.id === e.target.value
                        );
                        handleIndustry(selectedIndustry || null);
                      }}
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      {industries.map((el: IndustryOptionItem) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Tech Sector</label>
                    <Select
                      value={techSector?.id || ''}
                      onChange={(e) => {
                        const selectedTechSector = techSectors.find(
                          (techSector) => techSector.id === e.target.value
                        );
                        handleTechSector(selectedTechSector || null);
                      }}
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      {techSectors.map((el: IndustryOptionItem) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Employees number</label>
                    <Select
                      value={employees}
                      onChange={(e) => handleInputEmployees(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      {EmployeeCollection.map((el: OptionItem) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Stage</label>
                    <Select
                      value={stage}
                      onChange={(e) => handleInputStage(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      {StageCollection.map((el: OptionItem) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Website</label>
                    <TextField
                      name="websiteUrl"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. www.unicorn.com"
                      onChange={(e) => setStartupUrl(e.target.value)}
                      error={Boolean(
                        props.errors.websiteUrl && props.touched.websiteUrl
                      )}
                      helperText={<ErrorMessage name="websiteUrl" />}
                      sx={{
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                  {/* <Grid
                    item
                    display={"flex"}
                    justifyContent="flex-end"
                    md={6}
                  ></Grid> */}
                </Grid>
              </Grid>
              <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                pt={12}
                pb={4}
              >
                <Grid item md={6}>
                  <button
                    type="button"
                    onClick={() => navigate("/startup-profile")}
                    className="button__navigation color__inactive"
                  >
                    <IoIosArrowRoundBack size={26} /> Back
                  </button>
                </Grid>
                <Grid item display={"flex"} justifyContent="flex-end" md={6}>
                  <button
                    type="submit"
                    className="button__navigation color__active"
                  >
                    Next <IoIosArrowRoundForward size={26} />
                  </button>
                </Grid>
                <div className="startupProfile__circleBg">
                  <img src={circleBg} alt="dots" className="dotsBg" />
                </div>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default StartupProfile;

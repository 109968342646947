import React from 'react';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FaArrowUpLong } from "react-icons/fa6";

const ListItemComponent: React.FC<{icon: React.ReactNode, primaryText: string, secondaryText: string, tertiaryText: string}> = ({ icon, primaryText, secondaryText, tertiaryText }) => (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "rgba(1, 38, 38, 1)", borderRadius: '10px' }}>
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <div>
              <Typography
                component="span"
                variant="body1"
                sx={{ fontWeight: 'bold', color: 'rgba(4, 191, 123, 1)', display: 'block' }}
              >
                {primaryText}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                color="text.primary"
                sx={{ display: 'block', marginBottom: '8px' }}
              >
                {secondaryText}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                sx={{ display: 'block' }}
              >
                <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                >
                <button 
                style={{
                  padding: '0px 6px',
                  backgroundColor: '#E7F8EB',
                }} 
                
                className='btn'>
                  <FaArrowUpLong color={"#04BF7B"} size={12} />
                </button> 
                <p style={{margin: '1px 6px'}}>{tertiaryText} more visitors</p>
                </Box> 
           
              </Typography>
            </div>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );

export default ListItemComponent;
import { Grid } from '@mui/material'
import InvestorItemList from './InvestorItemList'
import { Investor } from '../../../../../types/fundraising'

interface Props {
  investors: Investor[]
  isFavList?: boolean;
  updateData?: (searchQuery: string) => Promise<void>
}

const InvestorList = ({ investors, isFavList = false, updateData }: Props) => {
  return (
    <>
    <Grid container direction="row" sx={{ padding: '0px 16px', alignItems: 'center', width: 'calc(100% - 24px)'}}>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>Name</Grid>
      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Stage</Grid>
      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Industry</Grid>
      <Grid item xs={2}></Grid>
    </Grid>
    {investors?.map((inv, i) => <InvestorItemList investor={inv} key={inv.id} isFavList={isFavList} updateData={updateData}/>)}
    </>
  )
}

export default InvestorList
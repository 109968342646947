import React, { useContext, FC, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Grid, Box, Typography } from "@mui/material";

//Image assets
import PumpJuiceLogo from "../../../assets/pompjuice_logo.svg";
import checkmarkicon from "../../../assets/checkmark_icon.svg";
import pomegranate from "../../../assets/pomegranate.svg";
import { AppContext } from "../../../context";

const VerificationSuccessful: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "SET_SIGNUP_STEP", payload: 3 });
    const timer = setTimeout(() => {
      navigate("/signup/lets-get-to-know-you");
    }, 5000);
    return () => clearTimeout(timer);
  }, [dispatch, navigate]);

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container minHeight={"100vh"} className="login__box">
        <Grid
          item
          xs={12}
          md={4}
          container
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="login__brand"
          height="100%"
        >
          <div className="login__semiCircle">
            <p></p>
          </div>
          <img src={PumpJuiceLogo} className="login_img" alt="PumpJuiceLogo" />

          <div className="login__brand_text">
            <p>Verification In Progress</p>
            <p style={{ fontSize: 35, fontWeight: 700 }}>
              Thank you for verifying your phone number
            </p>
          </div>

          <img
            src={pomegranate}
            style={{ width: "80%", margin: "2rem" }}
            alt="GitHub Logo"
            className="login_pomegranate"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          container
          display="flex"
          direction="column"
          alignItems="stretch"
          justifyContent="flex-end"
          className="login__card"
        >
          <Box className="signup__form ">
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Verified Successfully
            </Typography>
            <img
              src={checkmarkicon}
              alt="Checkmark Icon"
              style={{
                marginTop: "30px",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VerificationSuccessful;
